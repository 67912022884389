import React, { useEffect, useState } from "react";
import BaseButton from "../../../../BaseComponents/BaseButton";
import Spinner from "../../../../BaseComponents/BaseLoader";
import { listOfShift } from "../../../../Api/HRshiftSetting";
import { toast } from "react-toastify";
import { centerIDPayload, checkStatusCodeSuccess, removeSeconds } from "../../../../Constant/common";
import { shiftSettingLabel } from "../../../../Constant/HR/ShiftSetting";
import { RiSearchLine, RiPencilFill, RiUserSettingsFill } from "react-icons/ri";
import { notFound } from "../../../../Constant";
import { holidayLabel } from "../../../../Constant/HR_HolidayTemplate/holidayTemplate";
import { useNavigate } from "react-router-dom";
import { useSelectedCenter } from "../../../CenterContext";

const ShiftSetting = () => {
  document.title = shiftSettingLabel.ShiftSetting;
  const [shiftData, setShiftData] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { selectedCenterPayload } = useSelectedCenter();
  const selectedCenter = selectedCenterPayload.center_id;

  const fetchData = async () => {
    setLoader(true);
    try {
      const res = await listOfShift(selectedCenter ? `${centerIDPayload}${selectedCenter}` : "");
      if (checkStatusCodeSuccess(res?.statusCode)) {
        setShiftData(res?.data || []);
      } else {
        toast.error(res?.message);
        setShiftData([]);
      }
    } catch (error) {
      setShiftData([]);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="page-header dash-breadcrumb py-0 pb-1">
        <div className="row">
          <div className="col-12 col-md-6 mb-3 mb-md-0">
            <h5 className="f-w-600">{shiftSettingLabel.SHIFTSETTING}</h5>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
            <a href="/AddShift">
              <BaseButton className="btn btn-pill btn-primary">
                {shiftSettingLabel.crateShift}
              </BaseButton>
            </a>
          </div>
        </div>
      </div>
      <div>
        <div>
          {shiftData?.length > 0 ? (
            shiftData?.map((data) => (
              <div
                className="list-group-item d-flex flex-column flex-md-row justify-content-between align-items-center  bg-white px-5 mb-2 py-2"
                key={data?.id}
              >
                <div className="d-flex flex-column flex-md-row w-100 align-items-center">
                  <div className="col-12 col-md-3 mb-2 mb-md-0 font-weight-bold align-items-center">
                    {data?.shift_name}
                  </div>
                  <div className="col-12 col-md-2 mb-2 mb-md-0 align-items-center">
                    {removeSeconds(data?.shift_in_time)} - {removeSeconds(data?.shift_out_time)}
                  </div>
                  <div className="col-12 col-md-2 mb-2 mb-md-0 align-items-center">
                    {holidayLabel.staffApplied}
                    <span className="badge badge-pill badge-light text-dark mx-1 ">
                      {data?.totalAssignStaff}
                    </span>
                  </div>
                  <div className="col-12 col-md-5 d-md-flex justify-content-md-end mt-2 mt-md-0">
                    <div className="d-flex flex-column flex-md-row align-items-end justify-content-end">
                      <span
                        className="p-2 text-primary"
                        title={holidayLabel.editTemplate}
                        onClick={() => navigate(`/EditShift/${data?.id}`)}
                      >
                        <RiPencilFill size={18} />
                      </span>
                      <span
                        className="p-2 text-success"
                        title={holidayLabel.manageStaff}
                        onClick={() => navigate(`/manageShiftStaff/${data?.id}`)}
                      >
                        <RiUserSettingsFill size={18} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="card py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShiftSetting;
