import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { changePasswordTitle } from "../../Constant/title";
import { useFormik } from "formik";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import {
  changePassword,
  ConfirmPassword,
  currentPassword,
  Loading,
  newPassword,
  Submit,
} from "../../Constant";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseButton from "../../BaseComponents/BaseButton";
import { changePasswordApi } from "../../Api/AuthApi";
import { checkStatusCodeSuccess } from "../../Constant/common";
import {
  passwordRegex,
  validationMessages,
} from "../../Constant/validation";
import * as yup from "yup";
import { toast } from "react-toastify";

const ChangePassword = () => {
  document.title = changePasswordTitle;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const formValidation = yup.object({
    current_password: yup
      .string()
      .required(validationMessages.required(currentPassword)),
    new_password: yup
      .string()
      .required(validationMessages.required(newPassword))
      .min(6, validationMessages.passwordLength(newPassword, 6))
      .matches(
        passwordRegex,
        validationMessages.passwordComplexity(newPassword)
      ),
    confirm_password: yup
      .string()
      .oneOf(
        [yup.ref("new_password"), null],
        validationMessages.passwordsMatch(ConfirmPassword)
      )
      .required(validationMessages.required(ConfirmPassword)),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      current_password: null,
      new_password: null,
      confirm_password: null,
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      setLoader(true);
      const payload = {
        currentPassword: values.current_password,
        newPassword: values.new_password,
        confirmPassword: values.confirm_password,
      };
      changePasswordApi(payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            toast.success(resp?.message);
            navigate(-1);
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setLoader(false);
        });
    },
  });

  return (
    <div className="container-fluid p-0">
      <div className="row">
        <div class="col-12 p-0">
          <div className="mt-4">
            <form className="login-form" onSubmit={formik.handleSubmit}>
              <div className="login-header text-center">
              </div>
              <div className="login-social-title">
                <h5 className="text-gray">{changePassword}</h5>
              </div>
              <div className="form-group">
                <BaseInput
                  className="form-control"
                  name="current_password"
                  type="password"
                  label={currentPassword}
                  icon={<i className="icon-lock" />}
                  placeholder={PlaceHolderFormat(currentPassword)}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values.current_password}
                  touched={formik.touched.current_password}
                  error={formik.errors.current_password}
                />
              </div>
              <div className="form-group">
                <BaseInput
                  className="form-control"
                  name="new_password"
                  type="password"
                  label={newPassword}
                  icon={<i className="icon-lock" />}
                  placeholder={PlaceHolderFormat(newPassword)}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values.new_password}
                  touched={formik.touched.new_password}
                  error={formik.errors.new_password}
                />
              </div>
              <div className="form-group">
                <BaseInput
                  className="form-control"
                  name="confirm_password"
                  type="password"
                  label={ConfirmPassword}
                  icon={<i className="icon-lock" />}
                  placeholder={PlaceHolderFormat(ConfirmPassword)}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values.confirm_password}
                  touched={formik.touched.confirm_password}
                  error={formik.errors.confirm_password}
                />
              </div>
              <div className="d-flex justify-content-start">
                <BaseButton
                  className="btn btn-pill"
                  type="submit"
                  color="primary"
                  disabled={loader}
                  loader={loader}
                  children={loader ? Loading : Submit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;