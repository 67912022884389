import React from "react";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseButton from "../../BaseComponents/BaseButton";
import { formFields, hrEnums } from "../../Constant/HR_Staff/hrEnums";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import { digitRegex, SelectPlaceHolder } from "../../Constant/validation";
import BaseSelect from "../../BaseComponents/BaseSelect";
import BaseRadioGroup from "../../BaseComponents/BaseRadio";
import { radioOptions } from "../../Constant/Inquiry/Inquiry";
import {
  extractDate,
  handleResponse,
  RequiredSign,
} from "../../Constant/common";
import BaseCheckbox from "../../BaseComponents/BaseCheckbox";
import { RiCheckLine, RiCloseLine, RiEditBoxLine } from "react-icons/ri";
import { Edit } from "../../Constant";

const PersonalDetails = ({
  staffForm,
  viewPersonalMode,
  handlePersonalEdit,
  handlePersonalDiscard,
  handlePhysicalChange,
  editPersonalMode,
  selectedPhysical,
  selectedGender,
  handleGenderChange,
  fetchDistrict,
  district,
  states,
  staffData,
  handleMaritalStatus,
  btnLoader,
  handleCheckboxChange,
  isChecked,
}) => {
  return (
    <>
      {viewPersonalMode ? (
        <div id="personal-info" className="info-section">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h5 className="f-w-600">{hrEnums.PERSONAL_INFO}</h5>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              <BaseButton
                onClick={handlePersonalEdit}
                title={Edit}
                className="mx-2 btn btn-light"
                children={
                  <RiEditBoxLine
                    size={20}
                  />
                }
              />
            </div>
          </div>
          <div className="border-bottom mb-2 mt-2 mr-4"></div>
          <div className="row custom-section">
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.GENDER}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffPersonalInformation?.gender ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.DOB}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {(staffData?.staffPersonalInformation?.dob &&
                    extractDate(staffData?.staffPersonalInformation?.dob)) ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.BLOOD_GRP}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffPersonalInformation?.blood_group ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.EMERGENCY_CONTACT}
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffPersonalInformation?.emergency_contact ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.FATHER_NAME}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffPersonalInformation?.father_name ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.MOTHER_NAME}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffPersonalInformation?.mother_name ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.MARITAL_STATUS}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffPersonalInformation?.marital_status ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.SPOUSE_NAME}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffPersonalInformation?.spouse_name ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.PHYSICALLY_CHALLENGED}
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffPersonalInformation?.physically_challenged ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="mb-2 mr-4"></div>
            <div className="row">
              {staffData?.staffAddress?.map((address, index) => (
                <div key={index} className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-3 mb-2">
                      <div>
                        <span className="text-secondary fw-normal fs-8">
                          {address.address_type ?? handleResponse.nullData}
                        </span>
                        <br />
                      </div>
                    </div>
                    <div className="col-sm-9 mb-2">
                      <div className="row">
                        <div className="col-6 col-md-3 mb-3">
                          <div>
                            <span className="text-secondary fw-normal fs-8">
                              {hrEnums.ADDRESS_LINE1}
                            </span>
                            <br />
                            <span className="f-w-600">
                              {address.address_line_1 ??
                                handleResponse.nullData}
                            </span>
                          </div>
                        </div>
                        <div className="col-6 col-md-3 mb-3">
                          <div>
                            <span className="text-secondary fw-normal fs-8">
                              {hrEnums.ADDRESS_LINE2}
                            </span>
                            <br />
                            <span className="f-w-600">
                              {address.address_line_2 ??
                                handleResponse.nullData}
                            </span>
                          </div>
                        </div>
                        <div className="col-6 col-md-3 mb-3">
                          <div>
                            <span className="text-secondary fw-normal fs-8">
                              {hrEnums.DISTRICT}
                            </span>
                            <br />
                            <span className="f-w-600">
                              {address.district?.district_name ??
                                handleResponse.nullData}
                            </span>
                          </div>
                        </div>
                        <div className="col-6 col-md-3 mb-3">
                          <div>
                            <span className="text-secondary fw-normal fs-8">
                              {hrEnums.STATE}
                            </span>
                            <br />
                            <span className="f-w-600">
                              {address.state?.state_name ??
                                handleResponse.nullData}
                            </span>
                          </div>
                        </div>
                        <div className="col-6 col-md-3 mb-3">
                          <div>
                            <span className="text-secondary fw-normal fs-8">
                              {hrEnums.POSTAL_CODE}
                            </span>
                            <br />
                            <span className="f-w-600">
                              {address.postal_code ?? handleResponse.nullData}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div id="personal-info" className="info-section">
          <div className="row mt-2 d-flex align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h5 className="f-w-600">{hrEnums.PERSONAL_INFO}</h5>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              {editPersonalMode && (
                <>
                  <BaseButton
                    onClick={handlePersonalDiscard}
                    className="btn btn-pill btn-light"
                    children={<RiCloseLine size={20}/>}
                  />
                  <BaseButton
                    className="mx-2 ms-1"
                    color="light"
                    type="submit"
                    loader={btnLoader}
                    children={<RiCheckLine size={20} />}
                  />
                </>
              )}
            </div>
          </div>
          <div className="border-bottom mb-4 mt-2 mr-4"></div>
          <div className="row custom-section">
            <div className="col-lg-3">
              <label htmlFor="">
                {
                  <>
                    {hrEnums.GENDER}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
              </label>
              <BaseRadioGroup
                name={formFields.gender}
                options={radioOptions}
                selectedValue={selectedGender}
                onChange={handleGenderChange}
                value={staffForm.values.gender}
                touched={staffForm.touched.gender}
                error={staffForm.errors.gender}
                handleBlur={staffForm.handleBlur}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.dob}
                type="date"
                label={
                  <>
                    {hrEnums.DOB}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                placeholder={PlaceHolderFormat(hrEnums.DOB)}
                value={staffForm.values.dob}
                touched={staffForm.touched.dob}
                error={staffForm.errors.dob}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.blood_group}
                name={formFields.blood_group}
                label={hrEnums.BLOOD_GRP}
                className="select-border"
                options={hrEnums.BLOOD_GRP_OP}
                placeholder={SelectPlaceHolder(hrEnums.BLOOD_GRP)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.BLOOD_GRP, true)
                }
                value={staffForm.values.blood_group}
                touched={staffForm.touched.blood_group}
                error={staffForm.errors.blood_group}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.emergency_contact}
                type="text"
                label={hrEnums.EMERGENCY_CONTACT}
                placeholder={PlaceHolderFormat(hrEnums.EMERGENCY_CONTACT)}
                value={staffForm.values.emergency_contact}
                touched={staffForm.touched.emergency_contact}
                error={staffForm.errors.emergency_contact}
                handleBlur={staffForm.handleBlur}
                maxLength={10}
                handleChange={(e) => {
                  if (digitRegex.test(e?.target?.value)) {
                    staffForm.handleChange(e);
                  }
                }}
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="">{hrEnums.PHYSICALLY_CHALLENGED}</label>
              <BaseRadioGroup
                name={formFields.physically_challenged}
                options={hrEnums.PHYSICALLY_CHALLENGED_OP}
                selectedValue={selectedPhysical}
                onChange={handlePhysicalChange}
                value={staffForm.values.physically_challenged}
                touched={staffForm.touched.physically_challenged}
                error={staffForm.errors.physically_challenged}
                handleBlur={staffForm.handleBlur}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.father_name}
                type="text"
                label={hrEnums.FATHER_NAME}
                placeholder={PlaceHolderFormat(hrEnums.FATHER_NAME)}
                value={staffForm.values.father_name}
                touched={staffForm.touched.father_name}
                error={staffForm.errors.father_name}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.mother_name}
                type="text"
                label={hrEnums.MOTHER_NAME}
                placeholder={PlaceHolderFormat(hrEnums.MOTHER_NAME)}
                value={staffForm.values.mother_name}
                touched={staffForm.touched.mother_name}
                error={staffForm.errors.mother_name}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.marital_status}
                name={formFields.marital_status}
                label={hrEnums.MARITAL_STATUS}
                className="select-border"
                options={hrEnums.MARITAL_STATUS_OP}
                placeholder={SelectPlaceHolder(hrEnums.MARITAL_STATUS)}
                handleChange={(field, value) =>
                  handleMaritalStatus(field, value)
                }
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.MARITAL_STATUS, true)
                }
                value={staffForm.values.marital_status}
                touched={staffForm.touched.marital_status}
                error={staffForm.errors.marital_status}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.spouse_name}
                type="text"
                label={hrEnums.SPOUSE_NAME}
                placeholder={PlaceHolderFormat(hrEnums.SPOUSE_NAME)}
                value={staffForm.values.spouse_name}
                touched={staffForm.touched.spouse_name}
                error={staffForm.errors.spouse_name}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
                disabled={
                  staffForm.values.marital_status !== hrEnums.MARRIED
                    ? true
                    : false
                }
              />
            </div>
            <div className="border-bottom mb-2 mt-3 mr-4"></div>
            <div className="row">
              <p className="f-w-500">{hrEnums.CURRENT_ADDRESS}:</p>
            </div>
            <div className="col-lg-3">
              <BaseInput
                label={hrEnums.ADDRESS_LINE1}
                name={formFields.address_line1}
                type="text"
                placeholder={PlaceHolderFormat(hrEnums.ADDRESS_LINE1)}
                value={staffForm.values.address_line1}
                touched={staffForm.touched.address_line1}
                error={staffForm.errors.address_line1}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                label={hrEnums.ADDRESS_LINE2}
                name={formFields.address_line2}
                type="text"
                placeholder={PlaceHolderFormat(hrEnums.ADDRESS_LINE2)}
                value={staffForm.values.address_line2}
                touched={staffForm.touched.address_line2}
                error={staffForm.errors.address_line2}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.state}
                name={formFields.state}
                label={hrEnums.STATE}
                className="select-border"
                options={states}
                placeholder={SelectPlaceHolder(hrEnums.STATE)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                  fetchDistrict(value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.STATE, true)
                }
                value={staffForm.values.state}
                touched={staffForm.touched.state}
                error={staffForm.errors.state}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.district}
                name={formFields.district}
                label={hrEnums.DISTRICT}
                className="select-border"
                options={district}
                placeholder={SelectPlaceHolder(hrEnums.DISTRICT)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.DISTRICT, true)
                }
                value={staffForm.values.district}
                touched={staffForm.touched.district}
                error={staffForm.errors.district}
                isDisabled={district === null ? true : false}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                label={hrEnums.POSTAL_CODE}
                name={formFields.postal_code}
                type="number"
                placeholder={PlaceHolderFormat(hrEnums.POSTAL_CODE)}
                value={staffForm.values.postal_code}
                touched={staffForm.touched.postal_code}
                error={staffForm.errors.postal_code}
                handleBlur={staffForm.handleBlur}
                handleChange={(e) => {
                  if (
                    e?.target?.value?.length <= 6 &&
                    digitRegex.test(e?.target?.value)
                  ) {
                    staffForm.handleChange(e);
                  }
                }}
              />
            </div>
            <div className="border-bottom mb-1 mt-3 mr-4"></div>
            <div className="d-flex align-items-center mb-3">
              <BaseCheckbox
                id="sameAsCurrentAddress"
                checked={isChecked}
                onChange={(e) => handleCheckboxChange(e)}
                label={hrEnums.CURRENT_CHECKBOX}
              />
            </div>
            <div className="row">
              <p className="f-w-500">{hrEnums.PERMANENT_ADDRESS}:</p>
            </div>
            <div className="col-lg-3">
              <BaseInput
                label={hrEnums.ADDRESS_LINE1}
                name={formFields.permanent_address_line1}
                type="text"
                placeholder={PlaceHolderFormat(hrEnums.ADDRESS_LINE1)}
                value={staffForm.values.permanent_address_line1}
                touched={staffForm.touched.permanent_address_line1}
                error={staffForm.errors.permanent_address_line1}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
                disabled={isChecked}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                label={hrEnums.ADDRESS_LINE2}
                name={formFields.permanent_address_line2}
                type="text"
                placeholder={PlaceHolderFormat(hrEnums.ADDRESS_LINE2)}
                value={staffForm.values.permanent_address_line2}
                touched={staffForm.touched.permanent_address_line2}
                error={staffForm.errors.permanent_address_line2}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
                disabled={isChecked}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.permanent_state}
                name={formFields.permanent_state}
                label={hrEnums.STATE}
                className="select-border"
                options={states}
                placeholder={SelectPlaceHolder(hrEnums.STATE)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                  fetchDistrict(value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.STATE, true)
                }
                value={staffForm.values.permanent_state}
                touched={staffForm.touched.permanent_state}
                error={staffForm.errors.permanent_state}
                isDisabled={isChecked}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.permanent_district}
                name={formFields.permanent_district}
                label={hrEnums.DISTRICT}
                className="select-border"
                options={district}
                placeholder={SelectPlaceHolder(hrEnums.DISTRICT)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.DISTRICT, true)
                }
                value={staffForm.values.permanent_district}
                touched={staffForm.touched.permanent_district}
                error={staffForm.errors.permanent_district}
                isDisabled={district === null || isChecked}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                label={hrEnums.POSTAL_CODE}
                name={formFields.permanent_postal_code}
                type="number"
                placeholder={PlaceHolderFormat(hrEnums.POSTAL_CODE)}
                value={staffForm.values.permanent_postal_code}
                touched={staffForm.touched.permanent_postal_code}
                error={staffForm.errors.permanent_postal_code}
                handleBlur={staffForm.handleBlur}
                handleChange={(e) => {
                  if (
                    e?.target?.value?.length <= 6 &&
                    digitRegex.test(e?.target?.value)
                  ) {
                    staffForm.handleChange(e);
                  }
                }}
                disabled={isChecked}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalDetails;
