import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../BaseComponents/BaseTable";
import { RiArrowUpLine, RiEditFill, RiSearchLine } from "react-icons/ri";
import { classEnums } from "../../Constant/Class/class";
import { Search, notFound } from "../../Constant";
import {
  exportClass,
  exportRejectClass,
  listOfClassApi,
} from "../../Api/ClassApi";
import Spinner from "../../BaseComponents/BaseLoader";
import {
  setClassIdInSessionStorage,
  setBatchIdInSessionStorage,
  RupeesSymbol,
  extractDate,
  checkStatusCodeSuccess,
  fileDownload,
  csvFile,
} from "../../Constant/common";
import { toast } from "react-toastify";
import BaseButton from "../../BaseComponents/BaseButton";
import { classTitle } from "../../Constant/title";
import { useSelectedCenter } from "../CenterContext";

const Class = () => {
  document.title = classTitle;
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [customPageSize, setCustomPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [classData, setClassData] = useState(null);
  const [searchClassData, setSearchClassData] = useState([]);
  const { selectedCenterPayload } = useSelectedCenter();

  const handleSearchValueChange = (value) => {
    if (value !== searchValue) {
      setSearchValue(value);
      setCurrentPage(1);
    }
  };

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const handleEdit = (classId, batchId) => {
    setClassIdInSessionStorage(classId);
    setBatchIdInSessionStorage(batchId);
    navigate(`/classDetails/${classId}`);
  };

  useEffect(() => {
    fetchClassData();
  }, [currentPage, customPageSize, columnName, sortOrder, searchValue]);

  const fetchClassData = async () => {
    setLoader(true);
    const classPayload = {
      ...selectedCenterPayload,
      order: [columnName, sortOrder],
      search: searchValue,
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    listOfClassApi(classPayload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setClassData(resp?.data?.uniqueListOfClass);
          setSearchClassData(resp?.data?.uniqueListOfClass);
          setTotalPages(resp?.data?.totalPages);
          setTotalRecords(resp?.data?.totalRecordsCount);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
        } else {
          toast.error(resp?.message);
          setSearchClassData([]);
        }
      })
      .catch((err) => {
        setSearchClassData([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const classColumns = useMemo(
    () => [
      {
        header: classEnums.SR_NO,
        accessorKey: "serial_number",
        cell: (cell) => cell.row.index + 1,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: classEnums.BATCH_ID,
        accessorKey: "batch.batch_id",
        enableColumnFilter: false,
      },
      {
        header: classEnums.BATCH_START_DATE,
        accessorKey: "batch.start_date",
        enableColumnFilter: false,
        cell: ({ cell }) => {
          return (
            <span>{`${extractDate(cell?.row?.original?.batch?.start_date)}`}</span>
          );
        },
      },
      {
        header: classEnums.BATCH_END_DATE,
        accessorKey: "batch.end_date",
        enableColumnFilter: false,
        cell: ({ cell }) => {
          return (
            <span>{`${extractDate(cell?.row?.original?.batch?.end_date)}`}</span>
          );
        },
      },
      {
        header: classEnums.COURSE_NAME,
        accessorKey: "batch.course.course_name",
        enableColumnFilter: false,
      },
      {
        header: classEnums.COURSE_HOURS,
        accessorKey: "batch.course.course_hours",
        enableColumnFilter: false,
      },
      {
        header: classEnums.RATE_PER_HOUR,
        accessorKey: "batch.course.course_rate",
        enableColumnFilter: false,
        cell: ({ cell }) => {
          return (
            <span>{`${RupeesSymbol(
              cell?.row?.original?.batch?.course?.course_rate
            )}`}</span>
          );
        },
      },
      {
        header: classEnums.ACTION,
        accessorKey: "action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell) => (
          <div className="d-flex justify-content-center icon">
            <RiEditFill
              className="fs-5 mx-2 text-success"
              onClick={() =>
                handleEdit(
                  cell?.row?.original?.id,
                  cell?.row?.original?.batch_id
                )
              }
              title={classEnums.EDIT}
            />
          </div>
        ),
      },
    ],
    [navigate]
  );
  const handleExportClass = () => {
    setLoader(true);
    const centerId = selectedCenterPayload.center_id;
    exportClass(
      (centerId !== undefined && centerId !== null) ? centerId : ""
    )
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          const filename = resp?.data;
          fileDownload(filename,csvFile,classEnums.CLASS_EXPORT);
          toast.success(resp?.success);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const handleExportWithRejection = () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
    };
    exportRejectClass(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          const filename = resp?.data;
          fileDownload(filename, csvFile, classEnums.REJECTED_CLASS_EXPORT);
          toast.success(resp.success);
        } else {
          toast.error(resp.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  return (
    <div>
      <div className="px-3 d-flex justify-content-between align-items-center">
        <h5 className="f-w-600">{classEnums.CLASS}</h5>
        <div className="col-sm-auto col-md-auto mb-2">
          <BaseButton onClick={() => handleExportClass()} color="success">
            <RiArrowUpLine size={20} /> {classEnums.exportClass}
          </BaseButton>
          <BaseButton
            onClick={() => handleExportWithRejection()}
            className="mx-2 text-white"
            color="warning"
          >
            <RiArrowUpLine size={20} />
            {classEnums.EXPORT_WITH_REJECTION}
          </BaseButton>
        </div>
      </div>{" "}
      <div className="card mx-3">
        <div className="card-body text-center">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          {classData && classData?.length > 0 && (
            <TableContainer
              totalPages={totalPages}
              totalRecords={totalRecords}
              totalNumberOfRows={totalNumberOfRows}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              fetchData={handleFetchData}
              customPageSize={customPageSize}
              setCustomPageSize={setCustomPageSize}
              fetchSortingData={handleFetchSorting}
              columns={classColumns}
              data={searchValue ? searchClassData : classData || []}
              manualPagination={true}
              SearchPlaceholder={Search}
              onSearch={handleSearchValueChange}
              isGlobalFilter={true}
              tableClass="table table-bordered text-center"
              manualFiltering={true}
            />
          )}
          {!loader && !classData && (
            <div className="py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Class;
