import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BaseButton from "../../../../BaseComponents/BaseButton";
import "../../../../../src/hr.css";
import { holidayLabel } from "../../../../Constant/HR_HolidayTemplate/holidayTemplate";
import { holidayTemplateTitle } from "../../../../Constant/title";
import { listOfHoliday } from "../../../../Api/HolidayTemplate";
import { checkStatusCodeSuccess } from "../../../../Constant/common";
import Spinner from "../../../../BaseComponents/BaseLoader";
import { toast } from "react-toastify";
import { RiPencilFill, RiSearchLine, RiUserSettingsFill } from "react-icons/ri";
import { notFound } from "../../../../Constant";
import { useSelectedCenter } from "../../../CenterContext";

const HolidayTemplate = () => {
  document.title = holidayTemplateTitle;
  const navigate = useNavigate();
  const [templateData, setTemplateData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { selectedCenterPayload } = useSelectedCenter();

  const handleCreateTemplate = () => {
    navigate("/createHolidayTemplate");
  };

  const handleEditTemplate = (id) => {
    navigate(`/editHolidayTemplate/${id}`);
  };

  const fetchData = () => {
    setLoader(true);
    const payload={
      condition:{
        ...selectedCenterPayload,
      },
    };
    listOfHoliday(payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          setTemplateData(res?.data);
        } else {
          setTemplateData([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setTemplateData([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h5>{holidayLabel.holidayTemplate}</h5>
          <BaseButton
            className="btn btn-pill"
            type="button"
            children={holidayLabel.createTemplate}
            onClick={handleCreateTemplate}
          />
        </div>
        <div className="mt-2">
          <div>
            {loader ? (
              <Spinner attrSpinner={{ className: "loader-2" }} />
            ) : templateData?.length > 0 ? (
              templateData?.map((data) => (
                <div
                  className="list-group-item holiday-template bg-white py-2 px-5 mb-2"
                  key={data?.id}
                >
                  <div className="row align-items-center">
                    <div className="col-12 col-sm-4 col-md-3 font-weight-bold">
                      {data?.template_name}
                    </div>
                    <div className="col-12 col-sm-4 col-md-3 ">
                      {data?.holidays_count === 1
                        ? holidayLabel.holiday
                        : holidayLabel.holidays}
                        <span className="badge badge-pill badge-light text-dark mx-1">{data?.holidays_count}</span>{" "}
                    </div>
                    <div className="col-12 col-sm-4 col-md-2 ">
                     {holidayLabel.staffApplied} <span className="badge badge-pill badge-light text-dark mx-1">{data?.applied_staff_count}</span>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="d-flex flex-column flex-md-row align-items-end justify-content-end">
                      <span className="p-2 text-primary" title={holidayLabel.editTemplate} onClick={() => handleEditTemplate(data?.id)}><RiPencilFill size={18} /></span>
                      <span className="p-2 text-success" title={holidayLabel.manageStaff} onClick={() => navigate(`/manageStaff/${data?.id}`)}><RiUserSettingsFill size={18} /></span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="card py-4 text-center">
                <div>
                  <RiSearchLine className="fs-2" />
                </div>
                <div className="mt-4">
                  <h5>{notFound.dataNotFound}</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HolidayTemplate;
