import { ADDATTENDACNE, ADDNOTE, ATTENDENCEMUSTERROLL, LISTOFWORKINGDAY, REVERTTOHOLIDAY, REVERTWEEKOFF, VIEWATTENDANCE, VIEWLOGS } from "./apiRoutes";
import { authData } from "./Service";

export const addStaffAttendance = async (id,data) => {
    const response = await authData.put(`${ADDATTENDACNE}${id}`,data);
    return response?.data;
};
export const addNote = async (id,data) => {
    const response = await authData.put(`${ADDNOTE}${id}`,data);
    return response?.data;
};
export const viewLogs = async (id,data) => {
    const response = await authData.get(`${VIEWLOGS}${id}`,data);
    return response?.data;
};
export const viewAttendance = async (data) => {
    const response = await authData.post(VIEWATTENDANCE,data);
    return response?.data;
};
export const listOfWorkingDay = async (data) => {
    const response = await authData.post(LISTOFWORKINGDAY,data);
    return response?.data;
};
export const attendanceMusterRollReport = async (data) => {
    const response = await authData.post(ATTENDENCEMUSTERROLL,data);
    return response?.data;
}; 

export const revertStatus = async (id) => {
    const response = await authData.put(`${REVERTTOHOLIDAY}${id}`);
    return response?.data;
}; 
export const revertWeekOffStatus = async (id) => {
    const response = await authData.put(`${REVERTWEEKOFF}${id}`);
    return response?.data;
}; 