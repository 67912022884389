import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import {
  RiEditFill,
  RiCheckboxCircleLine,
  RiSearchLine,
  RiSurveyLine,
  RiArrowUpLine,
} from "react-icons/ri";
import {
  SelectPlaceHolder,
  validationMessages,
} from "../../Constant/validation";
import TableContainer from "../../BaseComponents/BaseTable";
import {
  batchallocationLabel,
  batchallocationValidation,
  BatchListTitle,
  editBatchLable,
  StatusOptions,
} from "../../Constant/BatchAllocation/batchallocation";
import {
  candidateSatuts,
  courseCodeList,
  editBatchAssessmentApi,
  listEnrolledCandidateApi,
  listOfBatch,
} from "../../Api/BatchApi";
import BaseSelect from "../../BaseComponents/BaseSelect";
import BaseInput from "../../BaseComponents/BaseInput";
import { useNavigate } from "react-router-dom";
import Spinner from "../../BaseComponents/BaseLoader";
import BaseModal from "../../BaseComponents/BaseModal";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { ClearFilter, Completed, notFound, Status } from "../../Constant";
import BaseButton from "../../BaseComponents/BaseButton";
import { exportBatchList } from "../../Api/exportBtn";
import { BaseExportURL } from "../../Api/Service";
import BaseCheckbox from "../../BaseComponents/BaseCheckbox";
import {
  checkStatusCodeSuccess,
  csv,
  deleteUploadedfile,
  handleArrayResponse,
  isRequired,
  setCourseIdInSessionStorage,
  setItem,
} from "../../Constant/common";
import { CandidateLabel } from "../../Constant/Candidate/candidate";
import { Candidate } from "../../Constant/Attendance";
import { useSelectedCenter } from "../CenterContext";

const BatchList = () => {
  document.title = BatchListTitle;
  const [batchList, setBatchList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showAssessModal, setShowAssessModal] = useState(false);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [customPageSize, setCustomPageSize] = useState(5);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  //assessment pagination
  const [totalEnrolledPages, setTotalEnrolledPages] = useState(1);
  const [totalEnrolledRecords, setTotalEnrolledRecords] = useState(0);
  const [totalEnrolledNumberOfRows, setTotalEnrolledNumberOfRows] = useState(0);
  const [currentEnrolledPage, setCurrentEnrolledPage] = useState(1);
  const [customEnrolledPageSize, setCustomEnrolledPageSize] = useState(5);
  const [columnEnrolledName, setColumnEnrolledName] = useState("id");
  const [sortEnrolledOrder, setSortEnrolledOrder] = useState("DESC");
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const [batchData, setBatchData] = useState([]);
  const [batchSearchData, setBatchSearchData] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [assessId, setAssessId] = useState();
  const [checkedItemsError, setCheckedItemsError] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchEnrolledValue, setSearchEnrolledValue] = useState("");
  const [searchBatchListData, setSearchBatchListData] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {selectedCenterPayload} = useSelectedCenter();

  const handleSearchValueChange = (value) => {
    if (value !== searchValue) {
      setCurrentPage(1);
      setSearchValue(value);
    }
  };
  const handleSearchEnrolledValueChange = (value) => {
    if (value !== searchEnrolledValue) {
      setCurrentEnrolledPage(1);
      setSearchEnrolledValue(value);
    }
  };

  const history = useNavigate();
  const fetchCourse = () => {
    setLoader(true);
    courseCodeList({
      ...selectedCenterPayload,
    })
      .then((res) => {
        if (checkStatusCodeSuccess(res?.statusCode)) {
          setCourseData(
            res?.data?.map((item) => ({
              value: item?.id,
              label: item?.course_code,
              id: item?.id,
            }))
          );
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };
  const handleFetchASorting = (page, id, order) => {
    setCurrentEnrolledPage(page);
    setColumnEnrolledName(id);
    setSortEnrolledOrder(order);
  };

  const statusForm = useFormik({
    initialValues: {
      Status: null,
    },
    validationSchema: yup.object({
      Status: yup.string().required(validationMessages.required("Status")),
    }),
    onSubmit: async (values) => {
      setBtnLoader(true);
      const payload = {
        status: values.Status,
      };
      await candidateSatuts(selectedBatchId, payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            toast.success(resp?.message);
            fetchData();
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || err?.message);
        })
        .finally(() => {
          setBtnLoader(false);
          handleBatchStatus();
        });
      statusForm.resetForm();
    },
  });

    const assessmentForm = useFormik({
      initialValues: {
        assessment_date: null,
        candidate_id: checkedItems || [],
      },
      validationSchema: yup.object({
        assessment_date: yup
          .string()
          .required(
            validationMessages.required(CandidateLabel.assessmentDateValidation)
          ),
        candidate_id: yup
          .array()
          .of(yup.string().required(validationMessages.required(Candidate)))
          .min(1, validationMessages.required(Candidate))
          .required(validationMessages.required(Candidate)),
      }),
      onSubmit: (values) => {
        setBtnLoader(true);
        const payload = {
          assessment_date: values.assessment_date ? values.assessment_date : null,
          candidate_id: values.candidate_id,
        };
        editBatchAssessmentApi(assessId, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              setCheckedItems([]);
              setIsCheckedAll(false);
              setShowAssessModal(false);
              assessmentForm.resetForm();
              fetchData();
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            handleArrayResponse(err);
          })
          .finally(() => {
            setBtnLoader(false);
            setIsCheckedAll(false);
          });
      },
    });

  const fetchSearchData = () => {
    setLoader(true);
    const payload = {
      ...(selectedCourse !== null && {
        course_id: selectedCourse || null,
      }),
      condition:{
        ...selectedCenterPayload,
      },
      order: [columnName, sortOrder],
      pageNumber: currentPage,
      pageSize: customPageSize,
      search: searchValue,
    };
    listOfBatch(payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res?.statusCode)) {
          setSearchBatchListData(res?.data?.listOfBatch);
          setTotalPages(res?.data?.totalPages);
          setTotalRecords(res?.data?.totalRecordsCount);
          setTotalNumberOfRows(res?.data?.numberOfRows);
          setCurrentPage(res?.data?.currentPage);
        } else {
          toast.error(res?.message);
          setSearchBatchListData([]);
        }
      })
      .catch((error) => {
        setSearchBatchListData([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (searchValue) {
      fetchSearchData();
    } else {
      fetchData(selectedCourse);
    }
  }, [searchValue, customPageSize, currentPage, sortOrder, columnName]);

  useEffect(() => {
    if (searchValue === "") {
      setCurrentPage(1);
    }
  }, [searchValue]);

  useEffect(() => {
    fetchCandidateData(assessId);
  }, [customEnrolledPageSize, currentEnrolledPage, sortEnrolledOrder, columnEnrolledName, searchEnrolledValue]);

  const fetchData = (courseId = null) => {
    setListLoader(true);
    const payload = {
      order: [columnName, sortOrder],
      condition:{
        ...selectedCenterPayload,
      },
      pageNumber: currentPage,
      pageSize: customPageSize,
      ...(courseId && { condition: { course_id: courseId, ...selectedCenterPayload } }),
      search: searchValue,
    };
    listOfBatch(payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res?.statusCode)) {
          setBatchList(res?.data?.listOfBatch || []);
          setTotalRecords(res?.data?.totalRecordsCount);
          setTotalNumberOfRows(res?.data?.numberOfRows);
          setCurrentPage(res?.data?.currentPage);
        } else {
          toast.error(res?.message);
          setBatchList([]);
        }
      })
      .catch((error) => {
        setBatchList([]);
        return error;
      })
      .finally(() => {
        setListLoader(false);
      });
  };

  useEffect(() => {
    if (isCheckedAll && batchData?.length > 0) {
      const allCandidateIds = batchData?.map((item) => item.candidate_id);
      setCheckedItems(allCandidateIds);
    }
  }, [isCheckedAll, batchData]);

  useEffect(() => {
    if (checkedItems?.length === 0) {
      assessmentForm.setFieldValue(
        CandidateLabel.candidateIdKey,
        checkedItems
      );
    } else {
      assessmentForm.setFieldValue(
        CandidateLabel.candidateIdKey,
        checkedItems
      );
    }
    if (formSubmitted) {
      if (checkedItems?.length === 0) {
        setCheckedItemsError(true);
      } else {
        setCheckedItemsError(false);
      }
    }
  }, [checkedItems]);

  useEffect(() => {
    fetchCourse();
  }, []);

  const handleCheckItem = (id) => {
    const isAlreadyChecked = checkedItems?.includes(id);
    const updatedCheckedItems = isAlreadyChecked
      ? checkedItems?.filter((itemId) => itemId !== id)
      : [...checkedItems, id];
    setCheckedItems(updatedCheckedItems);

    setIsCheckedAll(updatedCheckedItems?.length === batchData?.length);
  };

  const handleCheckAll = () => {
    setIsCheckedAll(!isCheckedAll);
    const updatedCheckedItems = isCheckedAll
      ? []
      : batchData?.map((item) => item.candidate_id);
    setCheckedItems(updatedCheckedItems);
  };

  const columns = useMemo(() => [
    {
      header: batchallocationValidation.batchNameValidation,
      accessorKey: batchallocationLabel.bacthNameKey,
      enableColumnFilter: false,
    },
    {
      header: batchallocationLabel.courseName,
      accessorKey: batchallocationLabel.courseNameKey,
      enableColumnFilter: false,
    },
    {
      header: batchallocationLabel.centerName,
      accessorKey: batchallocationLabel.centerKey,
      enableColumnFilter: false,
    },
    {
      header: batchallocationLabel.totalEnrolled,
      accessorKey: batchallocationLabel.totalEnrolledKey,
      enableColumnFilter: false,
    },
    {
      header: batchallocationLabel.totalCandidate,
      accessorKey: batchallocationLabel.totalCandidateKey,
      enableColumnFilter: false,
    },
    {
      header: Status,
      accessorKey: batchallocationLabel.status,
      enableColumnFilter: false,
    },
    {
      header: batchallocationLabel.action,
      accessorKey: batchallocationLabel.actionKey,
      enableSorting: true,
      cell: (cell) => (
        <div>
          <span>
            <RiCheckboxCircleLine
              className="fs-5 text-primary mx-2"
              onClick={() => handleBatchStatus(cell?.row?.original)}
              title="Status"
            />
          </span>
          <span>
            <RiEditFill
              className={`fs-5 text-success mx-2 ${
                cell?.row?.original?.status === Completed ? `icon-disabled` : ``
              }`}
              onClick={() => {
                setItem("BatchCenter", cell?.row?.original?.center?.id);
                handleEditBatch(cell?.row?.original?.id);
                let courseId = cell?.row?.original?.course?.id;
                setCourseIdInSessionStorage(courseId);
              }}
              title="Edit"
            />
          </span>
          <span>
            <RiSurveyLine
              className={`fs-5 text-info mx-2 ${
                cell?.row?.original?.is_assessed === true ? `icon-disabled` : ``
              }`}
              onClick={() => {
                handleBatchAssessment(cell?.row?.original?.id);
                fetchCandidateData(cell?.row?.original?.id);
                setCheckedItemsError(false);
                setAssessId(cell?.row?.original?.id);
              }}
              title="Assessment"
            />
          </span>
        </div>
      ),
      enableColumnFilter: false,
    },
  ]);

  const assessColumn = useMemo(
    () => [
      {
        header: (
          <BaseCheckbox
            id="checkBoxAll"
            checked={isCheckedAll}
            onChange={handleCheckAll}
            className="custom-checkbox-class d-flex justify-content-center"
            labelClassName="custom-label-class"
          />
        ),
        cell: (cell) => (
          <BaseCheckbox
            id={`checkbox${cell.row.original.candidate_id}`}
            checked={
              checkedItems?.includes(cell.row.original.candidate_id) ||
              isCheckedAll
            }
            onChange={() => {
              handleCheckItem(cell.row.original.candidate_id);
            }}
            className="custom-checkbox-class taskCheckBox"
            labelClassName="custom-label-class"
          />
        ),
        enableSorting: true,
        id: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: editBatchLable.can_id,
        accessorKey: editBatchLable.can_idKey,
        enableColumnFilter: false,
      },
      {
        header: editBatchLable.emp_id,
        accessorKey: editBatchLable.emp_idKey,
        enableColumnFilter: false,
      },
      {
        header: editBatchLable.candidateName,
        accessorKey: editBatchLable.candidateNameKey,
        enableColumnFilter: false,
      },
      {
        header: editBatchLable.contactNumber,
        accessorKey: editBatchLable.contactNoKey,
        enableColumnFilter: false,
      },
    ],
    [checkedItems, isCheckedAll]
  );

  const fetchCandidateData = (id) => {
    setLoader(true);
    const payload = {
      order: [columnEnrolledName, sortEnrolledOrder],
      condition: {
        batch_id: id,
      },
      ...selectedCenterPayload,
      pageNumber: currentEnrolledPage,
      pageSize: customEnrolledPageSize,
      search: searchEnrolledValue
    };
    listEnrolledCandidateApi(payload)
      .then((res) => {
        if(checkStatusCodeSuccess(res?.statusCode))
        {
          setBatchData(res?.data?.listOfEnrolledCandidate);
          setBatchSearchData(res?.data?.listOfEnrolledCandidate)
          setTotalEnrolledPages(res?.data?.totalPages);
          setTotalEnrolledRecords(res?.data?.totalRecordsCount);
          setTotalEnrolledNumberOfRows(res?.data?.numberOfRows);
          setCurrentEnrolledPage(res?.data?.currentPage);
          const courseId =
            res?.data?.listOfEnrolledCandidate[0]?.course?.course_id;
          setCourseIdInSessionStorage(courseId);
        }else{
          setBatchData([]);
          setBatchSearchData([]);
        }
      })
      .catch((error) => {
        setBatchData([]);
        setBatchSearchData([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleExportBatchList = () => {
    setLoader(true);
    exportBatchList({
      condition: selectedCenterPayload
    })
      .then((resp) => {        
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          const filename = resp?.data;
          const url = `${BaseExportURL}${resp?.data}`;
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          toast.success(resp?.success);
          if(url){
            deleteUploadedfile(filename, true, csv);
        }
        } else {
          toast.error(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || error?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleEditBatch = (batchId) => {
    history(`/editbatch/${batchId}`);
  };
  const handleBatchStatus = (row) => {
    setSelectedBatchId(row?.id);
    setShowModal(!showModal);
    statusForm.setValues({ Status: row?.status || "" });
  };
  const handleBatchAssessment = (batchId) => {
    setSelectedBatchId(batchId);
    setShowAssessModal(!showAssessModal);
    assessmentForm.resetForm();
    setFormSubmitted(false);
    setCheckedItems([]);
  };

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };
  const handleAFetchData = (page) => {
    setCurrentEnrolledPage(page);
  };

  const filterForm = useFormik({
    initialValues: {
      course: null,
    },
  });

  const handleClearFilter = () => {
    filterForm.resetForm();
    setColumnName("id");
    setSortOrder("DESC");
    fetchData();
  };

  useEffect(() => {
    const hasAnyValue = Object?.values(filterForm.values)?.some(
      (value) => value
    );
    setClearFilter(hasAnyValue);
  }, [filterForm.values]);

  return (
    <div>
      {(listLoader || loader) && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <Card className="mb-2 mx-3 px-2">
        <div className="row">
          <div className="d-flex justify-content-start pt-2">
            <h6>{batchallocationLabel.Filters}</h6>
          </div>
          <div className="col-md-2 px-3">
            <div className="align-items-center">
              <BaseSelect
                label={batchallocationLabel.selectCourse}
                name="course"
                className="select-border"
                options={courseData}
                placeholder={SelectPlaceHolder(batchallocationLabel.course)}
                handleChange={(field, value) => {
                  filterForm.setFieldValue(field, value);
                  setSelectedCourse(value);
                  fetchData(value);
                }}
                value={filterForm.values.course}
                isDisabled={false}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end pb-2">
            {clearFilter && (
              <BaseButton
                onClick={handleClearFilter}
                children={ClearFilter}
                color="danger"
              />
            )}
          </div>
        </div>
      </Card>
      <div className="d-flex justify-content-between align-items-center px-3">
        <h5 className="f-w-600">{batchallocationLabel.batchList}</h5>
        <div className="col-sm-auto col-md-auto mb-2">
          <BaseButton color="success" onClick={handleExportBatchList}>
            <RiArrowUpLine size={20} /> {editBatchLable.export}
          </BaseButton>
        </div>
      </div>
      <Card className="mx-3">
        <CardBody>
          {batchList?.length > 0 ? (
            <TableContainer
              totalPages={totalPages}
              totalRecords={totalRecords}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              fetchData={handleFetchData}
              totalNumberOfRows={totalNumberOfRows}
              columns={columns}
              data={searchValue ? searchBatchListData : batchList || []}
              isGlobalFilter={true}
              customPageSize={customPageSize}
              setCustomPageSize={setCustomPageSize}
              fetchSortingData={handleFetchSorting}
              onSearch={handleSearchValueChange}
              manualPagination={true}
              manualFiltering={true}
              tableClass="table table-bordered text-center"
              SearchPlaceholder={batchallocationLabel.searchBar}
            />
          ) : (
            <div className="py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
      <BaseModal
        isOpen={showModal}
        toggler={handleBatchStatus}
        title={batchallocationLabel.statusUpdateTitle}
        submitText={batchallocationLabel.submit}
        submit={statusForm.handleSubmit}
        disabled={btnLoader}
        loader={btnLoader}
      >
        <>
          <BaseSelect
            name="Status"
            label={Status}
            className="select-border"
            options={StatusOptions}
            placeholder={PlaceHolderFormat(Status)}
            handleChange={(field, value) => {
              statusForm.setFieldValue(field, value);
            }}
            handleBlur={() => statusForm.setFieldTouched(Status, true)}
            value={statusForm.values.Status}
            touched={statusForm.touched.Status}
            error={statusForm.errors.Status}
            required={true}
          />
        </>
      </BaseModal>
      <BaseModal
        isOpen={showAssessModal}
        toggler={handleBatchAssessment}
        title={batchallocationLabel.assessmentOfBatchLabel}
        submitText={batchallocationLabel.submit}
        submit={() => {
          if (checkedItems?.length === 0) {
            setFormSubmitted(true);
            setCheckedItemsError(true);
          }
          assessmentForm.handleSubmit();
        }}
        disabled={btnLoader}
        bodyClass="assessment-modal-batch"
        size="lg"
        loader={btnLoader}
      >
        <>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div
              className={`sticky p-3 ${
                batchData?.length > 3 && "assessment-sticky"
              }`}
            >
              <div className="row w-100 d-flex justify-content-between align-items-center">
                <div className="col-12 col-sm-auto">
                  <span className="text-end">
                    {CandidateLabel.assessmentDateValidation}{isRequired}:
                  </span>
                </div>
                <div className="col-12 col-sm-5">
                  <BaseInput
                    type="date"
                    name="assessment_date"
                    value={assessmentForm.values.assessment_date}
                    handleChange={assessmentForm.handleChange}
                    touched={assessmentForm.values.assessment_date}
                    handleBlur={assessmentForm.handleBlur}
                  />
                </div>
                <div className="col-12 col-lg-4">
                  {assessmentForm.errors.assessment_date &&
                    assessmentForm.touched.assessment_date && (
                      <span className="text-danger assessment-table-error">
                        {assessmentForm.errors.assessment_date}
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div
              className={`mt-2 mb-0 p-2 w-100 ${
                checkedItemsError && "table-error-border"
              }`}
            >
              <div>
                {batchData?.length > 0 ? (
                  <div key={batchData.id}>
                    <TableContainer
                      totalPages={totalEnrolledPages}
                      totalRecords={totalEnrolledRecords}
                      totalNumberOfRows={totalEnrolledNumberOfRows}
                      columns={assessColumn}
                      currentPage={currentEnrolledPage}
                      setCurrentPage={setCurrentEnrolledPage}
                      fetchData={handleAFetchData}
                      data={
                        searchEnrolledValue ? batchSearchData : batchData || []
                      }
                      isGlobalFilter={true}
                      customPageSize={customEnrolledPageSize}
                      onSearch={handleSearchEnrolledValueChange}
                      setCustomPageSize={setCustomEnrolledPageSize}
                      tableClass="table table-bordered text-center"
                      SearchPlaceholder={batchallocationLabel.searchBar}
                      fetchSortingData={handleFetchASorting}
                      manualPagination={true}
                      manualFiltering={true}
                    />
                  </div>
                ) : (
                  <div className="py-4 text-center">
                    <div>
                      <RiSearchLine className="fs-2" />
                    </div>
                    <div className="mt-4">
                      <h5>{notFound.dataNotFound}</h5>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {checkedItemsError && (
              <span className="text-danger assessment-table-error">
                {assessmentForm.errors.candidate_id}
              </span>
            )}
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default BatchList;
