import React, { useEffect, useState } from "react";
import {
  Aadhar,
  AadharBack,
  AadharFront,
  BankPassbook,
  FatherName,
  MotherName,
  Qualification,
  QualificationCertificate,
  center,
  course,
  passport,
  reference,
  sector,
  CategoryOptions,
  radioOptions,
  fileSizeMessage,
  hostelMessage,
  viewFile,
  editInquiryErrorForFile,
  editInquiryErrorForFileSize,
  allowedFileTypesInquiry,
  ReferenceOptions,
  PMKVYStaff,
  Referral,
  referenceName,
  Pwd,
  DOB,
  OtherDocument,
  BatchName,
  Source,
  Status,
  isHostel,
} from "../../Constant/Inquiry/Inquiry";
import {
  Address,
  AlternativeContactNumber,
  Cancel,
  CandidateName,
  CandidateNameAsPerAdharCard,
  Category,
  ContactNumber,
  Documents,
  Email,
  EmailAddress,
  Gender,
  Loading,
  OtherDetails,
  PersonalDetails,
  Submit,
} from "../../Constant";
import BaseButton from "../../BaseComponents/BaseButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import {
  SelectPlaceHolder,
  aadharRegex,
  digitRegex,
  emailRegex,
  numberRegex,
  validationMessages,
} from "../../Constant/validation";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseSelect from "../../BaseComponents/BaseSelect";
import {
  fileDeleteApi,
  fileUploadApi,
  qualificationApi,
} from "../../Api/common";
import BaseRadioGroup from "../../BaseComponents/BaseRadio";
import { toast } from "react-toastify";
import Spinner from "../../BaseComponents/BaseLoader";
import { HostelRadio, PwdRadio } from "../../Constant/Hostel/hostel";
import { BaseImageURL } from "../../Api/Service";
import { candidateTitle } from "../../Constant/title";
import {
  checkStatusCodeSuccess,
  currentDateMax,
  handleArrayResponse,
  isRequired,
  rendorBackButton,
  todayDate,
} from "../../Constant/common";
import { listOfUser } from "../../Api/Tot";
import { TotLabels } from "../../Constant/TOT/tot";
import { useSelectedCenter } from "../CenterContext";
import { RiQuestionFill } from "react-icons/ri";
import { editCandidate, viewOfCandidate } from "../../Api/CandidateApi";
import { CandidateLabel } from "../../Constant/Candidate/candidate";
import { hrEnums } from "../../Constant/HR_Staff/hrEnums";
import { Center } from "../../Constant/Center";

const EditCandidate = () => {
  document.title = candidateTitle;
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [viewInquiryData, setViewInquiryData] = useState();
  const [qualificationData, setQualificationData] = useState();
  let fileName;
  let file;
  const [selectedRadio, setSelectedRadio] = useState("Male");
  const [radio, setRadio] = useState("No");
  const [pwdRadio, setPwdRadio] = useState("No");
  const [selectedOption, setSelectedOption] = useState("");
  const [userData, setUserData] = useState();
  let data;
  const { selectedCenterPayload } = useSelectedCenter();
  const [existingFiles, setExistingFiles] = useState({
    passport: null,
    aadharFront: null,
    aadharBack: null,
    qualificationCerti: null,
    bankPassbook: null,
  });
  const [currentImage, setCurrentImage] = useState(null);

  const defaultValidationSchema = yup.object({
    candidate_name: yup
      .string()
      .required(validationMessages.required(CandidateName)),
    contact_no: yup
      .string()
      .required(validationMessages.required(ContactNumber))
      .matches(
        numberRegex,
        validationMessages.contactLength(ContactNumber, 10)
      ),
    address: yup.string().required(validationMessages.required(Address)),
    gender: yup.string().required(validationMessages.required(Gender)),
    center: yup.string().required(validationMessages.required(Center)),
    course: yup.string().required(validationMessages.required(course)),
    qualification: yup
      .string()
      .required(validationMessages.required(Qualification)),
    category: yup.string().required(validationMessages.required(Category)),
    sector: yup.string().required(validationMessages.required(sector)),
    is_hostel: yup.string().required(validationMessages.required(isHostel)),
    father_name: yup.string().required(validationMessages.required(FatherName)),
    mother_name: yup.string().required(validationMessages.required(MotherName)),
    reference: yup.string().required(validationMessages.required(reference)),
    staff_id:
      selectedOption === PMKVYStaff &&
      yup.string().required(validationMessages.required(TotLabels.staffName)),
    referral:
      selectedOption === Referral &&
      yup.string().required(validationMessages.required(referenceName)),
    is_pwd: yup.string().required(validationMessages.required(Pwd)),
    address: yup.string().required(validationMessages.required(Address)),
    passport: yup.string().required(validationMessages.required(passport)),
    aadharFront: yup
      .string()
      .required(validationMessages.required(AadharFront)),
    aadharBack: yup.string().required(validationMessages.required(AadharBack)),
    qualificationCerti: yup
      .string()
      .required(validationMessages.required(QualificationCertificate)),
    bankPassbook: yup
      .string()
      .required(validationMessages.required(BankPassbook)),
    aadhar: yup
      .string()
      .required(validationMessages.required(Aadhar))
      .matches(aadharRegex, validationMessages.contactLength(Aadhar, 12)),
    email: yup
      .string()
      .required(validationMessages.required(Email))
      .matches(emailRegex, validationMessages.format(Email)),
    dob: yup
      .date()
      .max(currentDateMax, hrEnums.DOB_VALIDATION)
      .required(validationMessages.required(DOB)),
    alternative_contact_no: yup
      .string()
      .required(validationMessages.required(AlternativeContactNumber))
      .matches(
        numberRegex,
        validationMessages.contactLength(AlternativeContactNumber, 10)
      ),
  });

  const fetchQualificationData = async () => {
    let data;
    try {
      const qualificationResp = await qualificationApi();
      if (checkStatusCodeSuccess(qualificationResp?.statusCode)) {
        data = qualificationResp?.data;
        const qualification = data?.map((item) => ({
          value: item?.id,
          label: item?.name,
          id: item?.id,
        }));
        setQualificationData(qualification);
      } else {
        toast.error(qualificationResp?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return err;
    }
  };

  const fetchCandidateData = async (id, payload) => {
    try {
      const candidateResp = await viewOfCandidate(id, payload);
      if (checkStatusCodeSuccess(candidateResp?.statusCode)) {
        const candidateData = candidateResp?.data;
        setViewInquiryData(candidateData);
        setRadio(candidateData?.is_hostel);
        if (candidateData?.inquiryDetail !== null) {
          setSelectedRadio(candidateData?.candidateDetail?.gender);
        }
        setExistingFiles({
          passport: candidateData?.candidateDocument?.passport_photo ?? null,
          aadharFront: candidateData?.candidateDocument?.aadharCard_front ?? null,
          aadharBack: candidateData?.candidateDocument?.aadharCard_back ?? null,
          qualificationCerti:
            candidateData?.candidateDocument?.qualification_certificate ?? null,
          bankPassbook: candidateData?.candidateDocument?.bank_passbook ?? null,
        });
      } else {
        toast.error(candidateResp?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return err;
    }
  };

  const fetchUsers = () => {
    listOfUser({})
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          data = resp?.data;
          setUserData(
            data?.map((item) => ({
              value: item?.id,
              label: item?.name,
              id: item?.id,
              email: item?.email,
              contact_no: item?.contact_no,
            }))
          );
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const candidateForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      candidate_name: viewInquiryData?.candidate_name ?? null,
      contact_no: viewInquiryData?.contact_no ?? null,
      address: viewInquiryData?.address ?? null,
      center: viewInquiryData?.center?.center_name ?? null,
      course: viewInquiryData?.course?.course_name ?? null,
      batch: viewInquiryData?.batchAssign?.batch?.batch_id ?? null,
      status: viewInquiryData?.batchAssign?.status ?? null,
      source: viewInquiryData?.source ?? null,
      reference: viewInquiryData?.reference ?? null,
      referral: viewInquiryData?.referral ?? null,
      gender: viewInquiryData?.candidateDetail?.gender ?? "Male",
      aadhar: viewInquiryData?.candidateDetail?.aadhar_no ?? null,
      is_hostel: viewInquiryData?.is_hostel ?? "No",
      is_pwd: viewInquiryData?.is_pwd ?? "No",
      staff_id:
        viewInquiryData?.reference === PMKVYStaff
          ? viewInquiryData.authUser?.id
          : null,
      email: viewInquiryData?.email ?? null,
      qualification: viewInquiryData
        ? viewInquiryData?.candidateDetail?.qualification?.id
        : null,
      category: viewInquiryData?.candidateDetail?.caste_category ?? null,
      sector: viewInquiryData?.candidateDetail?.sector ?? null,
      father_name: viewInquiryData?.candidateDetail?.father_name ?? null,
      mother_name: viewInquiryData?.candidateDetail?.mother_name ?? null,
      passport: viewInquiryData?.candidateDocument?.passport_photo ?? null,
      aadharFront: viewInquiryData?.candidateDocument?.aadharCard_front ?? null,
      aadharBack: viewInquiryData?.candidateDocument?.aadharCard_back ?? null,
      qualificationCerti:
        viewInquiryData?.candidateDocument?.qualification_certificate ?? null,
      bankPassbook: viewInquiryData?.candidateDocument?.bank_passbook ?? null,
      dob: viewInquiryData?.dob ?? null,
      alternative_contact_no: viewInquiryData
        ? viewInquiryData?.candidateDetail?.alternative_contact_no
        : null,
      otherDocs: viewInquiryData?.candidateDocument?.other_document ?? null,
    },
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setBtnLoader(true);
      const payload = {
        inquiry: {
          candidate_name: values.candidate_name,
          address: values.address,
          contact_no: values.contact_no,
          email: values.email,
          reference: values.reference,
          is_hostel: values.is_hostel,
          is_pwd: values.is_pwd,
          dob: values.dob,
        },
        inquiryDetails: {
          aadhar_no: values.aadhar,
          gender: values.gender,
          father_name: values.father_name,
          mother_name: values.mother_name,
          caste_category: values.category,
          qualification_id: values.qualification,
          sector: values.sector,
          batch_time: values.batch_time,
          alternative_contact_no: values.alternative_contact_no,
        },
        inquiryDocument: {
          passport_photo: values.passport,
          aadharCard_front: values.aadharFront,
          aadharCard_back: values.aadharBack,
          bank_passbook: values.bankPassbook,
          qualification_certificate: values.qualificationCerti,
          other_document: values.otherDocs,
        },
      };

      if (values.staff_id) {
        payload.inquiry.staff_id = values.staff_id;
      }
      if (values.referral) {
        payload.inquiry.referral = values.referral;
      }

      editCandidate(id, payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            toast.success(resp?.message);
            navigate("/candidate");
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          handleArrayResponse(err);
        })
        .finally(() => {
          setBtnLoader(false);
        });
    },
  });

  const handleFileChange = (e, name) => {
    fileName = name;
    file = e?.target?.files[0];
    if (file) {
      if (!allowedFileTypesInquiry.includes(file.type)) {
        toast.error(editInquiryErrorForFile);
        return;
      } else {
        if (file.size > 1024 * 1024) {
          toast.error(editInquiryErrorForFileSize);
          return;
        } else {
          if (existingFiles[fileName]) {
            deleteFile(existingFiles[fileName])
              .then(() => {
                fileUploads();
              })
              .catch((err) => {
                toast.error(err?.response?.data?.message || err?.message);
              });
          } else {
            fileUploads();
          }
        }
      }
    }
  };

  const deleteFile = async (filePath) => {
    if (currentImage) {
      const payload = {
        generatedFileNames: [filePath],
      };
      fileDeleteApi(payload)
        .then((resp) => {
          if (!checkStatusCodeSuccess(resp?.StatusCodes)) {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || err?.message);
          return err;
        });
    }
  };

  useEffect(() => {
    if (candidateForm.values.reference) {
      setSelectedOption(candidateForm.values.reference);
      fetchUsers();
    }
  }, [candidateForm.values.reference]);

  const handleSelectChange = (field, value) => {
    fetchUsers();
    candidateForm.setFieldValue(field, value);
    if (field === "reference") {
      setSelectedOption(value);
    }
  };

  const fileUploads = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("files", file);
    fileUploadApi(formData)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          candidateForm.setFieldValue(fileName, resp?.data[0]);
          setExistingFiles((prev) => ({
            ...prev,
            [fileName]: resp?.data[0],
          }));
          setCurrentImage(resp?.data[0]);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const scrollTop = (errors) => {
    const firstErrorKey = Object.keys(errors)[0];
    const errorElement = document.getElementsByName(firstErrorKey)[0];

    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      errorElement.focus();
    }
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
    candidateForm.setFieldValue("gender", event.target.value);
  };

  const handleChangePwd = (event) => {
    setPwdRadio(event.target.value);
    candidateForm.setFieldValue("is_pwd", event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const payload = {
        condition: selectedCenterPayload,
      };
      await Promise.all([
        fetchQualificationData(),
        fetchCandidateData(id, payload),
      ]);
      setLoader(false);
    };

    fetchData();
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div className="px-3 d-flex justify-content-between align-items-center mb-1">
        {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
        <h5 className="f-w-600">{CandidateLabel.editCandidate}</h5>
        {rendorBackButton(() => navigate("/candidate"))}
      </div>
      <div className="card mx-3">
        <div className="card-body">
          <form onSubmit={candidateForm.handleSubmit}>
            <span className="p-2 d-block border-bottom text-dark">
              {PersonalDetails}
            </span>
            <div className="row px-2 mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    label={CandidateNameAsPerAdharCard}
                    name="candidate_name"
                    type="text"
                    placeholder={PlaceHolderFormat(CandidateName)}
                    value={candidateForm.values.candidate_name}
                    touched={candidateForm.touched.candidate_name}
                    error={candidateForm.errors.candidate_name}
                    handleBlur={candidateForm.handleBlur}
                    handleChange={candidateForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="contact_no"
                    type="number"
                    label={ContactNumber}
                    placeholder={PlaceHolderFormat(ContactNumber)}
                    value={candidateForm.values.contact_no}
                    touched={candidateForm.touched.contact_no}
                    error={candidateForm.errors.contact_no}
                    handleBlur={candidateForm.handleBlur}
                    handleChange={(e) => {
                      if (
                        e?.target?.value?.length <= 10 &&
                        digitRegex.test(e.target.value)
                      ) {
                        candidateForm.handleChange(e);
                      }
                    }}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="email"
                    type="text"
                    label={EmailAddress}
                    placeholder={PlaceHolderFormat(EmailAddress)}
                    value={candidateForm.values.email}
                    touched={candidateForm.touched.email}
                    error={candidateForm.errors.email}
                    handleBlur={candidateForm.handleBlur}
                    handleChange={candidateForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <label htmlFor="">
                    {Gender}
                    {isRequired}
                  </label>
                  <BaseRadioGroup
                    name="gender"
                    options={radioOptions}
                    selectedValue={selectedRadio}
                    onChange={handleRadioChange}
                    value={candidateForm.values.gender}
                    touched={candidateForm.touched.gender}
                    error={candidateForm.errors.gender}
                    handleBlur={candidateForm.handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="row px-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    name="dob"
                    type="date"
                    label={DOB}
                    placeholder={PlaceHolderFormat(DOB)}
                    value={candidateForm.values.dob}
                    touched={candidateForm.touched.dob}
                    error={candidateForm.errors.dob}
                    handleBlur={candidateForm.handleBlur}
                    handleChange={(e) => {
                      if (e.target.value < todayDate) {
                        candidateForm.handleChange(e);
                      }
                    }}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="alternative_contact_no"
                    type="number"
                    label={AlternativeContactNumber}
                    placeholder={PlaceHolderFormat(AlternativeContactNumber)}
                    value={candidateForm.values.alternative_contact_no}
                    touched={candidateForm.touched.alternative_contact_no}
                    error={candidateForm.errors.alternative_contact_no}
                    handleBlur={candidateForm.handleBlur}
                    handleChange={(e) => {
                      if (
                        e?.target?.value?.length <= 10 &&
                        digitRegex.test(e.target.value)
                      ) {
                        candidateForm.handleChange(e);
                      }
                    }}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="aadhar"
                    type="text"
                    label={Aadhar}
                    placeholder={PlaceHolderFormat(Aadhar)}
                    value={candidateForm.values.aadhar}
                    touched={candidateForm.touched.aadhar}
                    error={candidateForm.errors.aadhar}
                    handleBlur={candidateForm.handleBlur}
                    maxLength={12}
                    handleChange={(e) => {
                      if (digitRegex.test(e?.target?.value)) {
                        candidateForm.handleChange(e);
                      }
                    }}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="center"
                    label={center}
                    className="select-border"
                    placeholder={PlaceHolderFormat(center)}
                    handleChange={candidateForm.center}
                    handleBlur={candidateForm.handleBlur}
                    value={candidateForm.values.center}
                    touched={candidateForm.touched.center}
                    error={candidateForm.errors.center}
                    disabled={true}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="row px-2  mt-4">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    name="course"
                    label={course}
                    className="select-border"
                    placeholder={PlaceHolderFormat(course)}
                    handleChange={candidateForm.course}
                    handleBlur={candidateForm.handleBlur}
                    value={candidateForm.values.course}
                    touched={candidateForm.touched.course}
                    error={candidateForm.errors.course}
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="batch"
                    label={BatchName}
                    className="select-border"
                    placeholder={PlaceHolderFormat(BatchName)}
                    handleChange={candidateForm.batch}
                    handleBlur={candidateForm.handleBlur}
                    value={candidateForm.values.batch}
                    touched={candidateForm.touched.batch}
                    error={candidateForm.errors.batch}
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="status"
                    label={Status}
                    className="select-border"
                    placeholder={PlaceHolderFormat(Status)}
                    handleChange={candidateForm.status}
                    handleBlur={candidateForm.handleBlur}
                    value={candidateForm.values.status}
                    touched={candidateForm.touched.status}
                    error={candidateForm.errors.status}
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="source"
                    label={Source}
                    className="select-border"
                    placeholder={PlaceHolderFormat(Source)}
                    handleChange={candidateForm.source}
                    handleBlur={candidateForm.handleBlur}
                    value={candidateForm.values.source}
                    touched={candidateForm.touched.source}
                    error={candidateForm.errors.source}
                    disabled={true}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <span className="p-2 d-block border-bottom text-dark mt-4">
              {OtherDetails}
            </span>
            <div className="row px-2 mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseSelect
                    name="qualification"
                    label={Qualification}
                    className="select-border"
                    options={qualificationData || []}
                    placeholder={SelectPlaceHolder(Qualification)}
                    handleChange={(field, value) => {
                      candidateForm.setFieldValue(field, value);
                    }}
                    handleBlur={() =>
                      candidateForm.setFieldTouched(Qualification, true)
                    }
                    value={candidateForm.values.qualification}
                    touched={candidateForm.touched.qualification}
                    error={candidateForm.errors.qualification}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseSelect
                    name="category"
                    label={Category}
                    className="select-border"
                    options={CategoryOptions || []}
                    placeholder={SelectPlaceHolder(Category)}
                    handleChange={(field, value) => {
                      candidateForm.setFieldValue(field, value);
                    }}
                    handleBlur={() =>
                      candidateForm.setFieldTouched(Category, true)
                    }
                    value={candidateForm.values.category}
                    touched={candidateForm.touched.category}
                    error={candidateForm.errors.category}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="sector"
                    type="text"
                    label={sector}
                    placeholder={PlaceHolderFormat(sector)}
                    value={candidateForm.values.sector}
                    touched={candidateForm.touched.sector}
                    error={candidateForm.errors.sector}
                    handleBlur={candidateForm.handleBlur}
                    handleChange={candidateForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <label htmlFor="">{hostelMessage}{isRequired}</label>
                  <BaseRadioGroup
                    name="is_hostel"
                    options={HostelRadio}
                    selectedValue={radio}
                    disabled={true}
                    value={candidateForm.values.is_hostel}
                    touched={candidateForm.touched.is_hostel}
                    error={candidateForm.errors.is_hostel}
                    handleBlur={candidateForm.handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="row px-2 mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    name="father_name"
                    type="text"
                    label={FatherName}
                    placeholder={PlaceHolderFormat(FatherName)}
                    value={candidateForm.values.father_name}
                    touched={candidateForm.touched.father_name}
                    error={candidateForm.errors.father_name}
                    handleBlur={candidateForm.handleBlur}
                    handleChange={candidateForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="mother_name"
                    type="text"
                    label={MotherName}
                    placeholder={PlaceHolderFormat(MotherName)}
                    value={candidateForm.values.mother_name}
                    touched={candidateForm.touched.mother_name}
                    error={candidateForm.errors.mother_name}
                    handleBlur={candidateForm.handleBlur}
                    handleChange={candidateForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseSelect
                    label={reference}
                    name="reference"
                    options={ReferenceOptions}
                    handleChange={handleSelectChange}
                    value={candidateForm.values.reference}
                    placeholder={SelectPlaceHolder(reference)}
                    required={true}
                  />
                </div>
                {selectedOption === PMKVYStaff && (
                  <div className="col-lg-3">
                    <BaseSelect
                      label={TotLabels.staffName}
                      name="staff_id"
                      className="select-border"
                      handleChange={(field, value) => {
                        candidateForm.setFieldValue(field, value);
                        const selectedTrainer = userData?.find(
                          (user) => user?.value === value
                        );
                        candidateForm.setFieldValue(
                          "trainer_id",
                          selectedTrainer.id
                        );
                      }}
                      value={candidateForm.values.staff_id}
                      placeholder={SelectPlaceHolder(TotLabels.staffName)}
                      options={userData}
                      touched={candidateForm.touched.staff_id}
                      error={candidateForm.errors.staff_id}
                      handleBlur={candidateForm.handleBlur}
                      required={true}
                    />
                  </div>
                )}
                {selectedOption === Referral && (
                  <div className="col-lg-3">
                    <BaseInput
                      name="referral"
                      type="text"
                      label={referenceName}
                      placeholder={PlaceHolderFormat(referenceName)}
                      value={candidateForm.values.referral}
                      touched={candidateForm.touched.referral}
                      error={candidateForm.errors.referral}
                      handleBlur={candidateForm.handleBlur}
                      handleChange={candidateForm.handleChange}
                      required={true}
                    />
                  </div>
                )}
                <div className="col-lg-3">
                  <label htmlFor="">{Pwd}{isRequired}</label>
                  <BaseRadioGroup
                    name="is_pwd"
                    options={PwdRadio}
                    selectedValue={pwdRadio}
                    onChange={handleChangePwd}
                    value={candidateForm.values.is_pwd}
                    touched={candidateForm.touched.is_pwd}
                    error={candidateForm.errors.is_pwd}
                    handleBlur={candidateForm.handleBlur}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="address"
                    type="textarea"
                    label={Address}
                    placeholder={PlaceHolderFormat(Address)}
                    value={candidateForm.values.address}
                    touched={candidateForm.touched.address}
                    error={candidateForm.errors.address}
                    handleBlur={candidateForm.handleBlur}
                    handleChange={candidateForm.handleChange}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <span className="p-2 d-flex justify-content-start border-bottom text-dark">
              <span>{Documents}</span>
              <div className="tooltip-container">
                <RiQuestionFill className="tooltip-icon" size={18} />
                <span className="tooltip-text">{fileSizeMessage}</span>
              </div>
            </span>
            <div className="row px-2 mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    name="passport"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={passport}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={candidateForm.touched.passport}
                    error={candidateForm.errors.passport}
                    handleBlur={candidateForm.handleBlur}
                    required={true}
                  />
                  {candidateForm.values.passport?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${candidateForm.values.passport}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="aadharFront"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={AadharFront}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={candidateForm.touched.aadharFront}
                    error={candidateForm.errors.aadharFront}
                    handleBlur={candidateForm.handleBlur}
                    required={true}
                  />
                  {candidateForm.values.aadharFront?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${candidateForm.values.aadharFront}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="aadharBack"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={AadharBack}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={candidateForm.touched.aadharBack}
                    error={candidateForm.errors.aadharBack}
                    handleBlur={candidateForm.handleBlur}
                    required={true}
                  />
                  {candidateForm.values.aadharBack?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${candidateForm.values.aadharBack}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="qualificationCerti"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={QualificationCertificate}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={candidateForm.touched.qualificationCerti}
                    error={candidateForm.errors.qualificationCerti}
                    handleBlur={candidateForm.handleBlur}
                    required={true}
                  />
                  {candidateForm.values.qualificationCerti?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${candidateForm.values.qualificationCerti}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row px-2 mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    name="bankPassbook"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={BankPassbook}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={candidateForm.touched.bankPassbook}
                    error={candidateForm.errors.bankPassbook}
                    handleBlur={candidateForm.handleBlur}
                    required={true}
                  />
                  {candidateForm.values.bankPassbook?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${candidateForm.values.bankPassbook}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="otherDocs"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={OtherDocument}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={candidateForm.touched.otherDocs}
                    error={candidateForm.errors.otherDocs}
                    handleBlur={candidateForm.handleBlur}
                  />
                  {candidateForm.values.otherDocs?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${candidateForm.values.otherDocs}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="col-lg-3"></div>
                <div className="col-lg-3"></div>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end">
              <BaseButton
                className="mx-2"
                color="danger"
                type="button"
                onClick={() => navigate("/candidate")}
              >
                {Cancel}
              </BaseButton>
              <BaseButton
                className="mx-2"
                color="primary"
                type="submit"
                onClick={async () => {
                  const errors = await candidateForm.validateForm();
                  scrollTop(errors);
                }}
                disabled={btnLoader}
                loader={btnLoader}
                children={btnLoader ? Loading : Submit}
              ></BaseButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditCandidate;
