export const shiftSettingLabel = {
  RegularStaff: "regularstaff",
  DeactivatedStaff: "deactivatedStaff",
  ADDSHIFT: "Add Shift",
  EDITSHIFT:"Edit Shift",
  BACK: "Back",
  FIXEDSHIFT: "Fixed shift",
  SHIFTTYPE: "Shift Type : ",
  SHIFTNAME: "Shift Name :",
  SHIFTTIME: "Shift Time :",
  TO: "To",
  UNPAIDBREAK: "Unpaid Break :",
  ADD: " Add",
  ASSIGNEDSTAFF: "Assigned Staff",
  MONTHLYSTAFF: "Monthly Regular Staff ",
  DEACTIVESTAFF: " Contractual deactive staff (2)",
  DEACTIVATED: "deactived",
  STAFFASSIGNEDTOOTHER: "Staff assigned to other shift",
  SHIFT: "shift",
  CONTACT: "contact",
  EMERGENCYCONTACT: "Emergency Contact",
  SHIFTSETTING: "Shift Setting",
  GENERALSHIFT: "General shift (9:00 AM to 7:00 PM)",
  NINTOSEVEN: "9:00 AM to 7:00 PM",
  APPLIEDTO: " Applied to 12 Staff(s)",
  shiftNameLabel:"Shift Name",
  staffWithShift:"Staff With Shift",
  staffWithOutShift:"Staff WithOut Shift",
  EditShift:"Edit Shift",
  NetPayableHours: "Net Payable Hours: ",
  crateShift:"Create Shift",
  unpaidBreakMessage:"Please fill in both In Time and Out Time before adding an Unpaid Break.",
  unpaidBreak:"unpaidBreak",
  shiftTypeKey:"shiftType",
  shiftType:"Shift Type",
  shiftName:"Shift Name",
  inTime:"inTime",
  outTime:"outTime",
  Remove:"Remove",
  Add:"Add",
  ApplyShift: "Apply Shift To Staff",
  ShiftSetting:'Shift Setting',
  Fixed:"Fixed",
  ManageStaff:"Manage Staff",
  in_time:"In-time",
  Out_time:"Out-time",
  time:"00:00:00",
  outTimeCValidation:"Out-time should be great than In-time."
};

export const data = {
  RegularStaff: [
    { id: "1", name: "Kaushik Trivedi" },
    { id: "2", name: "Riya Shah" },
    { id: "3", name: "Ramesh Kumar" },
    { id: "4", name: "Jitendra Sharma" },
    { id: "5", name: "Prachi Rathod" },
    { id: "6", name: "Devangi Dube" },
    { id: "7", name: "Amit sharma" },
    { id: "8", name: "Kishan Kumar" },
    { id: "9", name: "Ram Gopal" },
    { id: "10", name: "Amit sharma" },
    { id: "11", name: "Kishan Kumar" },
    { id: "12", name: "Ram Gopal" },
  ],
  deactivatedStaff: [
    { id: "14", name: "Dev Gupta" },
    { id: "15", name: "Archana Patel" },
  ],
};

export const OtherShift = {
  regularStaff: [
    { name: "Kaushik Trivedi", shift: "General shift (9:00 AM to 7:00 PM)" },
    { name: "Riya Trivedi", shift: "General shift (9:00 AM to 7:00 PM)" },
    { name: "Dev Tripathi", shift: "General shift (9:00 AM to 7:00 PM)" },
  ],
  deactivatedStaff: [
    { name: "Riya Trivedi", shift: "General shift (9:00 AM to 7:00 PM)" },
    { name: "Dev Tripathi", shift: "General shift (9:00 AM to 7:00 PM)" },
    { name: "Rohan Sharma", shift: "General shift (9:00 AM to 7:00 PM)" },
  ],
};
export const ShiftOptions= [
  { id: 0, value: "", label: "Select Shift Type" },
  { id: 1, value: "Fixed", label: "Fixed" }
]