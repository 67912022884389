import { StatusCodes } from "http-status-codes";
import { batchallocationLabel } from "./BatchAllocation/batchallocation";
import { jwtDecode } from "jwt-decode";
import BaseInput from "../BaseComponents/BaseInput";
import { useEffect, useState } from "react";
import moment from "moment";
import { RiArrowLeftLine } from "react-icons/ri";
import { MENUITEMS } from "../Layout/SideBar-Layout/Menu";
import { fileDeleteApi } from "../Api/common";
import { toast } from "react-toastify";
import { Tooltip } from "reactstrap";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
export const MenuConstants = {
  MOBILIZATION: "Mobilization",
  MASTER: "Master",
  ACCOUNT: "Account",
  OTHER: "Other",
  HR: "HR",
  SETTING: "Setting",
  ROLE: "Role",
  NOTIFICATION: "Notification",
  APP: "APP",
};
export const moduleName = {
  Inquiry: "Inquiry",
  BatchCreation: "Batch Creation",
  BatchList: "Batch List",
  Candidate: "Candidate",
  Center: "Center",
  Category: "Category",
  Course: "Course",
  Career: "Career",
  Vendor: "Vendor",
  Bill: "Bill",
  BillApproval: "Bill Approval",
  Attendance: "Attendance",
  Notification: "Notification",
  Position: "Position",
  Stock: "Stock",
  Placement: "Placement",
  PlacementEmployer: "Employer",
  Inventory: "Inventory",
  Accreditation: "Accreditation",
  Hostel: "Hostel",
  Class: "Class",
  StaffAttendance: "Staff Attendance",
  Staff: "Staff",
  Setting: "Setting",
  Public: "Public",
  Dashboard: "Dashboard",
  LateEntry: "Late Entry",
  Holiday: "Holiday",
  Shift: "Shift",
  RolePermission: "Role Permission",
  SalaryAccess: "Salary Access",
  Leave: "Leave",
  GlobalConfiguration: "Global Configuration",
  Salary: "Salary",
  Report: "Report",
  CareerOpening: "Job Position",
  BillPayment: "Bill Payment",
  AttendanceApproval: "Attendance Approval",
  LeaveApproval: "Leave Approval",
};
export const loginMessage =
{ 
  success: "You are login successfully.",
  accessDenied: "Access denied. Please contact the Admin.",
};

export const roles = ['Admin','SuperAdmin'];
export const settingRestrictions = ["Attendance Mode", "Month Duration"];
export const getItem = (key) => {
  return sessionStorage.getItem(key);
};
export const setItem = (key, value) => {
  sessionStorage.setItem(key, value);
};
export const removeItem = (key) => {
  sessionStorage.removeItem(key);
};
export const getSessionId = (token) => {
  const decoded = jwtDecode(token);
  setItem("id", decoded && decoded?.id);
  setItem("name", decoded && decoded?.name);
  if (decoded?.center_ids) {
    setItem("center_ids", JSON.stringify(decoded.center_ids));
  }
  setItem("role", decoded && decoded?.role);
};

export const clearItem = () => {
  sessionStorage.clear();
};
export const isAuthenticated = () => {
  return !!sessionStorage.getItem("token");
};
export const setCourseIdInSessionStorage = (courseId) => {
  sessionStorage.setItem(batchallocationLabel.courseId, courseId);
};

export const getCourseIdFromSessionStorage = () => {
  return sessionStorage.getItem(batchallocationLabel.courseId);
};

export const setClassIdInSessionStorage = (classId) => {
  sessionStorage.setItem("classID", classId);
};

export const getClassIdFromSessionStorage = () => {
  return sessionStorage.getItem("classID");
};

export const setBatchIdInSessionStorage = (batchId) => {
  sessionStorage.setItem("batchID", batchId);
};

export const getBatchIdFromSessionStorage = () => {
  return sessionStorage.getItem("batchID");
};

export const setInventoryIdInSessionStorage = (inventoryId) => {
  sessionStorage.setItem("inventoryID", inventoryId);
};

export const getInventoryIdFromSessionStorage = () => {
  return sessionStorage.getItem("inventoryID");
};

export const setStaffIdInSessionStorage = (id) => {
  sessionStorage.setItem("staffID", id);
};

export const getStaffIdFromSessionStorage = () => {
  return sessionStorage.getItem("staffID");
};
export const removeStaffIdFromSessionStorage = () => {
  sessionStorage.removeItem("staffID");
};
export const removeSeconds = (time) => {
  return time && time?.substring(0, 5);
}
export const extractDate = (date) => {
  return date && moment(date).format("DD-MM-YYYY");
};
export const extractDateAsM = (date) => {
  return date && moment(date).format("D MMM, YYYY");
};
export const dateMonthName = (date) => {
  return date && moment(date).format("D MMM");
};
export const getFormatDayName = (date) => {
  return date && moment(date).format("ddd");
};
export const extractYearWiseDate = (date) => {
  return date && moment(date).format("YYYY-MM-DD");
};
export const convertToTimeFormat = (timeString) => {
  return moment(timeString, "h:mm a").format("HH:mm");
};
export const extractMonth = (date) => {
  return date && moment(date).format("YYYY-MM");
};
export const extractMonthWithName = (date) => {
  return date && moment(date).format("MMMM YYYY");
};
export const formatTime = (timeString) => {
  return timeString && moment(timeString, "HH:mm:ss").isValid()
    ? moment(timeString, "HH:mm:ss").format("hh:mm A")
    : null;
};
export const extractHoursDay = (time) => {
  return time && moment(time, "HH:mm:ss").format("hh:mm A");
};
export const convertToAmPm = (time) => {
  return moment(time, "HH:mm").format("h:mm a");
};
export const extractMonthDay = (date) => {
  const parsedDate = moment(date);
  return parsedDate.isValid() ? parsedDate.format("DD MMM, hh:mm A") : 'Invalid Date';
};
export const extractTime = (date) => {
  return date && moment(date).format("HH:mm");
};
export const extractWithTime = (date) => {
  return date && moment(date).format("h:mm a");
};
export const extractDay = (date) => {
  return date && moment(date).format("dddd");
};
export const formatDate = (selectedMonth, day) => {
  return moment(selectedMonth).date(day).format("YYYY-MM-DD");
};
export const spaceSpliter = (value) => {
  return value?.split(" ")[0];
};
export const getEndOfMonth = (date) => {
  return date ? moment(date).endOf("month").format("YYYY-MM") : "";
};
export const isDateValid = (date) => {
  return date && moment(Date, "YYYY-MM", true).isValid();
};
export const convertDate = (date) => {
  return date && moment(Date, "YYYY-MM", true).toDate();
};
export const checkStatusCodeSuccess = (data) => {
  if (data === StatusCodes.OK || StatusCodes.ACCEPTED || StatusCodes.CREATED) {
    return true;
  } else {
    return false;
  }
};
export const rendorBackButton = (onClick) => {
  return (
    <span className="px-2 btn btn-primary btn-pill" title="Back">
      <RiArrowLeftLine size={22} onClick={onClick} />
    </span>
  );
};

export const handleResponse = {
  dataNotFound: "Sorry! No Result Found.",
  nullData: "-",
  somethingWrong: "Something went wrong.",
  all: "All",
};

export const handleEditClick = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const tooltipconstants = {
  viewAttendace: "View Attendance",
};

export const notificationData = [
  {
    message: "Bill Approval Request",
    link: "/billApproval",
    iconClass: "font-primary",
    time: "10 min.",
  },
  {
    message: "Your Batch will expire in one week",
    link: "/batchlist",
    iconClass: "font-success",
    time: "1 hr",
  },
];

export const notificationLable = {
  allNotification: "All Reminder",
  viewAll: "View all",
  toastMessage: "Reminder deleted successfully",
  deleteBody: "Are you sure you want to delete this notification?",
  refresh: "Refresh",
  select: "Select All",
  deselect: "Deselect All",
  markAllAsRead: "Mark All as Read",
  clearAll: "Clear All",
  markSelectedRead: "Mark Selected as Read",
  clearSelected: "Clear Selected",
  noNotification: "No notifications available",
  seeMore: "See More",
  Action: "Action",
  Yes: "Yes",
  Bill: "Bill",
  Tot: "Tot",
  Accreditation: "Accreditation",
  Inventory: "Inventory",
};

export const Deselect_all = "Deselect All";
export const Select_all = "Select All";
export const role = {
  trainer: "Trainer",
  staff: "Staff",
};
export const All = "ALL";
export const CENTER_IDS = "center_ids";
export const SELECTED_CENTER_ID = "selected_center_id";
export const Storage = "storage";
export const ROLE_PERMISSION = "Role Permission";
export const ROLE = "Role";
export const Null = "null";
export const Undefined = "undefined";
export const settingError = "Please select center first";
export const RupeesSymbol = (value) => {
  return `Rs.${value}`;
};
export const RequiredSign = "*";
export const centerIDPayload = "?centerId=";
export const csv = "csv";
export const staff = "staff";
export const Approved = "Approved";
export const Rejected = "Rejected";
export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <BaseInput
      {...props}
      value={value}
      handleChange={(e) => setValue(e.target.value)}
    />
  );
};
export const extractYearMonth = (date) => {
  return date && moment(date, "YYYY-MM");
};
export const yearMonthOnly = (value) => {
  return value && value.format("YYYY-MM");
};
export const dateUpdate = (selectedDate, operation, amount = 1) => {
  const newDate = moment(selectedDate)[operation](amount, "days").toDate();
  return newDate;
};
export const monthUpdate = (selectedDate, operation, amount = 1) => {
  const newDate = moment(selectedDate)[operation](amount, "months").toDate();
  return newDate;
};

export const getDayName = (date) => {
  return date.toLocaleDateString("en-US", { weekday: "long" });
};
export const minDate = moment().startOf("year").toDate();
export const maxDate = moment().endOf("year").toDate();
export const renderError = (formik, fieldName) => {
  return formik.touched[fieldName] && formik.errors[fieldName] ? (
    <div className="error text-danger">{formik.errors[fieldName]}</div>
  ) : null;
};
const findPathByPermission = (permissions) => {
  const flattenMenu = (menuItems) => {
    let paths = [];
    menuItems.forEach((menu) => {
      menu.Items.forEach((item) => {
        if (item.children) {
          paths = paths.concat(item.children);
        } else {
          paths.push(item);
        }
      });
    });
    return paths;
  };

  const flattenedItems = flattenMenu(MENUITEMS);

  for (const permission of permissions) {
    const matchedItem = flattenedItems.find(
      (item) => item.title === permission.module_name
    );
    if (matchedItem) {
      return matchedItem.path;
    }
  }
  return null;
};

export const navigateToFirstModule = (navigate) => {
  const permissions = JSON.parse(sessionStorage.getItem("permissions")) || [];
  const path = findPathByPermission(permissions);
    navigate(path);
};
export const deleteUploadedfile = (fileName,isFolder,folderDirectory)=> {
  const payload = {
    generatedFileNames: [fileName]
  }
  if (isFolder) {
    payload.folderPath = folderDirectory;
  }
  setTimeout(() => {
    if (fileName) {
      fileDeleteApi(payload)
        .then((resp) => {
    if(checkStatusCodeSuccess(resp?.StatusCode)){
            return true;
    } else{
            return false;
          }
        })
        .catch((err) => {
          return err;
        })
    }
  },5000);
};
export const csvFile = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const fileDownload = (resp, type, fileName) => {
    const blob = new Blob([resp], {type: type});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    if(url){
      deleteUploadedfile(fileName);
    }
};

export const blobResponse = {
  responseType: 'blob',
};
export const arrayBufferResponse = {
  responseType: "arraybuffer",
};

export const errHandler = (err) => {
  if (Array.isArray(err?.message)) {
    err?.message?.map((message) => {
      toast.error(message);
    });
  } else{
    toast.error(err?.response?.message || err?.message);
  }
}

export const TruncateWithTooltip = ({ text, maxLength, tooltipId, isOpen, toggleTooltip }) => {
  const truncatedText =
    text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text || handleResponse.nullData;

  return (
    <>
      <span id={tooltipId}>{truncatedText}</span>
      {text?.length > maxLength && (
        <Tooltip isOpen={isOpen} target={tooltipId} toggle={toggleTooltip} placement="top">
          {text}
        </Tooltip>
      )}
    </>
  );
};

export const todayDate = new Date().toISOString().split("T")[0]; 
export const currentDateMax = new Date(new Date().setDate(new Date().getDate() - 1));
export const weekend = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};
export const formattedDate = (date) => format(date, "yyyy-MM-dd");
export const getImgType = (img) => {
  const imgType = img?.split(".")[1];

  switch (imgType) {
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "svg":
      return "image/svg+xml";
    case "gif":
      return "image/gif";
    case "apng":
      return "image/apng";
    case "avif":
      return "image/avif";
    case "webp":
      return "image/webp";
    default:
      return "image/jpeg";
  }
};

export const eventGenerator = (eventTrigger, eventMessage) => {
  const event = new CustomEvent(eventTrigger, {
    detail: { message: eventMessage },
  });
  window.dispatchEvent(event);
};

export const addEvent = (eventTrigger, eventHandler) => {
  window.addEventListener(eventTrigger, eventHandler);
};
export const removeEvent = (eventTrigger, eventHandler) => {
  window.removeEventListener(eventTrigger, eventHandler);
};
export const isUserProfile = "isProfile";
export const profileEvent = "Profile";
export const profileEventMessage = "This is user profile";
export const inventoryEvent = "Inventory";
export const notificationEvent = "Notification";
export const inventoryEventMessage = "Stock has been transferred";
export const notificationEventMessage = "Notification has been changed";
export const datepickerPopper = [
  {
    name: "preventOverflow",
    options: {
      boundary: "viewport",
    },
  },
  {
    name: "flip",
    options: {
      fallbackPlacements: ["bottom-start"],
      flipVariations: false,
    },
  },
  {
    name: "offset",
    options: {
      offset: [0, 10],
    },
  },
];
export const isRequired = <span className="text-danger">{RequiredSign}</span>;
export const handleArrayResponse = (err) => {
  if (Array.isArray(err?.response?.data?.message)) {
    err?.response?.data?.message?.map((message) => {
      toast.error(message);
    });
  } else {
    toast.error(err?.response?.data?.message || err?.message);
  }
  return err;
}

export const RouteTag = ({ link, title, Icon }) => {
  const navigate = useNavigate();

  return (
    <span
      className="p-2 px-1 rounded-circle text-muted cursor"
      onClick={() => navigate(link)}
      title={title}
    >
      <Icon size={20} />
    </span>
  );
};
export const onErrorImage ="https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png";
