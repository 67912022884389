import React from "react";
import { DebouncedInput } from "../../Constant/common";

const CustomPagination = ({
  currentPage,
  totalPages,
  onPageChange,
  onNextPage,
  children,
  onPreviousPage,
  onPageSizeChange,
  searchQuery,
  onSearchChange,
  defaultPageSize
}) => {
  const renderPaginationItems = () => {
    const MAX_PAGES_DISPLAYED = 5;
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, startPage + MAX_PAGES_DISPLAYED - 1);

    if (endPage - startPage + 1 < MAX_PAGES_DISPLAYED) {
      startPage = Math.max(1, endPage - MAX_PAGES_DISPLAYED + 1);
    }

    const pageNumbers = [];

    if (startPage > 1) {
      pageNumbers.push(
        <li key="ellipsisStart" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={currentPage === i ? "page-item active" : "page-item"}>
          <a
            href="#!"
            className="page-link"
            onClick={(e) => {
              e.preventDefault();
              onPageChange(i);
            }}
          >
            {i}
          </a>
        </li>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <li key="ellipsisEnd" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      <div className="row justify-content-between">
        <div className="col-6 d-flex justify-content-start align-items-center">
          Items per page:&nbsp;
          <select
            onChange={onPageSizeChange}
            className="form-select w-auto me-1 fs-8 ps-3 pe-4 py-1"
            defaultValue={defaultPageSize}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div className="col-4 d-flex justify-content-end">
          <DebouncedInput
            placeholder="Search..."
            value={searchQuery}
            onChange={onSearchChange}
            className="form-control"
          />
        </div>
      </div>

      <div>{children}</div>
      <div className="d-flex justify-content-end mb-2 fs-3">
        <div className="col-sm-auto">
          <ul className="pagination pagination-separated pagination-xs justify-content-center">
            <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
              <a
                href="#!"
                className="page-link"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviousPage();
                }}
              >
                <i className="fa fa-chevron-left m-r-5"></i>
              </a>
            </li>
            {renderPaginationItems()}
            <li className={currentPage === totalPages ? "page-item disabled" : "page-item"}>
              <a
                href="#!"
                className="page-link"
                onClick={(e) => {
                  e.preventDefault();
                  onNextPage();
                }}
              >
                <i className="fa fa-chevron-right m-r-5"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CustomPagination;
