import React, { useEffect, useState } from "react";
import BaseCheckbox from "../../../../BaseComponents/BaseCheckbox";
import BaseRadioGroup from "../../../../BaseComponents/BaseRadio";
import BaseButton from "../../../../BaseComponents/BaseButton";
import BaseCard from "../../../../BaseComponents/BaseCard";
import { Details, SalaryAccess } from "../../../../Constant/HR/SalaryDetails";
import Spinner from "../../../../BaseComponents/BaseLoader";
import {
  SalaryDetaillist,
  UpdateSalaryAccess,
} from "../../../../Api/SalaryDetails";
import {
  centerIDPayload,
  checkStatusCodeSuccess,
  handleResponse,
} from "../../../../Constant/common";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import { holidayLabel } from "../../../../Constant/HR_HolidayTemplate/holidayTemplate";
import { useSelectedCenter } from "../../../CenterContext";

const SalaryDetails = () => {
  const [selectedAccess, setSelectedAccess] = useState(null);
  const [selectedName, setSelectedName] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [loader, setLoader] = useState(false);
  const [salaryList, setSalaryList] = useState([]);
  const [searchSalaryList, setSearchSalaryList] = useState("");
  const [handleswitch, setHandleswitch] = useState({});
  const [checks, setChecks] = useState([]);
  const [unselectedIds, setUnselectedIds] = useState([]);
  const [searchStaff, setSearchStaff] = useState([]);
  const { selectedCenterPayload } = useSelectedCenter();
  const selectedCenter = selectedCenterPayload.center_id;

  const handleRadioChange = (event) => {
    const accessType = event.target.value;
    setSelectedAccess(accessType);

    if (accessType === Details.ALLSTAFF) {
      const allIds = salaryList.reduce((ids, staff) => {
        ids.push(staff.id);
        return ids;
      }, []);
      setChecks(allIds);
      setSelectedName(allIds);
      setUnselectedIds([]);
    } else if (accessType === Details.NOACCESSSTATUS) {
      const allIds = salaryList.map((staff) => staff.id);
      setChecks([]);
      setSelectedName([]);
      setUnselectedIds(allIds);
    }
  };

  const handleSelectAll = () => {
    const isSelected = !allSelected;
    setAllSelected(isSelected);
    const newSelectedNames = isSelected
      ? salaryList.reduce((ids, item) => {
          ids.push(item.id);
          return ids;
        }, [])
      : [];
    setSelectedName(newSelectedNames);
    setChecks(newSelectedNames);
    setUnselectedIds([]);

    setSelectedAccess(
      isSelected ? Details.ALLSTAFF : Details.NOACCESSSTATUS
    );
  };

  const handleCheckbox = (id) => {
    const isChecked = checks.includes(id);
    const newChecks = isChecked
      ? checks.filter((item) => item !== id)
      : [...checks, id];

    setChecks(newChecks);
    setSelectedName(newChecks);
    setAllSelected(newChecks.length === salaryList.length);

    const newUnselectedIds = salaryList.reduce((ids, staff) => {
      if (!newChecks.includes(staff.id) && staff.selected === 1) {
        ids.push(staff.id);
      }
      return ids;
    }, []);
    
    setUnselectedIds(newUnselectedIds);

    if (newChecks.length === salaryList.length) {
      setSelectedAccess(Details.ALLSTAFF);
    } else if (newChecks.length > 0) {
      setSelectedAccess(Details.SELECTEDSTAFF);
    } else {
      setSelectedAccess(Details.NOACCESSSTATUS);
    }
  };

  const handleToggleSwitch = (id) => {
    setHandleswitch((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    if (salaryList.some((item) => item.selected === 1)) {
      setSelectedAccess(Details.SELECTEDSTAFF);
    }
  }, [salaryList]);

  const fetchUpdateSalaryAccess = () => {
    setLoader(true);
    const salaryAccessPayload = salaryList.reduce((acc, staff) => {
      acc[staff.id] = !checks.includes(staff.id)
        ? "Disabled"
        : handleswitch[staff.id]
        ? "Current_cycle"
        : "Previous_cycle";
      return acc;
    }, {});

    const payload = {
      accessType: selectedAccess,
      selectedStaffIds: checks,
      unselectedStaffIds: unselectedIds,
      salaryAccess: salaryAccessPayload,
    };

    UpdateSalaryAccess(payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error(Details.ERROROCCURED);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchData = () => {
    setLoader(true);
    SalaryDetaillist(selectedCenter ? `${centerIDPayload}${selectedCenter}` : `${centerIDPayload}`)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          const initialChecks = [];
          const initialUnselectedIds = [];
          res.data.forEach((staff) => {
            if (staff.selected === 1) {
              initialChecks.push(staff.id);
            } else if (!initialChecks.includes(staff.id)) {
              initialUnselectedIds.push(staff.id);
            }
          });

          setSalaryList(res.data);
          setChecks(initialChecks);
          setSelectedName(initialChecks);
          setUnselectedIds(initialUnselectedIds);
        } else {
          setSalaryList([]);
          toast.error(res.message);
        }
      })
      .catch((error) => {
        setSalaryList([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchSearchData = (query) => {
    setLoader(true);
    SalaryDetaillist({ query })
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          setSearchStaff(res.data);
        } else {
          setSearchSalaryList([]);
          setSearchStaff([]);
          toast.error(res.message);
        }
      })
      .catch((error) => {
        setSearchSalaryList([]);
        toast.error(Details.ERROROCCURED);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (searchSalaryList) {
      fetchSearchData(searchSalaryList);
    } else {
      fetchData();
    }
  }, [searchSalaryList]);

  useEffect(() => {
    setAllSelected(selectedName.length === salaryList.length);
  }, [salaryList, selectedName]);

  const renderItem = (staff) => (
    <div className="row align-items-center mt-2" key={staff.id}>
      <div className="col-12 d-flex align-items-center flex-column flex-sm-row">
        <div className="col-12 col-sm-8 col-md-12 col-lg-4 d-flex align-items-center mb-2 mb-sm-0">
          <BaseCheckbox
            id={staff.id}
            type="checkbox"
            checked={checks.includes(staff.id)}
            onChange={() => handleCheckbox(staff.id)}
          />
          <label htmlFor={staff.id} className="mt-2 ms-2">
            {staff.name}
          </label>
        </div>
        <div className="col-12 col-sm-8 col-md-12 col-lg-4 d-flex align-items-center mb-3 mb-sm-0 p-2">
          {staff.contact_no ? <span>{staff.contact_no}</span> : <span>--</span>}
        </div>
        <div className="col-12 col-sm-8 col-md-12 col-lg-4 d-flex justify-content-between align-items-center mb-2 mb-md-0">
          <span>{Details.CURRENTSALARY}</span>
          {checks.includes(staff.id) && (
            <div className="switch-sm mb-1">
              <label className="switch">
                <Input
                  type="checkbox"
                  id={`switch-${staff.id}`}
                  checked={handleswitch[staff.id] || false}
                  onChange={() => handleToggleSwitch(staff.id)}
                />
                <span className="switch-state"></span>
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="container-fluid">
        {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
        <div className="page-header dash-breadcrumb py-3">
          <div className="row">
            <div className="col-6">
              <div>
                <h4 className="f-w-600">{Details.SALARYACCESS}</h4>
                <span className="text-secondary">{Details.STAFFAPP}</span>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-right">
              <a href="/setting">
                <BaseButton className="btn btn-pill btn-primary">
                  {Details.BACK}
                </BaseButton>
              </a>
            </div>
          </div>
        </div>  
        <div className="card">
          <div className="leave-categories">
            <div className="px-3 mt-2">
              <h6>{Details.SALARYDETAILS}</h6>
              <div className="form-check d-flex justify-content-between mt-2">
                <BaseRadioGroup
                  name={Details.SalaryAccess}
                  className="m-checkbox"
                  selectedValue={selectedAccess}
                  options={SalaryAccess}
                  onChange={handleRadioChange}
                />
              </div>
            </div>
          </div>
        </div>
        <BaseCard
          searchPlaceholder={Details.SEARCH}
          searchValue={searchSalaryList}
          onSearchChange={setSearchSalaryList}
          checkAllLabel={Details.SELECTALL}
          selectAllChecked={allSelected}
          onCheckAllChange={handleSelectAll}
          searchItems={searchSalaryList ? searchStaff : salaryList}
          items={salaryList || []}
          renderItem={renderItem}
          onItemCheckChange={handleCheckbox}
          showSearch={true}
          noDataMessage={handleResponse.dataNotFound}
        />
        <div className="mt-3 sticky-save-button">
          <div className="col-12 d-flex justify-content-end">
            <BaseButton
              type="submit"
              className="btn btn-success"
              onClick={fetchUpdateSalaryAccess}
              disabled={selectedAccess === null}
            >
              {holidayLabel.Save}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryDetails;
