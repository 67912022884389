import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BaseButton from "../../BaseComponents/BaseButton";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  InputPlaceHolder,
  validationMessages,
} from "../../Constant/validation";
import { Submit, Update } from "../../Constant";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseCheckbox from "../../BaseComponents/BaseCheckbox/index";
import { MenuConstants, rendorBackButton } from "../../Constant/common";
import {
  addRoleApi,
  editRoleApi,
  listOfRolePermissionApi,
  viewRolePermissionApi,
} from "../../Api/roleApi";
import { checkStatusCodeSuccess } from "../../Constant/common";
import { toast } from "react-toastify";
import Spinner from "../../BaseComponents/BaseLoader";
import {
  addRoleLabel,
  editRoleLabel,
  masterModulesLabel,
  roleNameLabel,
  viewRoleLabel,
} from "../../Constant/Role";

const AddRole = () => {
  const navigate = useNavigate();
  const { roleid, viewRoleId } = useParams();
  const [loader, setLoader] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [permissionAllowed, setPermissionAllowed] = useState([]);
  const [viewRoleData, setViewRoleData] = useState();
  const [moduleData, setModuleData] = useState({
    MOBILIZATION: [],
    MASTER: [],
    ACCOUNT: [],
    OTHER: [],
    HR: [],
    SETTING: [],
    ROLE: [],
    NOTIFICATION: [],
    APP: [],
  });
  const ViewRole = (id) => {
    setLoader(true);
    viewRolePermissionApi(id)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.data)) {
          setViewRoleData(resp?.data);
          if (roleid || viewRoleId) {
            setPermissionAllowed(
              resp?.data?.rolePermission?.map((item) => item?.masterModule.id)
            );
          }
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.response?.message || err?.message);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchMasterModules = () => {
    setLoader(true);
    listOfRolePermissionApi({})
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.data)) {
          let data = resp?.data?.listOfRolePermission;
          const updatedData = {};
          Object.keys(MenuConstants).forEach((key) => {
            updatedData[key] = data?.filter(
              (item) => item?.parent_module_name === MenuConstants[key]
            );
          });
          setModuleData(updatedData);
          toast.success(resp?.message);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.response?.message || err?.message);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const roleForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      role: roleid || viewRoleId ? viewRoleData?.role_name : "",
    },
    validationSchema: yup.object({
      role: yup.string().required(validationMessages.required(roleNameLabel)),
    }),
    onSubmit: (values) => {
      setBtnLoader(true);
      const payload = {
        role_name: values.role,
        master_module_id: permissionAllowed,
      };
      if (roleid) {
        editRoleApi(roleid, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.data)) {
              toast.success(resp?.message);
              navigate("/role");
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message || err?.message);
            return err;
          })
          .finally(() => {
            setBtnLoader(false);
            setPermissionAllowed([]);
          });
      } else {
        addRoleApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.data)) {
              toast.success(resp?.message);
              navigate("/role");
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message || err?.message);
            return err;
          })
          .finally(() => {
            setBtnLoader(false);
            setPermissionAllowed([]);
          });
      }
    },
  });

  useEffect(() => {
    if (viewRoleId) {
      setViewMode(true);
      ViewRole(viewRoleId);
    }
    if (roleid) {
      ViewRole(roleid);
    }
    fetchMasterModules();
  }, [viewRoleId, roleid]);

  const handleSelectAllChange = (key, isChecked) => {
    setPermissionAllowed((prevPermissions) => {
      const modulePermissions = moduleData[key].map((item) => item.id);
      if (isChecked) {
        return [...new Set([...prevPermissions, ...modulePermissions])];
      } else {
        return prevPermissions.filter(
          (permissionId) => !modulePermissions.includes(permissionId)
        );
      }
    });
  };
  useEffect(() => {
    const firstErrorKey = Object.keys(roleForm.errors)[0];
    const errorElement = document.getElementsByName(firstErrorKey)[0];

    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      errorElement.focus();
    }
  }, [roleForm.errors]);

  const handleCheckboxChange = (id) => {
    setPermissionAllowed((prevPermissions) => {
      if (prevPermissions.includes(id)) {
        return prevPermissions.filter((permissionId) => permissionId !== id);
      } else {
        return [...prevPermissions, id];
      }
    });
  };

  return (
    <>
      <div className="container">
        {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
        <div className="d-flex justify-content-between align-items-center mx-3 mb-1">
          <h5>
            {roleid ? editRoleLabel : viewRoleId ? viewRoleLabel : addRoleLabel}
          </h5>
          {rendorBackButton(() => navigate("/role"))}
        </div>
      </div>
      <div className="container px-4">
        <form onSubmit={roleForm.handleSubmit}>
          <div className="card d-flex mb-2 p-2">
            <div className="col-12 col-sm-4 col-lg-2 mx-2">
              <BaseInput
                name="role"
                label={roleNameLabel}
                placeholder={InputPlaceHolder(roleNameLabel)}
                value={roleForm.values.role}
                touched={roleForm.touched.role}
                disabled={viewMode}
                error={roleForm.errors.role}
                handleChange={roleForm.handleChange}
                handleBlur={roleForm.handleBlur}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mx-1">
            <h5>{masterModulesLabel}</h5>
          </div>

          <div className="card p-2">
            {Object.keys(MenuConstants).map((key) => {
              const moduleName = MenuConstants[key];   
              const data = moduleData[key];

              if (data?.length === 0) return null;
              const allSelected = data.every((item) =>
                permissionAllowed.includes(item.id)
              );
              return (
                <div key={key}>
                  <div className="d-flex justify-content-between mt-1 px-2">
                  <BaseCheckbox
                      name={`${moduleName}_select_all`}
                      checked={allSelected}
                      onChange={(e) =>
                        handleSelectAllChange(key, e.target.checked)
                      }
                      id={`${moduleName}_select_all`}
                      disabled={viewMode}
                      labelClassName="mx-2 user-select-none"
                      label={moduleName}
                    />
                  </div>
                  <div className="border-bottom d-flex">
                    {data?.map((item) => (
                      <div key={item?.id} className="d-flex">
                        <span className="p-2 mb-0 d-flex">
                          <BaseCheckbox
                            name={item?.module_name}
                            checked={permissionAllowed.includes(item?.id)}
                            onChange={() => handleCheckboxChange(item?.id)}
                            id={item?.module_name}
                            disabled={viewMode}
                            labelClassName="mx-2 user-select-none"
                            label={item?.module_name}
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          {!viewRoleId && (
            <div className="d-flex justify-content-end py-2 my-2">
              <BaseButton
                color="success"
                type="submit"
                loader={btnLoader}
                disabled={btnLoader || (permissionAllowed.length < 1)}
                children={roleid ? Update : Submit}
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default AddRole;
