import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { forgotPasswordTitle } from '../../Constant/title';
import { useFormik } from 'formik';
import { PlaceHolderFormat } from '../../Constant/requireMessage';
import { ConfirmPassword, Email, forgotPassword, Loading, Otp, Password, RememberPassword, Submit } from '../../Constant';
import BaseInput from '../../BaseComponents/BaseInput';
import BaseButton from '../../BaseComponents/BaseButton';
import { forgotPasswordApi, verifyOtpApi } from '../../Api/AuthApi';
import { checkStatusCodeSuccess } from '../../Constant/common';
import { emailRegex, passwordRegex, validationMessages } from '../../Constant/validation';
import * as yup from "yup";
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  document.title = forgotPasswordTitle;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isSecondForm, setIsSecondForm] = useState(false);
  const [email, setEmail] = useState(null);

  const firstValidation = yup.object({
    email: yup
      .string()
      .required(validationMessages.required(Email))
      .matches(emailRegex, validationMessages.format(Email)),
  });
  const secondValidation = yup.object({
    email: yup
      .string()
      .required(validationMessages.required(Email))
      .matches(emailRegex, validationMessages.format(Email)),
    otp: yup.string().required(validationMessages.required(Otp)),
    password: yup
      .string()
      .required(validationMessages.required(Password))
      .min(6, validationMessages.passwordLength(Password, 6))
      .matches(passwordRegex, validationMessages.passwordComplexity(Password)),
    confirm_password: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        validationMessages.passwordsMatch(ConfirmPassword)
      )
      .required(validationMessages.required(ConfirmPassword)),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: isSecondForm ? email : null,
      otp: null,
      password: null,
      confirm_password: null,
    },
    validationSchema: isSecondForm ? secondValidation : firstValidation,
    onSubmit: (values) => {
      setIsSecondForm(true);
      setLoader(true);
      if (!isSecondForm){
        const payload = {
          email: values.email,
        };
        verifyOtpApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              setIsSecondForm(true);
              setEmail(values.email);
            } else {
              navigate("/changePassword");
            }
          })
          .catch((err) => {
            toast.error(err);
          })
          .finally(() => {            
            setLoader(false);
          });
      } else{
        const payload = {
          email: values.email,
          otp: values.otp,
          password: values.password,
          confirmPassword: values.confirm_password,
        };
        forgotPasswordApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              navigate("/login");
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            toast.error(err);
          })
          .finally(() => {
            setLoader(false);
          });
      }
      
    },
  });

  return (
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-xl-7">
          <div className="bg-img-cover"></div>
        </div>
        <div className="col-xl-5 p-0">
          <div className="login-card">
            <form className="login-form" onSubmit={formik.handleSubmit}>
              <div className="login-header text-center mb-2">
                <h4>{forgotPassword}</h4>
              </div>
              <div className="login-social-title">
                <h5 className="text-gray">{forgotPassword}</h5>
              </div>
              <div className="form-group">
                <label>{Email}</label>
                <div className="input-group">
                  <BaseInput
                    name="email"
                    type="text"
                    icon={<i className="icon-email" />}
                    placeholder={PlaceHolderFormat(Email)}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.email}
                    touched={formik.touched.email}
                    error={formik.errors.email}
                    disabled={isSecondForm}
                  />
                </div>
              </div>
              {isSecondForm && (
                <>
                  <div className="form-group">
                    <BaseInput
                      name="otp"
                      type="text"
                      label={Otp}
                      placeholder={PlaceHolderFormat(Otp)}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      value={formik.values.otp}
                      touched={formik.touched.otp}
                      error={formik.errors.otp}
                    />
                  </div>
                  <div className="form-group">
                    <BaseInput
                      className="form-control"
                      name="password"
                      type="password"
                      label={Password}
                      icon={<i className="icon-lock" />}
                      placeholder={PlaceHolderFormat(Password)}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      value={formik.values.password}
                      touched={formik.touched.password}
                      error={formik.errors.password}
                    />
                  </div>
                  <div className="form-group">
                    <BaseInput
                      className="form-control"
                      name="confirm_password"
                      type="password"
                      label={ConfirmPassword}
                      icon={<i className="icon-lock" />}
                      placeholder={PlaceHolderFormat(ConfirmPassword)}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      value={formik.values.confirm_password}
                      touched={formik.touched.confirm_password}
                      error={formik.errors.confirm_password}
                    />
                  </div>
                </>
              )}
              <div className="form-group d-flex justify-content-end">
                <Link to={"/login"} className="link">
                  {RememberPassword}
                </Link>
              </div>
              <div className="form-group">
                <BaseButton
                  className="btn btn-pill btn-block d-flex align-items-center"
                  type="submit"
                  color="primary"
                  disabled={loader}
                  loader={loader}
                  children={loader ? Loading : Submit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;