import React, { useEffect, useState } from "react";
import {
  RiCalendarLine,
  RiArrowUpSLine,
  RiArrowDownSLine,
  RiSearchLine,
} from "react-icons/ri";
import { salaryEnums } from "../../../Constant/HR/staffSalary";
import BaseButton from "../../../BaseComponents/BaseButton";
import Spinner from "../../../BaseComponents/BaseLoader";
import { salaryOverviewTitle } from "../../../Constant/title";
import {
  listOfSalaryOverviewApi,
  viewSalaryOverviewApi,
} from "../../../Api/salaryApi";
import { useParams } from "react-router-dom";
import {
  checkStatusCodeSuccess,
  getItem,
  handleResponse,
  isUserProfile,
  RupeesSymbol,
} from "../../../Constant/common";
import { toast } from "react-toastify";
import { notFound, Submit } from "../../../Constant";
import BaseModal from "../../../BaseComponents/BaseModal";
import { validationMessages } from "../../../Constant/validation";
import { useFormik } from "formik";
import * as yup from "yup";
import { halfSalarySlipApi } from "../../../Api/reportApi";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const SalaryOverview = () => {
  document.title = salaryOverviewTitle;
  const { staffId } = useParams();
  const [openDetailsId, setOpenDetailsId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [salaryOverviewList, setSalaryOverviewList] = useState(null);
  const [salaryOverviewDetails, setSalaryOverviewDetails] = useState(null);
  const [modal, setModal] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [dateRange, setDateRange] = useState(false);
  const isProfile = JSON.parse(sessionStorage.getItem(isUserProfile) || false);
  const profileId = getItem("id");
  const finalId = isProfile ? profileId : staffId;
  const toggleDetails = (id) => {
    if (openDetailsId !== id) {
      setLoader(true);
      const payload = {
        month: id,
      };
      viewSalaryOverviewApi(finalId, payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            setSalaryOverviewDetails(resp?.data);
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message ?? err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    }
    setOpenDetailsId(openDetailsId === id ? null : id);
  };

  const fetchData = () => {
    setLoader(true);
    listOfSalaryOverviewApi(finalId)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setSalaryOverviewList(resp?.data);
          const dates = resp?.data?.map(entry => new Date(entry.duration.split(' - ')[0]));
          const minDate = new Date(Math.min(...dates));
          const maxDate = new Date(Math.max(...dates));
          setDateRange({ minDate, maxDate });
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        setSalaryOverviewList([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleSalarySlip = () => {
    setModal(!modal);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedDate: null,
    },
    validationSchema: yup.object({
      selectedDate: yup.string().required(validationMessages.required(salaryEnums.MONTH_YEAR)),
    }),
    onSubmit: async (values) => {
      setBtnLoader(true);

      const selectedDate = new Date(values.selectedDate);
      const month = selectedDate.getMonth(); 
      const year = selectedDate.getFullYear();
      const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(selectedDate.setMonth(month)));

      const payload = {
        staff_id: Number(finalId),
        month : String(monthName),
        year: String(year),
      };
      await halfSalarySlipApi(payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            const blob = new Blob([resp?.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${JSON.parse(getItem("staffData"))?.name}${salaryEnums.FILENAME}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            toast.success(resp?.success);
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || err.message);
        })
        .finally(() => {
          setBtnLoader(false);
          formik.resetForm();
          toggleSalarySlip(false);
        });
    },
  });

  return (
    <>
      <div className="row d-flex align-items-center mb-2">
        <div className="col-6">
          <h5 className="f-w-600 m-0">{salaryEnums.SALARY_OVERVIEW}</h5>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <BaseButton 
            onClick={toggleSalarySlip}
            className="btn btn-pill btn-primary mx-2">
            {salaryEnums.GENERATE_SALARY_SLIP}
          </BaseButton>
        </div>
      </div>
      <BaseModal
        isOpen={modal}
        title={salaryEnums.SALARY_SLIP}
        toggler={toggleSalarySlip}
        submit={formik.handleSubmit}
        submitText={Submit}
        disabled={btnLoader}
        loader={btnLoader}
      >
        <form onSubmit={formik.handleSubmit}>
        <label htmlFor="selectedDate">{salaryEnums.SELECT_YEAR_MONTH}</label>
        <DatePicker
              className="form-control"
              selected={formik.values.selectedDate}
              onChange={(date) => formik.setFieldValue("selectedDate", date)}
              placeholderText={salaryEnums.SELECT_YEAR_MONTH}
              showMonthYearPicker
              dateFormat="MM/yyyy"
              minDate={dateRange.minDate}
              maxDate={dateRange.maxDate}
            />
            {formik.touched.selectedDate && formik.errors.selectedDate ? (
              <div className="text-danger">{formik.errors.selectedDate}</div>
            ) : null}
        </form>
      </BaseModal>
      <div className="rounded email-wrap bookmark-wrap">
        {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
        {salaryOverviewList?.map((entry) => (
          <div key={entry?.id} className="card month-card p-2 my-1">
            <div
              className="d-flex justify-content-between align-items-center px-2"
              onClick={() => toggleDetails(entry?.date)}
            >
              <div className="d-flex align-items-center gap-4 ml-4 custom-new">
                <RiCalendarLine className="fs-5 ms-3" />
                <div className="ml-4">
                  <h6>{entry?.date}</h6>
                  <span className="text-muted fs-8">{`Duration: ${entry?.duration}`}</span>
                </div>
              </div>
              <div className="d-flex align-items-center fs-8">
                <div className="mx-4">
                  <h6>{salaryEnums.DUE_AMOUNT}</h6>
                  <span className="text-muted">
                    {RupeesSymbol(entry?.finalPayableSalary)}
                  </span>
                </div>
                {openDetailsId === entry.date ? (
                  <RiArrowUpSLine className="ml-2 fs-3" />
                ) : (
                  <RiArrowDownSLine className="ml-2 fs-3" />
                )}
              </div>
            </div>
            {openDetailsId === entry?.date && salaryOverviewDetails && (
              <div className="details">
                <div className="card-body">
                  <div className="details">
                    <div className="card-body">
                      <div className="customTop">
                        <div className="row mt-2">
                          <div className="col-12 col-md-7">
                            <div className="row">
                              <div className="col-4">
                                <span>{salaryEnums.EARNINGS}</span>
                              </div>
                              <div className="col-4">
                                <span>{salaryEnums.FULL}</span>
                              </div>
                              <div className="col-4">
                                <span>{salaryEnums.ACTUAL}</span>
                              </div>
                            </div>
                            <div className="border-bottom mb-2" />
                            <div className="row mb-3">
                          <div className="col-4 mt-2">
                            <h6>{salaryEnums.FIXED_EARNINGS}</h6>
                          </div>
                        </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <p className="text-dark fs-8">{salaryEnums.SYSTEM_BASIC}</p>
                              </div>
                              <div className="col-4">
                                <p className="text-dark fs-8">
                                  {RupeesSymbol(
                                    salaryOverviewDetails
                                      ?.monthlySalary
                                  ) ?? handleResponse.nullData}
                                </p>
                              </div>
                              <div className="col-4">
                                <p className="text-dark fs-8">
                                  {RupeesSymbol(
                                    salaryOverviewDetails?.actualSystemBasis
                                  ) ?? handleResponse.nullData}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <p className="text-dark fs-8">{salaryEnums.GROSS_EARNING}</p>
                              </div>
                              <div className="col-4">
                                <p className="text-dark fs-8">
                                  {RupeesSymbol(
                                    salaryOverviewDetails?.totalMonthlyCtc
                                  ) ?? handleResponse.nullData}
                                </p>
                              </div>
                              <div className="col-4">
                                <p className="text-dark fs-8">
                                  {RupeesSymbol(
                                    salaryOverviewDetails?.finalPayableSalary
                                  ) ?? handleResponse.nullData}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-5">
                            <div className="row">
                              <div className="col-8 col-md-6 col-lg-8">
                                <span>{salaryEnums.DEDUCTIONS}</span>
                              </div>
                              <div className="col-4 col-md-6 col-lg-4">
                                <span>{salaryEnums.ACTUAL}</span>
                              </div>
                            </div>
                            <div className="border-bottom mb-2" />
                            <div className="row mb-2">
                              <div className="col-8 col-md-6 col-lg-8">
                                <p className="text-dark fs-8">{salaryEnums.TOTAL_DEDUCTIONS}</p>
                              </div>
                              <div className="col-4 col-md-6 col-lg-4">
                                <p className="text-dark fs-8">{salaryEnums.ZERO_VALUE}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom mb-4" />
                      <div className="customNet">
                        <div className="row mb-2">
                          <div className="col-6">
                            <span>{salaryEnums.NET_PAYABLE}</span>
                          </div>
                          <div className="col-4 text-center">
                            <p className="text-dark fs-8">
                              {salaryOverviewDetails?.totalPayableDays}
                              {salaryEnums.TOTAL_PAYABLE_DAYS}
                            </p>
                          </div>
                          <div className="col-2 text-center">
                            <p className="text-dark fs-8">{RupeesSymbol(salaryOverviewDetails?.finalPayableSalary) ?? handleResponse.nullData}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-7">
                            <span>
                              {salaryEnums.DUE_AMOUNT}
                              {RupeesSymbol(salaryOverviewDetails?.finalPayableSalary) ?? handleResponse.nullData}
                            </span>
                          </div>
                          <div className="col-4 text-right"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        {((!salaryOverviewList && !loader) || (salaryOverviewList?.length < 1)) && (
          <div className="card py-4 text-center">
            <div>
              <RiSearchLine className="fs-2" />
            </div>
            <div className="mt-4">
              <h5>{notFound.dataNotFound}</h5>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SalaryOverview;
