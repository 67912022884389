import React, { useEffect, useMemo, useState } from "react";
import { hrEnums } from "../../Constant/HR_Staff/hrEnums";
import { RiAddLine, RiEyeFill, RiSearchLine } from "react-icons/ri";
import TableContainer from "../../BaseComponents/BaseTable";
import { ContactNo, Email, notFound, Search } from "../../Constant";
import { useNavigate } from "react-router-dom";
import {
  checkStatusCodeSuccess,
  extractDate,
  handleResponse,
  removeItem,
  removeStaffIdFromSessionStorage,
  setStaffIdInSessionStorage,
} from "../../Constant/common";
import { listOfStaffApi } from "../../Api/HRStaffApi";
import Spinner from "../../BaseComponents/BaseLoader";
import BaseButton from "../../BaseComponents/BaseButton";
import { useSelectedCenter } from "../CenterContext";
import { staffTitle } from "../../Constant/title";

const StaffHR = () => {
  const [staffData, setStaffData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [customPageSize, setCustomPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [searchStaffData, setSearchStaffData] = useState([]);
  const navigate = useNavigate();
  const {selectedCenterPayload} = useSelectedCenter();
  document.title = staffTitle;

  useEffect(()=>{
    removeItem("staffData");
  },[]);
  
  const fetchData = () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      order: [columnName, sortOrder],
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    listOfStaffApi(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess((resp?.statusCode))) {
          setStaffData(resp?.data?.listOfStaffDetail);
          setTotalPages(resp?.data?.totalPages);
          setTotalRecords(resp?.data?.totalRecordsCount);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
        }
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchSearchData = async () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      order: [columnName, sortOrder],
      search: searchValue,
      pageNumber: currentPage,
      pageSize: customPageSize,
    };

    await listOfStaffApi(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setSearchStaffData(resp?.data?.listOfStaffDetail);
          setTotalRecords(resp.data.totalRecordsCount);
          setTotalPages(resp?.data?.totalPages);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
        }else{
          setSearchStaffData([]);
        }
      })
      .catch((err) => {
        setSearchStaffData([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if(searchValue === "")
    {
      setCurrentPage(1)
    }
  },[searchValue])

  useEffect(() => {
    if(searchValue) {
      fetchSearchData();
    } else {
      fetchData();
    }
  }, [currentPage, customPageSize, columnName, sortOrder, searchValue]);

  const handleSearchValueChange = (value) => {
    if (value !== searchValue) {
      setCurrentPage(1);
      setSearchValue(value);
    }
  };

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const handleView = (id) => {
    setStaffIdInSessionStorage(id);
    navigate(`/staffProfile/${id}`, {
      state: {
        viewProfileMode: true,
        viewGeneralMode: true,
        viewPersonalMode: true,
        viewEmploymentMode: true,
        viewBankMode: true,
      },
    });
  };

  const handleAdd = () => {
    removeStaffIdFromSessionStorage();
    navigate("/staffProfile", {
      state: {
        viewProfileMode: false,
        viewGeneralMode: false,
        viewPersonalMode: false,
        viewEmploymentMode: false,
        viewBankMode: false,
      },
    });
  };

  const classColumns = useMemo(
    () => [
      {
        header: hrEnums.SR_NO,
        accessorKey: "serial_number",
        cell: (cell) => cell.row.index + 1,
        enableColumnFilter: false,
      },
      {
        header: hrEnums.STAFF_NAME,
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: hrEnums.CENTER,
        accessorKey: "center.center_name",
        enableColumnFilter: false,
        cell: (cell) => {
          const result = cell.row.original.centers;
          return (
            <div
              className={
                result.length > 2
                  ? `d-flex justify-content-start flex-wrap gap-2 icon tooltip-container-data`
                  : `d-flex justify-content-start flex-wrap gap-2 icon`
              }
            >
              {result.length > 2
                ? result.slice(0, 2)
                  .map((item, index) => {
                    return (
                      <>
                        <span>
                          {index === result.length
                            ? item.center_name
                            : `${item.center_name}, `}
                        </span>
                        {index === result.slice(0, 2).length - 1 && (
                          <span>+{result.slice(2).length}</span>
                        )}
                      </>
                    );
                  })
                :
                result.map((item, index) => {
                  return <span>{
                    result.length === 1 
                      ? item.center_name 
                      : index === result.length
                    ? item.center_name 
                    : `${item.center_name}, `
                  }</span>;
                })}
              <span className="tooltip-text">
                {result?.map(
                  (item, index) => {
                    return index === result.length - 1
                      ? item.center_name
                      : `${item.center_name}, `;
                  }
                )}
              </span>
            </div>
          );
        }
      },
      {
        header: ContactNo,
        accessorKey: "contact_no",
        enableColumnFilter: false,
      },
      {
        header: Email,
        accessorKey: "email",
        enableColumnFilter: false,
      },
      {
        header: hrEnums.JOINING_DATE,
        accessorKey: "staffGeneralInformation.date_of_joining",
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            extractDate(
              cell.row.original.staffGeneralInformation?.date_of_joining
            ) ?? handleResponse.nullData
          );
        },
      },
      {
        header: hrEnums.ACTION,
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell) => (
          <div className="d-flex justify-content-center icon">
            <RiEyeFill
              className="fs-5 mx-2 text-success"
              onClick={() => handleView(cell.row.original.id)}
              title={hrEnums.VIEW}
            />
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <div className="mx-3 d-block d-sm-flex justify-content-between align-items-center">
        <h5>{hrEnums.STAFF}</h5>
        <div className="col-auto d-grid d-sm-flex mb-2">
          <BaseButton
            onClick={handleAdd}
            className="mx-2 btn btn-pill btn-primary"
          >
            <span>
              <RiAddLine size={20} />
            </span>
            {hrEnums.ADD}
          </BaseButton>
        </div>
      </div>
      <div className="card mx-3">
        <div className="card-body">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          {staffData && staffData?.length > 0 && (
            <TableContainer
              totalPages={totalPages}
              totalRecords={totalRecords}
              totalNumberOfRows={totalNumberOfRows}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              fetchData={handleFetchData}
              customPageSize={customPageSize}
              setCustomPageSize={setCustomPageSize}
              fetchSortingData={handleFetchSorting}
              columns={classColumns}
              shouldLineBreak={false}
              data={searchValue ? searchStaffData : staffData || []}
              manualPagination={true}
              SearchPlaceholder={Search}
              onSearch={handleSearchValueChange}
              isGlobalFilter={true}
              tableClass="table table-bordered"
              manualFiltering={true}
            />
          )}
          {!staffData && (
            <div className="py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffHR;
