import React, { useEffect, useState } from "react";
import { salaryEnums } from "../../../Constant/HR/staffSalary";
import BaseInput from "../../../BaseComponents/BaseInput";
import { Back, History, Submit } from "../../../Constant";
import BaseButton from "../../../BaseComponents/BaseButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { validationMessages } from "../../../Constant/validation";
import {
  editSalaryStructureApi,
  effectiveCycleApi,
  viewSalaryStructureApi,
} from "../../../Api/salaryApi";
import { checkStatusCodeSuccess, handleEditClick, RupeesSymbol } from "../../../Constant/common";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { salaryStructureTitle } from "../../../Constant/title";
import Spinner from "../../../BaseComponents/BaseLoader";
import { RiArrowLeftLine, RiDraftLine, RiHistoryLine } from "react-icons/ri";

const EditSalary = () => {
  document.title = salaryStructureTitle;
  const { staffId } = useParams();
  const navigate = useNavigate();
  const [btnLoader, setBtnLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [salaryStructure, setSalaryStructure] = useState(null);
  const [previousSalary, setPreviousSalary] = useState(null);
  const [monthlyCTC, setMonthlyCTC] = useState(null);
  const [yearlyCTC, setYearlyCTC] = useState(null);
  const [currentEffectiveCycle, setCurrentEffectiveCycle] = useState(null);

  const fetchData = () => {
    setLoader(true);
    effectiveCycleApi(staffId)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp.statusCode)) {
          setCurrentEffectiveCycle(resp?.data?.shift());
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
    viewSalaryStructureApi(staffId)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp.statusCode)) {
          setSalaryStructure(resp?.data);
          setPreviousSalary(resp?.data?.monthly_salary);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
    handleEditClick();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      allowance_monthly: salaryStructure?.monthly_special_allowance,
      allowance_yearly: salaryStructure?.yearly_special_allowance,
      basic_monthly: salaryStructure?.monthly_salary,
      basic_yearly: salaryStructure?.yearly_salary,
    },
    validationSchema: yup.object({
      allowance_monthly: yup
        .string()
        .required(validationMessages.required(salaryEnums.MONTHLY_SPECIAL_ALLOWANCE)),
      allowance_yearly: yup
        .string()
        .required(validationMessages.required(salaryEnums.YEARLY_SPECIAL_ALLOWANCE)),
      basic_monthly: yup
        .string()
        .required(validationMessages.required(salaryEnums.MONTHLY_BASIC_PAY)),
      basic_yearly: yup
        .string()
        .required(validationMessages.required(salaryEnums.YEARLY_BASIC_PAY)),
    }),
    onSubmit: (values) => {
      const payload = {
        monthly_special_allowance: Number(values?.allowance_monthly),
        yearly_special_allowance: Number(values?.allowance_yearly),
        monthly_salary: Number(values?.basic_monthly),
        yearly_salary: Number(values?.basic_yearly),
        total_monthly_ctc: monthlyCTC,
        total_yearly_ctc: yearlyCTC,
      };
      apiCallFunction(payload);
    },
  });

  const apiCallFunction = (payload) => {
    setBtnLoader(true);
    editSalaryStructureApi(staffId, payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp.statusCode)) {
          toast.success(resp?.message);
          formik.resetForm();
          navigate(`/salaryStructure/${staffId}`);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? err?.message);
      })
      .finally(() => {
        setBtnLoader(false);
      });
  };

  useEffect(() => {
    const monthlyCTC =
      Number(formik.values.basic_monthly) +
      Number(formik.values.allowance_monthly);
    const yearlyCTC =
      Number(formik.values.basic_yearly) +
      Number(formik.values.allowance_yearly);
    setMonthlyCTC(monthlyCTC);
    setYearlyCTC(yearlyCTC);
  }, [
    formik.values.basic_monthly,
    formik.values.allowance_monthly,
    formik.values.basic_yearly,
    formik.values.allowance_yearly,
  ]);

  const calculateYearly=(e,field1,field2)=>{
    formik.setFieldValue(field1,e.target.value);
    const yearlyAllowance = Number(e.target.value) * 12;
    formik.setFieldValue(field2, yearlyAllowance);
  };
  const calculateMonthly=(e,field1,field2)=>{
    formik.setFieldValue(field1,e.target.value);
    const monthlyAllowance = Number(e.target.value) / 12;
    formik.setFieldValue(field2, monthlyAllowance);
  };

  return (
    <>
      <div className="row d-flex align-items-center mb-2">
        <div className="col-6">
          <h5 className="m-0">{salaryEnums.EDIT_SALARY}</h5>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <span className="p-2 px-1 rounded-circle  text-muted cursor" onClick={() => navigate(`/salaryStructure/${staffId}`)} title={Back} ><RiArrowLeftLine size={20} /></span>
          <span className="p-2 px-1 rounded-circle  text-muted cursor" onClick={() => navigate(`/reviseSalaryStructure/${staffId}`)} title={salaryEnums.REVISE} ><RiDraftLine size={20} /></span>
          <span className="p-2 px-1 rounded-circle  text-muted cursor" onClick={() => navigate(`/salaryHistory/${staffId}`)} title={History}><RiHistoryLine size={20} /></span>
        </div>
      </div>
      <div className="email-wrap bookmark-wrap">
        <div className="card border month-card p-1 mb-3">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          <div className="details">
            <div className="card-body">
              <div className="details">
                <div className="card-body">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="customTop">
                      <div className="mb-2">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-lg-3">
                                <BaseInput
                                  label={salaryEnums.EFFECTIVE_CYCLE}
                                  name="effective_cycle"
                                  type="text"
                                  disabled={true}
                                  value={currentEffectiveCycle}
                                  touched={formik.touched.effective_cycle}
                                  error={formik.errors.effective_cycle}
                                  handleBlur={formik.handleBlur}
                                  handleChange={formik.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-bottom mt-4" />
                        <div className="row mt-2 mb-4">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-lg-3 mt-0 mt-lg-4 f-w-600">
                                {salaryEnums.EMPLOYEE_SALARY_CTC}
                              </div>
                              <div className="col-lg-3">
                                <BaseInput
                                  label={salaryEnums.MONTHLY_AMOUNT}
                                  name="monthly_amount"
                                  type="text"
                                  value={RupeesSymbol(previousSalary)}
                                  touched={formik.touched.monthly_amount}
                                  error={formik.errors.monthly_amount}
                                  handleBlur={formik.handleBlur}
                                  handleChange={formik.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-3">
                              <h6>{salaryEnums.COMPONENTS}</h6>
                            </div>
                            <div className="col-3 text-center">
                              <h6>{salaryEnums.CALCULATION}</h6>
                            </div>
                            <div className="col-3 text-center">
                              <h6>{salaryEnums.MONTHLY_AMOUNT}</h6>
                            </div>
                            <div className="col-3 text-center">
                              <h6>{salaryEnums.YEARLY_AMOUNT}</h6>
                            </div>
                          </div>
                          <div className="border-bottom mb-2" />
                          <div className="row mb-3">
                            <div className="col-3">
                              <p>{salaryEnums.EARNINGS}</p>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <p>{salaryEnums.SYSTEM_BASIC}</p>
                            </div>
                            <div className="col-3 text-center">
                              <p>{salaryEnums.FIXED}</p>
                            </div>
                            <div className="col-3 text-center px-4">
                              <BaseInput
                                name="basic_monthly"
                                type="text"
                                value={formik.values.basic_monthly}
                                touched={formik.touched.basic_monthly}
                                error={formik.errors.basic_monthly}
                                handleBlur={formik.handleBlur}
                                handleChange={(e)=>calculateYearly(e,"basic_monthly","basic_yearly")}
                              />
                            </div>
                            <div className="col-3 text-center px-4">
                              <BaseInput
                                name="basic_yearly"
                                type="text"
                                value={formik.values.basic_yearly}
                                touched={formik.touched.basic_yearly}
                                error={formik.errors.basic_yearly}
                                handleBlur={formik.handleBlur}
                                handleChange={(e)=>calculateMonthly(e,"basic_yearly","basic_monthly")}
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <p>{salaryEnums.SPECIAL_ALLOWANCE}</p>
                            </div>
                            <div className="col-3 text-center">
                              <p>{salaryEnums.FIXED}</p>
                            </div>
                            <div className="col-3 text-center px-4">
                              <BaseInput
                                name="allowance_monthly"
                                type="text"
                                value={formik.values.allowance_monthly}
                                touched={formik.touched.allowance_monthly}
                                error={formik.errors.allowance_monthly}
                                handleBlur={formik.handleBlur}
                                handleChange={(e)=>calculateYearly(e,"allowance_monthly","allowance_yearly")}
                              />
                            </div>
                            <div className="col-3 text-center px-4">
                              <BaseInput
                                name="allowance_yearly"
                                type="text"
                                value={formik.values.allowance_yearly}
                                touched={formik.touched.allowance_yearly}
                                error={formik.errors.allowance_yearly}
                                handleBlur={formik.handleBlur}
                                handleChange={(e)=>calculateMonthly(e,"allowance_yearly","allowance_monthly")}
                              />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="border-bottom mb-2" />
                    <div className="customNet">
                      <div className="row mb-2">
                        <div className="col-6">
                          <h6>{salaryEnums.CTC}</h6>
                        </div>
                        <div className="col-3 text-center">
                          <p>{RupeesSymbol(monthlyCTC)}</p>
                        </div>
                        <div className="col-3 text-center">
                          <p>{RupeesSymbol(yearlyCTC)}</p>
                        </div>
                        <div className="row">
                          <div className="mt-2 col-lg-12 d-flex justify-content-end">
                            <BaseButton
                              className="mx-2"
                              color="danger"
                              type="button"
                            >
                              <Link
                                to={`/salaryStructure/${staffId}/`}
                                className="text-light"
                              >
                                {Back}
                              </Link>
                            </BaseButton>
                            <BaseButton
                              className="mx-2"
                              color="primary"
                              type="submit"
                              disabled={btnLoader}
                              loader={btnLoader}
                              children={Submit}
                            ></BaseButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSalary;
