import React, { useState } from "react";
import BaseButton from "../../../BaseComponents/BaseButton";
import BaseModal from "../../../BaseComponents/BaseModal";
import { useFormik } from "formik";
import {
  cycleDateRadio,
  cycleOption,
  formikvalidationFunction,
  getReports,
  pdfExcelRadio,
  reportEnums,
  reportPayloadFunction,
} from "../../../Constant/Report/report";
import { Back, Submit } from "../../../Constant";
import BaseSelect from "../../../BaseComponents/BaseSelect";
import {
  SelectPlaceHolder,
} from "../../../Constant/validation";
import BaseRadioGroup from "../../../BaseComponents/BaseRadio";
import { PlaceHolderFormat } from "../../../Constant/requireMessage";
import BaseInput from "../../../BaseComponents/BaseInput";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {
  accountDetailApi,
  dailyAttendanceReportApi,
  exitEmployeeApi,
  leaveBalanceApi,
  leaveSummaryApi,
  payrollAttendanceReportApi,
  staffAttendanceMusterApi,
  staffDetailApi,
} from "../../../Api/reportApi";
import { checkStatusCodeSuccess, errHandler, fileDownload, isRequired } from "../../../Constant/common";
import { toast } from "react-toastify";
import { reportTitle } from "../../../Constant/title";
import { useNavigate } from "react-router-dom";
import { useSelectedCenter } from "../../CenterContext";

const Report = () => {
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [isPdf, setIsPdf] = useState(false);
  const maxRange = new Date();
  document.title = reportTitle;
  const navigate = useNavigate();
  const reports = getReports(setCurrentReport);
  const selectedCenterPayload = useSelectedCenter().selectedCenterPayload;
  const toggleModal = () => {
    setModal(!modal);
    setLoader(false);
  };

  const apicall = (currentReport, payload) => {
    switch (currentReport) {
      case reportEnums.exitEmployee:
        return exitEmployeeApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp)) {
              const type =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              fileDownload(resp?.data, type, reportEnums.exitEmployeeName);
              toast.success(resp?.success);
              formik.resetForm();
            } else {
              toast.error(resp);
            }
          })
          .catch((err) => {
            errHandler(err);
          })
          .finally(() => {
            setLoader(false);
            setModal(false);
          });

      case reportEnums.musterRoll:
        return staffAttendanceMusterApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp)) {
              const type = isPdf
                ? "application/pdf"
                : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              fileDownload(resp?.data, type, reportEnums.musterRollName);
              toast.success(resp?.success);
              formik.resetForm();
            } else {
              toast.error(resp);
            }
          })
          .catch((err) => {
            errHandler(err);
          })
          .finally(() => {
            setLoader(false);
            setIsPdf(false);
            setModal(false);
          });

      case reportEnums.dailyAttendance:
        return dailyAttendanceReportApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp)) {
              const type = isPdf
                ? "application/pdf"
                : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              fileDownload(resp?.data, type, reportEnums.dailyAttendanceName);
              toast.success(resp.data);
              formik.resetForm();
            } else {
              toast.error(resp);
            }
          })
          .catch((err) => {
            errHandler(err);
          })
          .finally(() => {
            setLoader(false);
            setIsPdf(false);
            setModal(false);
          });

      case reportEnums.attendancePayroll:
        return payrollAttendanceReportApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp)) {
              const type = "application/pdf";
              fileDownload(resp?.data, type, reportEnums.attendancePayrollName);
              toast.success(resp);
              formik.resetForm();
            } else{
              toast.error(resp);
            }
          })
          .catch((err) => {
            errHandler(err);
          })
          .finally(() => {
            setLoader(false);
            setModal(false);
          });

      case reportEnums.accountDetail:
        return accountDetailApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp)) {
              const type =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              fileDownload(resp?.data, type, reportEnums.accountDetailName);
              toast.success(resp.data);
              formik.resetForm();
            } else{
              toast.error(resp);
            }
          })
          .catch((err) => {
            errHandler(err);
          })
          .finally(() => {
            setLoader(false);
            setModal(false);
          });

      case reportEnums.staffDetail:
        return staffDetailApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp)) {
              const type =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              fileDownload(resp?.data, type, reportEnums.staffDetailName);
              toast.success(resp.data);
              formik.resetForm();
            } else{
              toast.error(resp);
            }
          })
          .catch((err) => {
            errHandler(err);
          })
          .finally(() => {
            setLoader(false);
            setModal(false);
          });

      case reportEnums.leaveBalance:
        return leaveBalanceApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp)) {
              const type =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              fileDownload(resp?.data, type, reportEnums.leaveBalanceName);
              toast.success(resp.data);
              formik.resetForm();
            } else {
              toast.error(resp);
            }
          })
          .catch((err) => {
            errHandler(err);
          })
          .finally(() => {
            setLoader(false);
            setModal(false);
          });

      case reportEnums.leaveSummary:
        return leaveSummaryApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp)) {
              const type =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              fileDownload(resp?.data, type, reportEnums.leaveSummaryName);
              toast.success(resp?.success);
              formik.resetForm();
            } else {
              toast.error(resp);
            }
          })
          .catch((err) => {
            errHandler(err);
          })
          .finally(() => {
            setLoader(false);
            setModal(false);
          });

      default:
        return {};
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      report_cycle: null,
      date_range_from: null,
      date_range_end: null,
      cycle_date: null,
      pdf_excel: null,
      attendance_detail: null,
      attendance_date: null,
      cycle: null,
    },
    validationSchema: formikvalidationFunction(currentReport, dateRange),
    onSubmit: (values) => {
      setLoader(true);
      const payload = reportPayloadFunction(currentReport, formik, selectedCenterPayload, dateRange);
      apicall(currentReport, payload);
    },
  });

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center mb-2 mx-4 px-4">
        <h5>{reportEnums.report}</h5>
        <BaseButton
          className="btn btn-pill"
          type="button"
          children={Back}
          onClick={() => navigate('/setting')}
        />
        </div>
      <BaseModal
        isOpen={modal}
        title={currentReport}
        toggler={() => toggleModal()}
        submit={formik.handleSubmit}
        submitText={Submit}
        disabled={loader}
        loader={loader}
      >
        <form onSubmit={formik.handleSubmit}>
          {currentReport === reportEnums.musterRoll && (
            <div className="mx-3">
              <div className="m-n5 form-label">
                {reportEnums.downloadBy}
                {isRequired}
              </div>
              <BaseRadioGroup
                name="cycle_date"
                options={cycleDateRadio}
                selectedValue={formik.values.cycle_date}
                onChange={(e) => {
                  setDateRange(e.target.value);
                  formik.setFieldValue("cycle_date", e.target.value);
                }}
                classNameLabel="cycle_date"
                value={formik.values.cycle_date}
                touched={formik.touched.cycle_date}
                error={formik.errors.cycle_date}
                handleBlur={formik.handleBlur}
              />
            </div>
          )}
          {currentReport === reportEnums.musterRoll &&
            dateRange === reportEnums.dateRange && (
              <div className="mx-3">
                <div>{reportEnums.dateRange}</div>
                <div className="d-flex justfy-content-start gap-2 mb-4">
                  <BaseInput
                    name="date_range_from"
                    type="date"
                    placeholder={PlaceHolderFormat(reportEnums.reportCycleFrom)}
                    value={formik.values.date_range_from}
                    touched={formik.touched.date_range_from}
                    error={formik.errors.date_range_from}
                    handleBlur={formik.handleBlur}
                    handleChange={(e) => {
                      formik.handleChange(e);
                    }}
                    required={true}
                  />
                  <BaseInput
                    name="date_range_end"
                    type="date"
                    placeholder={PlaceHolderFormat(reportEnums.reportCycleEnd)}
                    value={formik.values.date_range_end}
                    touched={formik.touched.date_range_end}
                    error={formik.errors.date_range_end}
                    handleBlur={formik.handleBlur}
                    handleChange={(e) => {
                      formik.handleChange(e);
                    }}
                    required={true}
                  />
                </div>
              </div>
            )}
          {(currentReport === reportEnums.exitEmployee ||
            (currentReport === reportEnums.musterRoll &&
              dateRange === reportEnums.salaryCycle) ||
            currentReport === reportEnums.leaveSummary) && (
            <div className="mx-3 mb-3">
              <label htmlFor="selectedDate">{reportEnums.reportCycle}{isRequired}</label>
              <DatePicker
                className="form-control"
                selected={formik.values.report_cycle}
                onChange={(date) => formik.setFieldValue("report_cycle", date)}
                placeholderText={reportEnums.reportCycle}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                maxDate={maxRange}
              />
              {formik.touched.report_cycle && formik.errors.report_cycle ? (
                <div className="text-danger">{formik.errors.report_cycle}</div>
              ) : null}
            </div>
          )}
          {currentReport === reportEnums.dailyAttendance && (
            <div className="mb-3 mx-3">
              <BaseInput
                label={reportEnums.attendanceDate}
                name="attendance_date"
                type="date"
                placeholder={PlaceHolderFormat(reportEnums.attendanceDate)}
                value={formik.values.attendance_date}
                touched={formik.touched.attendance_date}
                error={formik.errors.attendance_date}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                required={true}
              />
            </div>
          )}
          {currentReport === reportEnums.attendancePayroll && (
            <div className="mx-3">
              <BaseSelect
                name="cycle"
                label={reportEnums.cycle}
                className="select-border"
                options={cycleOption}
                placeholder={SelectPlaceHolder(reportEnums.cycle)}
                handleChange={(field, value) => {
                  formik.setFieldValue(field, value);
                }}
                handleBlur={() => formik.setFieldTouched("cycle", true)}
                value={formik.values.cycle}
                touched={formik.touched.cycle}
                error={formik.errors.cycle}
                required={true}
              />
            </div>
          )}
          {(currentReport === reportEnums.musterRoll ||
            currentReport === reportEnums.dailyAttendance) && (
            <div className="mx-3">
              <div className="">{reportEnums.reportType}{isRequired}</div>
              <BaseRadioGroup
                name="pdf_excel"
                options={pdfExcelRadio}
                selectedValue={formik.values.pdf_excel}
                onChange={(e) => {
                  formik.setFieldValue("pdf_excel", e.target.value);
                  if (e.target.value === reportEnums.PDF) {
                    setIsPdf(true);
                  }
                }}
                value={formik.values.pdf_excel}
                touched={formik.touched.pdf_excel}
                error={formik.errors.pdf_excel}
                handleBlur={formik.handleBlur}
                required={true}
              />
            </div>
          )}
          {((currentReport === reportEnums.accountDetail) ||
          (currentReport === reportEnums.staffDetail) ||
          (currentReport === reportEnums.leaveBalance)) && (
            <span>{reportEnums.confirmationText}</span>
          ) }
        </form>
      </BaseModal>
      {reports?.map((report, index) => (
        <div
          key={index}
          className="list-group-item holiday-template bg-white py-2 px-5 mb-2"
        >
          <div className="row align-items-center">
            <div className="col-8 p-2">
              <h6 className="title text-dark">{report?.title}</h6>
              <div className="title">{report?.subtitle}</div>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <BaseButton
                className="mx-2"
                type={Submit}
                onClick={() => {
                  report.stateSetter();
                  toggleModal();
                }}
                children={reportEnums.generate}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Report;