import React from "react";
import BaseButton from "../../BaseComponents/BaseButton";
import { formFields, hrEnums } from "../../Constant/HR_Staff/hrEnums";
import { SelectPlaceHolder } from "../../Constant/validation";
import BaseSelect from "../../BaseComponents/BaseSelect";
import MultiSelect from "../../BaseComponents/BaseSelect/MultiSelect";
import { handleResponse, RequiredSign } from "../../Constant/common";
import { RiCheckLine, RiCloseLine, RiEditBoxLine } from "react-icons/ri";
import { Edit } from "../../Constant";

const GeneralDetails = ({
  staffForm,
  viewGeneralMode,
  handleGeneralEdit,
  handleGeneralDiscard,
  editGeneralMode,
  handleMulti,
  selectedMulti,
  holidayData,
  staffData,
  leaveData,
  shiftData,
  isEditMode,
  btnLoader,
  isProfile,
}) => {
  return (
    <>
      {viewGeneralMode ? (
        <div id="general-info" className="info-section">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h5 className="f-w-600">{hrEnums.GENERAL_INFO}</h5>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              {!isProfile && (
              <BaseButton
                onClick={handleGeneralEdit}
                title={Edit}
                className="mx-2 btn-pill btn-light"
                children={
                  <RiEditBoxLine
                    size={20}
                  />
                }
              />)}
            </div>
          </div>
          <div className="border-bottom mb-2 mt-2 mr-4"></div>
          <div className="row custom-section">
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.WEEK_OFF}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                {staffData?.assignedWeekOff?.map((item, index) => (
                  <span key={index}>
                    {item.week_off}
                    {index < staffData?.assignedWeekOff?.length - 1 && ", "}
                  </span>
                )) ?? handleResponse.nullData}
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.HOLIDAY_TEMPLATE}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffGeneralInformation?.holiday?.template_name ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.LEAVE_TEMPLATE}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffGeneralInformation?.leave?.leave_template_name ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.SHIFT}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffGeneralInformation?.shift?.shift_name ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.SALARY_ACCESS}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffGeneralInformation?.salary_access ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.ATTENDANCE_MODE}
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffGeneralInformation?.attendance_mode ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="general-info" className="info-section">
          <div className="row mt-4 d-flex align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h5 className="f-w-600">{hrEnums.GENERAL_INFO}</h5>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              {editGeneralMode && (
                <>
                  <BaseButton
                    onClick={handleGeneralDiscard}
                    className="btn btn-pill btn-light"
                    children={<RiCloseLine
                      size={20}
                    />}
                  />
                  <BaseButton
                    className="mx-2 ms-1"
                    color="light"
                    type="submit"
                    loader={btnLoader}
                    children={<RiCheckLine size={20} />}
                  />
                </>
              )}
            </div>
          </div>
          <div className="border-bottom mb-4 mt-2 mr-4"></div>
          <div className="row custom-section">
            <div className="col-lg-3">
              <MultiSelect
                id={formFields.week_off}
                name={formFields.week_off}
                label={
                  <>
                    {hrEnums.WEEK_OFF}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                className="select-border"
                options={hrEnums.WEEK_OFF_OP}
                placeholder={SelectPlaceHolder(hrEnums.WEEK_OFF)}
                handleBlur={staffForm.handleBlur}
                onChange={handleMulti}
                value={selectedMulti ?? null}
                touched={staffForm.touched.week_off}
                error={staffForm.errors.week_off}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.holiday_template}
                name={formFields.holiday_template}
                label={
                  <>
                    {hrEnums.HOLIDAY_TEMPLATE}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                className="select-border"
                options={holidayData}
                placeholder={SelectPlaceHolder(hrEnums.HOLIDAY_TEMPLATE)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.HOLIDAY_TEMPLATE, true)
                }
                value={staffForm.values.holiday_template}
                touched={staffForm.touched.holiday_template}
                error={staffForm.errors.holiday_template}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.leave_template}
                name={formFields.leave_template}
                label={
                  <>
                    {hrEnums.LEAVE_TEMPLATE}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                className="select-border"
                options={leaveData}
                placeholder={SelectPlaceHolder(hrEnums.LEAVE_TEMPLATE)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.LEAVE_TEMPLATE, true)
                }
                value={staffForm.values.leave_template}
                touched={staffForm.touched.leave_template}
                error={staffForm.errors.leave_template}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.shift}
                name={formFields.shift}
                label={
                  <>
                    {hrEnums.SHIFT}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                className="select-border"
                options={shiftData}
                placeholder={SelectPlaceHolder(hrEnums.SHIFT)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.SHIFT, true)
                }
                value={staffForm.values.shift}
                touched={staffForm.touched.shift}
                error={staffForm.errors.shift}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.salary_access}
                name={formFields.salary_access}
                label={
                  <>
                    {hrEnums.SALARY_ACCESS}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                className="select-border"
                options={hrEnums.SALARY_ACCESS_OP}
                placeholder={SelectPlaceHolder(hrEnums.SALARY_ACCESS)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.SALARY_ACCESS, true)
                }
                value={staffForm.values.salary_access}
                touched={staffForm.touched.salary_access}
                error={staffForm.errors.salary_access}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.attendance_mode}
                name={formFields.attendance_mode}
                label={hrEnums.ATTENDANCE_MODE}
                className="select-border"
                options={hrEnums.ATTENDANCE_MODE_OP}
                placeholder={SelectPlaceHolder(hrEnums.ATTENDANCE_MODE)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.ATTENDANCE_MODE, true)
                }
                value={staffForm.values.attendance_mode}
                touched={staffForm.touched.attendance_mode}
                error={staffForm.errors.attendance_mode}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GeneralDetails;
