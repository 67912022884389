import React, { useEffect, useMemo, useState } from "react";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiEyeFill,
  RiPencilFill,
  RiUserAddFill,
} from "react-icons/ri";
import TableContainer from "../../BaseComponents/BaseTable";
import { useNavigate } from "react-router-dom";
import BaseModal from "../../BaseComponents/BaseModal";
import {
  deleteRoleApi,
  listOfRoleApi,
} from "../../Api/roleApi";
import { toast } from "react-toastify";
import Spinner from "../../BaseComponents/BaseLoader";
import { checkStatusCodeSuccess } from "../../Constant/common";
import { Action, Active, Deactive, Search, Status } from "../../Constant";
import {
  ACCESSOR_KEYS,
  activateLabel,
  areYouSureLabel,
  confirmationLabel,
  deactivateLabel,
  editRoleLabel,
  itemLabel,
  newRoleLabel,
  permissionsLabel,
  plusLabel,
  roleLabel,
  roleNameLabel,
  viewRoleLabel,
} from "../../Constant/Role";
import { editBatchLable } from "../../Constant/BatchAllocation/batchallocation";
import BaseButton from "../../BaseComponents/BaseButton";

const Role = () => {
  document.title = roleLabel
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [customPageSize, setCustomPageSize] = useState(5);
  const [toggleValue, setToggleValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [activeId, setActiveId] = useState(false);
  const [activeStatus, setActiveStatus] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [roleSearchData, setRoleSearchData] = useState([]);
  const [errorModal, setErrorModal] = useState(false);

  const toggleActive = (value, id, status) => {
    setToggleValue(value);
    setActiveId(id);
    setActiveStatus(status);
    setErrorModal(!errorModal);
  };

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };

  const handleSearchValueChange = (value) => {
    if (value !== searchValue) {
      setCurrentPage(1);
      setSearchValue(value);
    }
  };
  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const fetchRolePermission = () => {
    setLoader(true);
    const payload = {
      order: [columnName, sortOrder],
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    listOfRoleApi(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.data)) {
          setRoleData(resp?.data?.listOfRoles);
          setTotalRecords(resp.data.totalRecordsCount);
          setTotalPages(resp?.data?.totalPages);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
          if (searchValue) {
            fetchSearchRolePermission();
          }
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchSearchRolePermission = () => {
    setLoader(true);
    const payload = {
      order: [columnName, sortOrder],
      search: searchValue,
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    listOfRoleApi(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.data)) {
          setRoleSearchData(resp?.data?.listOfRoles);
          setTotalRecords(resp.data.totalRecordsCount);
          setTotalPages(resp?.data?.totalPages);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
        } else {
          setRoleSearchData([]);
        }
      })
      .catch((err) => {
        setRoleSearchData([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (searchValue) {
      fetchSearchRolePermission();
    } else {
      fetchRolePermission();
    }
  }, [currentPage, customPageSize, sortOrder, columnName, searchValue]);

  const toggleActiveDeactive = () => {
    setBtnLoader(true);
    const payload = {
      isActive: !activeStatus,
    };
    deleteRoleApi(activeId, payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.data)) {
          toast.success(resp?.message);
          fetchRolePermission();
          setErrorModal(!errorModal);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.response?.message || err?.message);
        return err;
      })
      .finally(() => {
        setBtnLoader(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        header: ACCESSOR_KEYS.SRNOLABEL,
        accessorKey: ACCESSOR_KEYS.SERIAL_NUMBER,
        cell: (cell) => (currentPage - 1) * customPageSize + cell.row.index + 1,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: roleNameLabel,
        accessorKey: ACCESSOR_KEYS.ROLE_NAME,
        enableColumnFilter: false,
      },
      {
        header: permissionsLabel,
        accessorKey: ACCESSOR_KEYS.PERMISSIONS,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const permissions =
            (Array.isArray(row.original.rolePermission) &&
              row.original.rolePermission) ||
            [];
          const displayedPermissions = permissions?.slice(0, 3);
          const remainingCount = permissions.length - 3;

          if (permissions.length === 0) {
            return (
              <div className="d-flex justify-content-center permission-badge icon">
                <span className="badge bg-light text-dark">
                  {row.original?.rolePermission?.masterModule?.module_name}
                </span>
              </div>
            );
          } else {
            return (
              <div className="d-flex justify-content-center permission-badge icon">
                {displayedPermissions.map((item, index) => (
                  <span key={index} className="badge bg-light text-dark">
                    {item?.masterModule?.module_name}
                  </span>
                ))}
                {remainingCount > 0 && (
                  <span className="badge bg-light text-dark">
                    {plusLabel}
                    {remainingCount}
                  </span>
                )}
              </div>
            );
          }
        },
      },
      {
        header: Status,
        accessorKey: ACCESSOR_KEYS.STATUS,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell) => (
          <div className="d-flex justify-content-center permission-badge icon">
            <span
              className={`badge badge-${
                cell.row.original?.isActive === true ? "primary" : "danger"
              }`}
            >
              {cell.row.original?.isActive === true ? Active : Deactive}
            </span>
          </div>
        ),
      },
      {
        header: Action,
        accessorKey: ACCESSOR_KEYS.ACTION,
        cell: (cell) => (
          <div className="d-flex justify-content-center icon">
            <span 
            className={`${
              cell.row.original?.isActive === false && "icon-disabled"
            }`}
            >
              <RiPencilFill
                size={20}
                className="text-primary mx-1"
                title={editRoleLabel}
                onClick={() => navigate(`/editRole/${cell.row.original.id}`)}
              />
            </span>
            {cell.row.original?.isActive === true ? (
              <span>
                <RiCloseCircleFill
                  size={20}
                  className="text-danger mx-1"
                  title={deactivateLabel}
                  onClick={() =>
                    toggleActive(
                      Active,
                      cell.row.original.id,
                      cell.row.original.isActive
                    )
                  }
                />
              </span>
            ) : (
              <span>
                <RiCheckboxCircleFill
                  size={20}
                  className="text-success mx-1"
                  title={activateLabel}
                  onClick={() =>
                    toggleActive(
                      Deactive,
                      cell.row.original.id,
                      cell.row.original.isActive
                    )
                  }
                />
              </span>
            )}
            <span>
              <RiEyeFill
                size={20}
                className="text-info mx-1"
                title={viewRoleLabel}
                onClick={() => navigate(`/viewRole/${cell.row.original.id}`)}
              />
            </span>
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    [currentPage, customPageSize]
  );

  return (
    <div className="container">
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h5>{roleLabel}</h5>
          <BaseButton
            className="btn btn-primary btn-pill d-flex justify-content-center align-items-center"
            onClick={() => navigate("/addRole")}
            children={
              <>
                <span>{newRoleLabel}</span>
                <RiUserAddFill size={25} className="px-1 text-white fs-6" />
              </>
            }
          />
        </div>
      </div>
      <div className="card border-0 mt-2 p-2">
        <TableContainer
          totalPages={totalPages}
          totalRecords={totalRecords}
          totalNumberOfRows={totalNumberOfRows}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          fetchData={handleFetchData}
          customPageSize={customPageSize}
          setCustomPageSize={setCustomPageSize}
          columns={columns}
          tableClass="table table-sm"
          data={searchValue ? roleSearchData : roleData || []}
          shouldLineBreak={false}
          onSearch={handleSearchValueChange}
          fetchSortingData={handleFetchSorting}
          SearchPlaceholder={Search}
          isGlobalFilter={true}
          manualPagination={true}
          manualFiltering={true}
        />
      </div>
      <BaseModal
        isOpen={errorModal}
        toggler={() => setErrorModal(false)}
        title={confirmationLabel}
        submitText={editBatchLable.yesLabel}
        disabled={btnLoader}
        loader={btnLoader}
        submit={toggleActiveDeactive}
      >
        {areYouSureLabel}
        {toggleValue === Active ? deactivateLabel : activateLabel}
        {itemLabel}
      </BaseModal>
    </div>
  );
};

export default Role;
