import React, { useEffect, useState } from "react";
import "../../../src/hr.css";
import {
  RiArrowRightSLine,
  RiArrowLeftSLine,
  RiSearchLine,
  RiCheckboxCircleFill,
  RiCheckboxBlankCircleFill,
} from "react-icons/ri";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseModal from "../../BaseComponents/BaseModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import BaseSelect from "../../BaseComponents/BaseSelect";
import BaseRadioGroup from "../../BaseComponents/BaseRadio";
import {
  attendanceLabel,
  holiday,
  nameLabel,
  paidLeave,
  weekOff,
} from "../../Constant/HR_Attendance/hrAttendance";
import {
  SelectPlaceHolder,
  validationMessages,
} from "../../Constant/validation";
import { HOLIDAY_OFF, notFound, Submit, Update, WEEK_OFF } from "../../Constant";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import {
  addNote,
  addStaffAttendance,
  attendanceMusterRollReport,
  listOfWorkingDay,
  revertStatus,
  revertWeekOffStatus,
  viewAttendance,
  viewLogs,
} from "../../Api/HrAttendance";
import {
  checkStatusCodeSuccess,
  convertToAmPm,
  convertToTimeFormat,
  dateMonthName,
  extractDate,
  extractDateAsM,
  extractHoursDay,
  extractMonth,
  extractMonthDay,
  extractMonthWithName,
  getFormatDayName,
  monthUpdate,
} from "../../Constant/common";
import { toast } from "react-toastify";
import Spinner from "../../BaseComponents/BaseLoader";
import { staffAttendance } from "../../Constant/title";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import StaffCard from "../../BaseComponents/StaffCard";
import CountUp from "react-countup";
import CustomPagination from "../../BaseComponents/BasePagination";

const AttendanceDetails = () => {
  const { staffId } = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentAttendanceId, setCurrentAttendanceId] = useState(null);
  const [currentStaffDate, setCurrentStaffDate] = useState('')
  const [modalType, setModalType] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [noteModal, setNoteModal] = useState(false);
  const [logModal, setLogModal] = useState(false);
  const [attendancedata, setAttendanceData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [logs, setLogs] = useState([]);
  const [countData, setCountData] = useState([]);
  const [attendanceId, setAttendanceId] = useState(null);
  const [workData, setWorkData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [halfLeaveData, setHalfLeaveData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  const [staffDetail, setStaffDetail] = useState('')
  const [ leaveType, setLeaveType] = useState(null);
  const [halfLeave, setHalfLeave] = useState(null);
  let weekOffStatus = false;
  let holidayOffStatus = false;
  let compOffHistory;

  document.title = staffAttendance;
  const fetchData = (date, query) => {
    setLoader(true);
    const payload = {
      searchDate: extractMonth(date),
      staff_id: parseInt(staffId),
      pageSize: pageSize,
      pageNumber: currentPage,
      search: query || "",
      order: [
        "date",
        "asc"
      ],
    };
    attendanceMusterRollReport(payload)
      .then((res) => {                
        if (checkStatusCodeSuccess(res.statusCode)) {
          setAttendanceData(res?.data?.transformedResponse);
          setCountData(res?.data);   
          setTotalPages(res?.data?.totalPages);  
        } else {
          setAttendanceData([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setAttendanceData([]);
        setCountData([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  
  const fetchWorkingDay = () => {
    setLoader(true);
    const payload = {
      condition: {
        staff_id: staffId,
      },
    };
    listOfWorkingDay(payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {          
          let data = res?.data?.listOfComOffLeave;
          setWorkData(
            data?.map((item) => ({
              value: item?.id,
              label: extractDate(item?.date),
              id: item?.id,
            }))
          );
        } else {
          setWorkData([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setWorkData([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleHalfDaySubmit = () => {
    formikHalfDay.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setSecondModalOpen(true);
      }
    });
  };
  
  const fetchLeaveCount = () => {
    setLoader(true);
    const payload = {
      searchDate: currentStaffDate,
      condition: {
        id: currentAttendanceId,
      },
    };

    viewAttendance(payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {          
          let data = res?.data?.listOfStaffDetail[0]?.staffId?.leaveHistory;

          const leaveOption = data?.filter((item) => ((item.remaining_leave > 0) || (item.leave_category_name === attendanceLabel.UnpaidLeave)))  
          .map((item) => ({
            value: item?.leave_category_name,
            label: item?.leave_category_name,
            labelSuffix: item.leave_category_name === attendanceLabel.UnpaidLeave 
            ? '' 
            : `(${item.remaining_leave} ${attendanceLabel.left})`,
            id: item?.id.toString(),
          }));          
          const halfOption = data.filter((item) => ((item.remaining_leave >= 1) || (item.leave_category_name === attendanceLabel.UnpaidLeave)))  
          .map((item) => ({
            value: item?.leave_category_name,
            label: item?.leave_category_name,
            labelSuffix: item.leave_category_name === attendanceLabel.UnpaidLeave 
            ? '' 
            : `(${item.remaining_leave} ${attendanceLabel.left})`,
            id: item?.id.toString(),
          }));    
          setHalfLeaveData(halfOption); 
          setLeaveData(leaveOption); 
          const leave = res?.data?.listOfStaffDetail[0]?.staffAttendanceHistory[0];
          if(leave?.attendance_status === attendanceLabel.HalfDay){
            setHalfLeave(leave.leave_type)
          } else if(leave?.attendance_status === attendanceLabel.PaidLeaveLabel) {
            setLeaveType(leave.leave_type);
          }       
        } else {
          setLeaveData([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setLeaveData([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageSizeChange = (e) => {    
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    if (
      modalType === attendanceLabel.paidLeaveModal &&
      selectedDate &&
      currentAttendanceId
    ) {
      fetchLeaveCount(selectedDate);
    }
  }, [selectedDate, modalType, currentAttendanceId]);

  useEffect(() => {
    if (
      modalType === attendanceLabel.halfDayModal &&
      selectedDate &&
      currentAttendanceId
    ) {
      fetchLeaveCount(selectedDate);
    }
  }, [selectedDate, modalType, currentAttendanceId]);
  useEffect(() => {
    if (modalType === attendanceLabel.compOffModal) {
      fetchWorkingDay();
    }
  }, [modalType, currentAttendanceId]);

  const RevertStatusToHoliday = (id) =>{
    setLoader(true);
    revertStatus(id)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          toast.success(res?.message)
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setLoader(false);
        fetchData(selectedDate)
      });
  }

  const RevertStatusToWeekOff = (id) =>{
    setLoader(true);
    revertWeekOffStatus(id)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          toast.success(res?.message)
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setLoader(false);
        fetchData(selectedDate)
      });
  }
  
  useEffect(() => {
    fetchData(selectedDate, searchQuery);
  }, [selectedDate, searchQuery, currentPage, pageSize, totalPages]);  
  const handleViewLogs = async (data) => {
    
    setLoader(true);
    setLogModal(true);    
    viewLogs(data?.staff_attendance_id)
      .then((res) => {        
        if (checkStatusCodeSuccess(res.statusCode)) {
          setLogs(res.data);
        } else {
          setLogs([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setLogs([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };  

  const handleNoteModal = (id, currentNote) => {
    setAttendanceId(id);
    formikNote.setFieldValue(nameLabel.note, currentNote ?? "");
    setNoteModal(true);
  };

  const incrementDate = () => {
    const newDate = monthUpdate(selectedDate, attendanceLabel.add);
    setSelectedDate(newDate);
    fetchData(newDate);
  };  
  const decrementDate = () => {
    const newDate = monthUpdate(selectedDate, attendanceLabel.subtract);
    setSelectedDate(newDate);
    fetchData(newDate);
  };
  // Formik setups
  const getFormikConfig = (modalType) => {
    const attendanceStatus = staffDetail?.attendance_status;
    const inTimePrefill =
      (attendanceStatus === "Present" && modalType === attendanceLabel.presentModal) ||
      (attendanceStatus === "Half Day" && modalType === attendanceLabel.halfDayModal)
        ? convertToTimeFormat(staffDetail?.in_time) || null
        : null;
    const outTimePrefill =
      (attendanceStatus === "Present" && modalType === attendanceLabel.presentModal) ||
      (attendanceStatus === "Half Day" && modalType === attendanceLabel.halfDayModal)
        ? convertToTimeFormat(staffDetail?.out_time) || null
        : null;
    const date = staffDetail?.date;
    const commonValidation = {
      inTime: Yup.string().required(
        validationMessages.required(attendanceLabel.InTime)
      ),
      outTime: Yup.string()
        .required(validationMessages.required(attendanceLabel.OutTime))
        .test(
          attendanceLabel.IsGreater,
          validationMessages.greaterThan(
            attendanceLabel.InTime,
            attendanceLabel.OutTime
          ),
          function (value) {
            const { inTime } = this.parent;
            return inTime ? value >= inTime : true;
          }
        ),
    };
  
    const halfDaySchema= Yup.object({
      ...commonValidation,  
    });

    const leaveSchema= Yup.object({
      leaveOption: Yup.string().required(validationMessages.required(attendanceLabel.LeaveOption)),
    });

    switch (modalType) {
      case attendanceLabel.presentModal:
        return {
          enableReinitialize: true,
          initialValues: { inTime: inTimePrefill, outTime: outTimePrefill },
          validationSchema: Yup.object(commonValidation),
          onSubmit: (values) =>
            handleFormSubmit(
              addStaffAttendance,
              values,
              attendanceLabel.presentModal
            ),
        };
  
      case attendanceLabel.halfDayModal:
        return {
          enableReinitialize: true,
          initialValues: {
            inTime: inTimePrefill,
            outTime: outTimePrefill,
            leaveOption: halfLeave,
          },
          validationSchema: isSecondModalOpen ? leaveSchema : halfDaySchema,
          onSubmit: (values) =>
            handleFormSubmit(
              addStaffAttendance,
              values,
              attendanceLabel.halfDayModal
            ),
        };
  
      case attendanceLabel.paidLeaveModal:
        return {
          enableReinitialize: true,
          initialValues: { leaveOption: leaveType },
          validationSchema: Yup.object({
            leaveOption: Yup.string().required(
              validationMessages.required(attendanceLabel.LeaveOption)
            ),
          }),
          onSubmit: (values) =>
            handleFormSubmit(
              addStaffAttendance,
              values,
              attendanceLabel.paidLeaveModal
            ),
        };
  
      case attendanceLabel.compOffModal:
        return {
          enableReinitialize: true,
          initialValues: { workedHoliday: "", compensationDay: date},
          validationSchema: Yup.object({
            workedHoliday: Yup.string().required(
              validationMessages.required(attendanceLabel.WorkedHoliday)
            ),
            compensationDay: Yup.string().required(
              validationMessages.required(attendanceLabel.CompensationDay)
            ),
          }),
          onSubmit: (values) =>
            handleFormSubmit(
              addStaffAttendance,
              values,
              attendanceLabel.compOffModal
            ),
        };
  
      case attendanceLabel.absentModal:
        return {
          enableReinitialize: true,
          initialValues: {
            reason: staffDetail?.remarks || "",
          },
          onSubmit: (values) =>
            handleFormSubmit(
              addStaffAttendance,
              values,
              attendanceLabel.absentModal
            ),
        };
  
      default:
        return {};
    }
  };
  
  const formikNote = useFormik({
    initialValues: { note: "" },
    validationSchema: Yup.object({
      note: Yup.string().required(
        validationMessages.required(attendanceLabel.note)
      ),
    }),
    onSubmit: (values) => {
      setBtnLoader(true);
      const payload = {
        attendance_note: values.note,
      };
      addNote(attendanceId, payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            toast.success(resp?.message);
            fetchData(selectedDate);
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || err?.message);
        })
        .finally(() => {
          setNoteModal(false);
          resetForm();
          setAttendanceId(null);
          setBtnLoader(false);
        });
    },
  });

  const formikPresent = useFormik(
    getFormikConfig(attendanceLabel.presentModal)
  );
  const formikHalfDay = useFormik(
    getFormikConfig(attendanceLabel.halfDayModal)
  );
  const formikPaidLeave = useFormik(
    getFormikConfig(attendanceLabel.paidLeaveModal)
  );
  const formikCompOff = useFormik(
    getFormikConfig(attendanceLabel.compOffModal)
  );
  const formikAbsent = useFormik(getFormikConfig(attendanceLabel.absentModal));
  const handleFormSubmit = (apiCall, values, modalType) => {
    setBtnLoader(true);
    let payload;
    switch (modalType) {
      case attendanceLabel.presentModal:
        payload = {
          attendance_status: attendanceLabel.PresentStatus,
          in_time: convertToAmPm(values.inTime),
          out_time: convertToAmPm(values.outTime),
        };
        break;
      case attendanceLabel.halfDayModal:
        payload = {
          attendance_status: attendanceLabel.HalfDay,
          in_time: convertToAmPm(values.inTime),
          out_time: convertToAmPm(values.outTime),
          leave_type: values.leaveOption,
        };
        break;
      case attendanceLabel.paidLeaveModal:
        payload = {
          attendance_status: attendanceLabel.PaidLeaveStatus,
          leave_type: values.leaveOption,
        };
        break;
      case attendanceLabel.compOffModal:
        payload = {
          attendance_status: attendanceLabel.CompOffStatus,
          comp_off_leave_history_id: values.workedHoliday,
          composition_day_date: values.compensationDay,
        };
        break;
      case attendanceLabel.absentModal:
        payload = {
          attendance_status: attendanceLabel.AbsentStatus,
          remarks: values.reason,
        };
        break;
      default:
        payload = {};
    }

    apiCall(currentAttendanceId, payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          toast.success(resp?.message);
          fetchData(selectedDate);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setBtnLoader(false);
        resetForm();
        setModalType(null);
        setCurrentAttendanceId(null);
        setSecondModalOpen(false)
      });
  };
  const resetForm = () => {
    formikPresent.resetForm();
    formikHalfDay.resetForm();
    formikPaidLeave.resetForm();
    formikNote.resetForm();
    formikCompOff.resetForm();
    formikAbsent.resetForm();
  };

  const openModal = (type, staff) => {
    setCurrentAttendanceId(staff?.staff_attendance_id);
    setModalType(type);
    setStaffDetail(staff)
    setCurrentStaffDate(staff?.date);  
  };

  const formattedInTime = attendancedata?.[0]?.shift_in_time
  const formattedOutTime = attendancedata?.[0]?.shift_out_time
   
  const in_time = extractHoursDay(formattedInTime)
  const out_time = extractHoursDay(formattedOutTime)
  const shift_name = attendancedata?.[0]?.shift_name

  const renderModalContent = () => {
    switch (modalType) {
      case attendanceLabel.presentModal:
        return (
          <>
            <div>
              {
                shift_name &&
                <p className="shift-name py-2">
                  {shift_name}
                  {in_time != null &&
                    out_time != null &&
                    ` (${in_time} | ${out_time})`}
                </p>
              }
            </div>
            <div>
            <p>
              
              </p>
            
            </div>
            <div className="d-flex justify-content-between">
              <div className="w-100 me-1">
                <label>{attendanceLabel.InTime}</label>
                <BaseInput
                  type="time"
                  name={nameLabel.inTime}
                  value={formikPresent.values.inTime}
                  handleChange={formikPresent.handleChange}
                  touched={formikPresent.touched.inTime}
                  error={formikPresent.errors.inTime}
                  handleBlur={formikPresent.handleBlur}
                  placeholder={attendanceLabel.selectTime}
                  required={true}
                />
              </div>
              <div className="w-100 ms-1">
                <label>{attendanceLabel.OutTime}</label>
                <BaseInput
                  type="time"
                  name={nameLabel.outTime}
                  value={formikPresent.values.outTime}
                  handleChange={formikPresent.handleChange}
                  touched={formikPresent.touched.outTime}
                  error={formikPresent.errors.outTime}
                  handleBlur={formikPresent.handleBlur}
                  placeholder={attendanceLabel.selectTime}
                  required={true}
                />
              </div>
            </div>
          </>
        );
      case attendanceLabel.halfDayModal:
        return (
          <>
            <div>
              {
                shift_name &&
                <p className="shift-name py-2">
                  {shift_name}
                  {in_time != null &&
                    out_time != null &&
                    ` (${in_time} | ${out_time})`}
                </p>
              }
            </div>
            <div className="d-flex justify-content-between">
              <div className="w-100 me-1">
                <label>{attendanceLabel.InTime}</label>
                <BaseInput
                  type="time"
                  name={nameLabel.inTime}
                  value={formikHalfDay.values.inTime}
                  handleChange={formikHalfDay.handleChange}
                  touched={formikHalfDay.touched.inTime}
                  error={formikHalfDay.errors.inTime}
                  handleBlur={formikHalfDay.handleBlur}
                  placeholder={attendanceLabel.selectTime}
                  required={true}
                />
              </div>
              <div className="w-100 ms-1">
                <label>{attendanceLabel.OutTime}</label>
                <BaseInput
                  type="time"
                  name={nameLabel.outTime}
                  value={formikHalfDay.values.outTime}
                  handleChange={formikHalfDay.handleChange}
                  touched={formikHalfDay.touched.outTime}
                  error={formikHalfDay.errors.outTime}
                  handleBlur={formikHalfDay.handleBlur}
                  placeholder={attendanceLabel.selectTime}
                  required={true}
                />
              </div>
            </div>
          </>
        );
      case attendanceLabel.paidLeaveModal:
        return halfLeaveData?.length > 0 ? (
          <>
           <div>
              {
                shift_name &&
                <p className="shift-name py-2">
                  {shift_name}
                  {in_time != null &&
                    out_time != null &&
                    ` (${in_time} | ${out_time})`}
                </p>
              }
            </div>
          <div className="mx-3">
            <BaseRadioGroup
              name="leaveOption"
              className="m-checkbox "
              options={halfLeaveData}
              selectedValue={formikPaidLeave.values.leaveOption}
              onChange={(e) =>
                formikPaidLeave.setFieldValue(
                  nameLabel.leaveOption,
                  e.target.value
                )
              }
              classNameLabel="leave-modal"
              value={formikPaidLeave.values.leaveOption}
              touched={formikPaidLeave.touched.leaveOption}
              error={formikPaidLeave.errors.leaveOption}
              handleBlur={formikPaidLeave.handleBlur}
            />
          </div>
          </>
        ) : (
          <div className="log-card">
            <div className="log-card-body">
              <p className="d-flex justify-content-center">
                {attendanceLabel.noLeaveAvailable}
              </p>
            </div>
          </div>
        );
      case attendanceLabel.compOffModal:
        return (
          <>
          <div>
              {
                shift_name &&
                <p className="shift-name py-2">
                  {shift_name}
                  {in_time != null &&
                    out_time != null &&
                    ` (${in_time} | ${out_time})`}
                </p>
              }
            </div>
            <BaseSelect
              label={attendanceLabel.WorkedHoliday}
              name={nameLabel.workedHoliday}
              options={workData}
              placeholder={SelectPlaceHolder(attendanceLabel.WorkedHoliday)}
              handleChange={(field, value) =>
                formikCompOff.setFieldValue(field, value)
              }
              value={formikCompOff.values.workedHoliday}
              touched={formikCompOff.touched.workedHoliday}
              error={formikCompOff.errors.workedHoliday}
              handleBlur={formikCompOff.handleBlur}
              required={true}
            />
            <BaseInput
              type="date"
              label={attendanceLabel.CompensationDay}
              name={nameLabel.compensationDay}
              handleChange={formikCompOff.handleChange}
              value={formikCompOff.values.compensationDay}
              touched={formikCompOff.touched.compensationDay}
              error={formikCompOff.errors.compensationDay}
              handleBlur={formikCompOff.handleBlur}
              disabled={true}
              required={true}
            />
          </>
        );
      case attendanceLabel.absentModal:
        return (
          <div>
            <div>
              {
                shift_name &&
                <p className="shift-name py-2">
                  {shift_name}
                  {in_time != null &&
                    out_time != null &&
                    ` (${in_time} | ${out_time})`}
                </p>
              }
            </div>
            <p>{attendanceLabel.absentNote}</p>
            <BaseInput
              type="textarea"
              name={nameLabel.reason}
              label={attendanceLabel.Remarks}
              placeholder={PlaceHolderFormat(attendanceLabel.reasonForAbsent)}
              handleChange={formikAbsent.handleChange}
              value={formikAbsent.values.reason}
              touched={formikAbsent.touched.reason}
              error={formikAbsent.errors.reason}
              handleBlur={formikAbsent.handleBlur}
            />
          </div>
        );
      default:
        return null;
    }
  };
  const handleSearchChange = (value) => {
    if (value) {
      setSearchQuery(value);
    }
  };
  const closeModal = () => {
    setModalType(null);
    formikPaidLeave.setFieldValue(nameLabel.leaveOption, "");
    resetForm();
    setStaffDetail(null);
  };

  const attendanceItems = [
    { label: attendanceLabel.Present, value: countData?.transformedResponse?.[0]?.statusCount?.present },
    { label: attendanceLabel.AbsentLabel, value: countData?.transformedResponse?.[0]?.statusCount?.absent },
    { label: attendanceLabel.HalfDay, value: countData?.transformedResponse?.[0]?.statusCount?.halfDay },
    { label: attendanceLabel.CompOffLabel, value: countData?.transformedResponse?.[0]?.statusCount?.compOff },
    { label: attendanceLabel.PaidLeaveLabel, value: countData?.transformedResponse?.[0]?.statusCount?.paidLeave },
    { label: attendanceLabel.WeekOff, value: countData?.transformedResponse?.[0]?.statusCount?.weekOff },
    { label: attendanceLabel.Holiday, value: countData?.transformedResponse?.[0]?.statusCount?.holiday },
    { label: attendanceLabel.UnpaidLeave, value: countData?.transformedResponse?.[0]?.statusCount?.unmarked },
  ];

  return (
    <>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div>
      <div className="mx-3 d-flex d-sm-flex w-100 justify-content-end">
          <div className="col-auto d-flex d-sm-flex mb-2">
            <RiCheckboxCircleFill
              size={20}
              className="text-success mx-3 fs-6"
            />
            <span className="fs-6 text-success me-3">
              {attendanceLabel.AllApproved}
            </span>
          </div>
        </div>{" "}
        {/* Staff Date to data  */}
        <div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card mb-3 p-4">
                <div className="card-body d-block justify-content-between align-items-center">
                  <div className="d-flex align-items-center flex-wrap content-size  justify-content-between">
                    <div>
                      <RiArrowLeftSLine
                        className="fs-5 arrow-icon"
                        onClick={decrementDate}
                      />
                      <span className="text-center fs-5 text-date">
                        {extractMonthWithName(selectedDate)}
                      </span>
                      <RiArrowRightSLine
                        className="fs-5 arrow-icon"
                        onClick={incrementDate}
                      />
                    </div>
                    <div className="card-body attendence-card-body">
                      <div className="row my-2 text-center d-flex justify-content-center align-items-center">
                        {attendanceItems?.map((stat, index) => (
                          <div
                            className="col-4 col-sm border-right"
                            key={index}
                          >
                            <span className="fw-normal">{stat.label}</span>
                            <h6 className="fw-600 counter">
                              <CountUp end={stat.value} />
                            </h6>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onNextPage={handleNextPage}
          defaultPageSize={5}
          children={
            <>
            <div className="mt-2">
              {attendancedata?.length > 0 ? (
                attendancedata.map((data) => {
                  const firstAttendanceData = data?.attendanceData;
          
                  if (firstAttendanceData?.length > 0) {
                    return firstAttendanceData.map((staff) => {
                      const {
                        id,
                        date,
                        total_working_hours,
                        attendance_status,
                        in_time,
                        out_time,
                        attendance_note,
                      } = staff;
                      let statusLabel;
                      if (
                        staff?.actual_attendance_status ===
                        attendanceLabel.WeekOff
                      ) {
                        weekOffStatus = true;
                      }
                      if (
                        staff?.actual_attendance_status ===
                        attendanceLabel.Holiday
                      ) {
                        holidayOffStatus = true;
                      }
                      compOffHistory = staff?.compOffLeaveHistory?.date;
                      if (staff.attendance_status === WEEK_OFF) {
                        statusLabel = weekOff;
                      } else if (staff.attendance_status === HOLIDAY_OFF) {
                        statusLabel = holiday;
                      } else {
                        statusLabel = paidLeave;
                      }

                      const handleStatusClick = (status) => {
                        if (
                          (staff.attendance_status === WEEK_OFF || staff.attendance_status === HOLIDAY_OFF || staff.isHoliday || staff.isWeekOff) &&
                          (status === attendanceLabel.PresentStatus || status === attendanceLabel.HalfDayStatus)
                        ) {
                          openModal(status, staff);
                        } else if (staff.attendance_status !== WEEK_OFF && staff.attendance_status !== HOLIDAY_OFF && !staff.isHoliday && !staff.isWeekOff) {
                          openModal(status, staff);
                        }
                      };
          
                      return (
                        <StaffCard
                          key={id}
                          title={dateMonthName(date)}
                          staffAttendanceId={staff.staff_attendance_id}
                          RevertApi={RevertStatusToHoliday}
                          RevertCompOff={RevertStatusToWeekOff}
                          subtitle={getFormatDayName(date)}
                          shiftName={attendancedata?.[0]?.shift_name}
                          isHoliday={staff.is_holiday}
                          isWeekOff={staff?.is_week_off}
                          workingHours={
                            total_working_hours && `${total_working_hours}`
                          }
                          historyEntries={{
                            status: attendance_status,
                            inTime: in_time,
                            outTime: out_time,
                          }}
                          status={attendance_status}
                          note={attendance_note}
                          onEditNote={() =>
                            handleNoteModal(
                              staff.staff_attendance_id,
                              attendance_note
                            )
                          }
                          onViewLogs={() => handleViewLogs(staff)}
                          onStatusClick={handleStatusClick}
                          statusLabels={statusLabel}
                          weekOffStatus={weekOffStatus}
                          holidayOffStatus={holidayOffStatus}
                          compOffHistory={compOffHistory}
                        />
                      );
                    });
                  } else {
                    return (
                      <div className="card py-4 text-center" key={data.id}>
                        <div>
                          <RiSearchLine className="fs-2" />
                        </div>
                        <div className="mt-4">
                          <h5>{notFound.dataNotFound}</h5>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <div className="card py-4 text-center">
                  <div>
                    <RiSearchLine className="fs-2" />
                  </div>
                  <div className="mt-4">
                    <h5>{notFound.dataNotFound}</h5>
                  </div>
                </div>
              )}
            </div>
          </>
          
          }
          onPreviousPage={handlePreviousPage}
          onPageSizeChange={handlePageSizeChange}
          onSearchChange={handleSearchChange}
        />
      </div>


      {modalType && (
       <BaseModal
       isOpen={Boolean(modalType)}
       toggler={closeModal}
       submit={() => {
         if (modalType === attendanceLabel.presentModal) formikPresent.handleSubmit();
         if (modalType === attendanceLabel.halfDayModal) handleHalfDaySubmit();
         if (modalType === attendanceLabel.paidLeaveModal) formikPaidLeave.handleSubmit();
         if (modalType === attendanceLabel.compOffModal) formikCompOff.handleSubmit();
         if (modalType === attendanceLabel.absentModal) formikAbsent.handleSubmit();
       }}
       title={
         <>
           <div className="fs-5">{modalType}</div>
           <div className="fs-8 text-secondary">
             {`${countData?.transformedResponse[0]?.name} | ${extractDateAsM(staffDetail?.date)}`}
           </div>
         </>
       }
       loader={btnLoader}
       disabled={btnLoader}
       submitText={
        modalType === attendanceLabel.halfDayModal
          ? attendanceLabel.Save
          : Submit
      }
     >
       {renderModalContent()}
     </BaseModal>
     
      )}

      <BaseModal
        isOpen={isSecondModalOpen}
        toggler={() => setSecondModalOpen(false)}
        title={
          <>
            <div className="fs-5">{modalType}</div>
            <div className="fs-8 text-secondary">
              {`${countData?.transformedResponse?.[0]?.name} | ${extractDateAsM(staffDetail?.date)}`}
            </div>
          </>
        }
        submitText="Submit"
        loader={btnLoader}
        submit={() => {
          formikHalfDay.handleSubmit();
        }}
      >
        {/* Modal Content */}
        {leaveData?.length > 0 ? (
          <div className="mx-3">
            <BaseRadioGroup
              name="leaveOption"
              className="m-checkbox"
              options={leaveData}
              selectedValue={formikHalfDay.values.leaveOption}
              onChange={(e) =>
                formikHalfDay.setFieldValue(nameLabel.leaveOption, e.target.value)
              }
              classNameLabel="leave-modal"
              value={formikHalfDay.values.leaveOption}
              touched={formikHalfDay.touched.leaveOption}
              error={formikHalfDay.errors.leaveOption}
              handleBlur={formikHalfDay.handleBlur}
            />
          </div>
        ) : (
          <div className="log-card">
            <div className="log-card-body">
              <p className="d-flex justify-content-center">
                {attendanceLabel.noLeaveAvailable}
              </p>
            </div>
          </div>
        )}
      </BaseModal>

      <BaseModal
        isOpen={noteModal}
        toggler={() => setNoteModal(false)}
        title={
          formikNote.values.note !== ""
            ? attendanceLabel.editNote
            : attendanceLabel.AddNote
        }
        submitText={formikNote.values.note !== "" ? Update : Submit}
        submit={formikNote.handleSubmit}
        loader={btnLoader}
        disabled={btnLoader}
      >
        <div>
          <BaseInput
            label={attendanceLabel.note}
            name={nameLabel.note}
            type="textarea"
            handleChange={formikNote.handleChange}
            placeholder={PlaceHolderFormat(attendanceLabel.note)}
            value={formikNote.values.note}
            touched={formikNote.touched.note}
            error={formikNote.errors.note}
            handleBlur={formikNote.handleBlur}
            required={true}
          />
        </div>
      </BaseModal>
      <BaseModal
        isOpen={logModal}
        toggler={() => setLogModal(false)}
        title={attendanceLabel.ViewLogs}
        submitText={Submit}
        hasSubmitButton={false}
      >
        <div className="container">
          <div className="row">
          <div className="col-lg-12 mt-2">
              {logs?.length === 0 ? (
                <p className="d-flex justify-content-center">
                  {attendanceLabel.noLogsFound}
                </p>
              ) : (
                logs?.map((log) => (
                  <div
                    key={log.id}
                    className="status-item d-flex align-items-start"
                  >
                    <RiCheckboxBlankCircleFill
                      size={13}
                      className="me-2 mt-1"
                    />
                    <div>
                      <p>
                        {attendanceLabel.MarkedAs} {log.attendance_status}
                        {![
                          attendanceLabel.AbsentStatus,
                          attendanceLabel.compOffStatus,
                          attendanceLabel.PaidLeaveStatus,
                          attendanceLabel.noteMarked,
                        ].includes(log) && (
                          <>
                            {log.shift_name}
                            {log.in_time || log.out_time ? (
                              <>
                                {" | "}
                                {" "}
                                (
                                {log.in_time && log.out_time
                                  ? `${log.in_time} TO ${log.out_time}`
                                  : log.in_time || log.out_time}
                                )
                              </>
                            ) : null}
                          </>
                        )}
                      </p>
                      <p className="status-date">
                      By {log?.staffAttendance?.addedBy?.name} on {extractMonthDay(log.createdAt)}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default AttendanceDetails;
