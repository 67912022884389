import { HOLIDAY_OFF, WEEK_OFF } from "../../Constant";
import { extractDate } from "../../Constant/common";
import { attendanceLabel } from "../../Constant/HR_Attendance/hrAttendance";
import BaseButton from "../BaseButton";

const StaffCard = ({
  title,
  subtitle,
  workingHours,
  historyEntries,
  status,
  note,
  onEditNote,
  onViewLogs,
  onStatusClick,
  statusLabels,
  shiftName,
  isHoliday,
  isWeekOff,
  RevertApi,
  RevertCompOff,
  staffAttendanceId,
  holidayOffStatus,
  weekOffStatus,
  compOffHistory,
}) => {
  const getStatusDisplayText = (status) => {
    if (!historyEntries || !historyEntries.status)
      return attendanceLabel.noData;

    return `${
      status === attendanceLabel.PresentStatus
        ? attendanceLabel.P
        : attendanceLabel.HalfDayStatus
    } | ${historyEntries.inTime} - ${
      historyEntries.outTime ?? attendanceLabel.noData
    }`;
  };

  const isStatusNotMarked = status === attendanceLabel.noteMarked;

  const handleHolidayRevert = () => {
    RevertApi(staffAttendanceId);
  };
  const handleWeekOffRevert = () => {
    RevertCompOff(staffAttendanceId);
  };

  return (
    <div className="card mb-2 p-2">
      <div className="card-body row">
        <div className="info col-lg-4">
          <h6>
            {title} | <small>{subtitle}</small>
          </h6>
          <div>{workingHours}</div>
          <div>
            {historyEntries?.inTime && (
              <div>
                {shiftName}({historyEntries.inTime} |{" "}
                {historyEntries.outTime ?? attendanceLabel.noData})
              </div>
            )}
          </div>
          <div className={isStatusNotMarked ? "text-danger" : ""}>
            {status}
            {isHoliday ||
            (status === attendanceLabel.Present && holidayOffStatus)
              ? ` | ${status}`
              : (isWeekOff ||
                  (status === attendanceLabel.Present && weekOffStatus)) &&
                ` | ${attendanceLabel.WeekOff}`}
          </div>
          <div>
            {compOffHistory &&
              `${attendanceLabel.CompOffDate}${extractDate(compOffHistory)}`}
          </div>
          <div className="ellipsis" title={note}>
            {note}
          </div>
          <div>
            <a className="text-primary cursor" onClick={onEditNote}>
              {note ? attendanceLabel.editNote : attendanceLabel.AddNote}
            </a>{" "}
            |{" "}
            <a className="text-primary cursor" onClick={onViewLogs}>
              {attendanceLabel.ViewLogs}
            </a>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="row">
            {statusLabels?.map((label, index) => (
              <div
                key={index}
                className={`col-md-${label.colWidth} grid-item border ${
                  status === label.status && label.bgClass
                } d-flex align-items-center justify-content-between`} // Add flexbox classes
                onClick={() => {
                  if (
                    (status === WEEK_OFF ||
                      status === HOLIDAY_OFF ||
                      isHoliday ||
                      isWeekOff) &&
                    label.status === attendanceLabel.PresentStatus
                  ) {
                    onStatusClick(label.status);
                  } else if (
                    status !== WEEK_OFF &&
                    status !== HOLIDAY_OFF &&
                    !isHoliday &&
                    !isWeekOff
                  ) {
                    onStatusClick(label.status);
                  }
                }}
              >
                <span className="small-text">
                  {status === label.status
                    ? label.status === attendanceLabel.PresentStatus ||
                      label.status === attendanceLabel.HalfDayStatus
                      ? getStatusDisplayText(label.status)
                      : label.displayText
                    : label.displayText}
                </span>
              </div>
            ))}
            {isHoliday && (
              <div className="d-flex justify-content-end w-100">
                <BaseButton
                  label={attendanceLabel.Revert}
                  size="sm"
                  className="btn btn-danger py-1 px-0"
                  onClick={() => handleHolidayRevert()}
                />
              </div>
            )}
            {isWeekOff && (
              <div className="d-flex justify-content-end w-100">
                <BaseButton
                  label={attendanceLabel.RevertWeekOff}
                  size="sm"
                  className="btn btn-danger py-1 px-0"
                  onClick={() => handleWeekOffRevert()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffCard;
