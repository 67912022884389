export const leaveOptions = [
  { id: "1", value: "Weekly Off", label: "Weekly Off", labelSuffix: "0.00 left" },
  {
    id: "2",
    value: "Comp Off",
    label: "Comp Off Leave",
    labelSuffix: "0.00 left",
  },
  { id: "3", value: "Other", label: "Other", labelSuffix: "0.00 left" },
];

export const options = [
  { id: 0, value: "", label: "Select Worked Holiday" },
  { id: 1, value: "3rd Jan 2024", label: "3rd Jan 2024" },
];

export const attendanceLabel = {
  AttendanceSummary: "Attendance Summary",
  note:"Note",
  IsGreater: "is-greater",
  InTime: "In Time",
  OutTime: "Out Time",
  LeaveOption: "Leave Option",
  Remarks: "Remarks",
  WorkedHoliday: "Worked Holiday",
  CompensationDay: "Compensation Day",
  TotalStaff: "Total Staff",
  Present: "Present",
  AbsentLabel: "Absent",
  HalfDay: "Half Day",
  CompOffLabel: "Comp Off",
  PaidLeaveLabel: "Paid Leave",
  AddNote: "Add Note",
  ViewLogs: "View Logs",
  HDHalfDay: "HD | Half Day",
  Absent: "A | Absent",
  CompOff: "CO | Comp Off",
  PaidLeave: "PL | Paid Leave",
  presentModal: "Present",
  paidLeaveModal: "Paid Leave",
  compOffModal: "Comp Off",
  noteModal: "Note",
  halfDayModal: "Half Day",
  logsModal: "Logs",
  absentModal: "Absent",
  reasonForAbsent: "Reason for Absence",
  absentNote: "Please confirm the absence of the staff member for today.",
  logsLabel: "Present added at 02:00 PM on 04/12/2024",
  PresentStatus:"Present",
  HalfDayStatus:"Half Day",
  PaidLeaveStatus:"Paid Leave",
  CompOffStatus:"Comp Off",
  AbsentStatus:"Absent",
  noteMarked:"Not Marked",
  halfDatStatus:"Half Day",
  PaidLeaveStatus:"Paid Leave",
  compOffStatus:"Comp Off",
  noLogs:"No logs available.",
  days:"days",
  notAvailableLogs:"No status available for",
  add:"add",
  subtract:"subtract",
  Yes:"Yes",
  Save:"Save",
  hours:"hours",
  editNote:"Edit Note",
  left:"left",
  isAdded:"is added on",
  noLeaveAvailable:"No leave data available.",
  selectTime:"Select time",
  noData:"N/A",
  AllApproved:"All Approved",
  Demo:"Demo",
  id:"#1dh",
  P:"P",
  HD:"HD",
  MarkedAs:"Marked as",
  noLogsFound:"No logs found",
  Revert: 'Revert Holiday',
  RevertWeekOff: 'Revert Week Off',
  Holiday: 'Holiday',
  UnpaidLeave: 'Unpaid leave',
  WeekOff: "Week Off",
  CompOffDate:"Comp Off Date: ",
};

export const nameLabel = {
  inTime: "inTime",
  outTime: "outTime",
  leaveOption: "leaveOption",
  note: "note",
  workedHoliday: "workedHoliday",
  compensationDay: "compensationDay",
  reason: "reason",
};

export const staffData = [
  {
    id: "D18",
    name: "Deepak Kumar",
    hours: "0:00 Hrs",
    time: "10:25 AM - NA",
    status: "Present",
  },
  {
    id: "D19",
    name: "Priya Sharma",
    hours: "0:00 Hrs",
    time: "10:25 AM - NA",
    status: "Present",
  },
  {
    id: "D21",
    name: "Prachi Rathod",
    hours: "Not Marked",
    status: "Not Marked",
  },
  {
    id: "D22",
    name: "Amit Kumar",
    hours: "0:00 Hrs",
    time: "10:25 AM - NA",
    status: "Absent",
  },
];
export const staticResponse = {
  statusCode: 200,
  status: "Success",
  data: [
    {
      id: 1,
      staff_attendance_id: 1,
      in_time: "10:10",
      out_time: "17:10",
      comp_off_leave_history_id: null,
      composition_day_date: null,
      leave_type: null,
      leave_reason: null,
      staffAttendance: {
        id: 1,
        attendance_status: "Present",
        date: "2024-07-30",
      },
    },
    {
      id: 2,
      staff_attendance_id: 2,
      in_time: "09:00",
      out_time: "13:00",
      comp_off_leave_history_id: null,
      composition_day_date: null,
      leave_type: null,
      leave_reason: null,
      staffAttendance: {
        id: 2,
        attendance_status: "Half Day",
        date: "2024-07-30",
      },
    },
    {
      id: 3,
      staff_attendance_id: 3,
      in_time: null,
      out_time: null,
      comp_off_leave_history_id: null,
      composition_day_date: null,
      leave_type: null,
      leave_reason: null,
      staffAttendance: {
        id: 3,
        attendance_status: "Absent",
        date: "2024-07-30",
      },
    },
  ],
};
export   const statusLabels = [
  {
    status: "Present",
    displayText: "P | Present",
    colWidth: 3,
    bgClass: "bg-success active",
  },
  {
    status: "Half Day",
    displayText: "HD | Half Day",
    colWidth: 4,
    bgClass: "bg-warning",
  },
  {
    status: "Absent",
    displayText: "A | Absent",
    colWidth: 4,
    bgClass: "bg-danger deactive",
  },
  {
    status: "Comp Off",
    displayText: "CO | Comp Off",
    colWidth: 4,
    bgClass: "bg-info",
  },

];

export const paidLeave = [...statusLabels,   {
  status: "Paid Leave",
  displayText: "PL | Paid Leave",
  colWidth: 4,
  bgClass: "bg-secondary",
}]

export const weekOff = [...statusLabels, 
  {
    status: "Week Off",
    displayText: "WO | Week Off",
    colWidth: 4,
    bgClass: "bg-info",
  },]

export const holiday = [...statusLabels,
{
  status: "Holiday",
  displayText: "H | Holiday",
  colWidth: 4,
  bgClass: "bg-danger",
},]